import style from '../../../styles/components/project/ProjectTabs.module.scss';

import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Button, Dropdown, Icon, Modal } from '@aurecon-creative-technologies/styleguide';

import { IProjectMetadataModel } from '../../../models/project/IProjectMetadataModel';
import { IProjectDetails } from '../../../models/project/IProjectDetails';
import { updateProjectMetadata } from '../../../apis/project/Project'
import { IProjectMetadataSave } from '../../../models/project/IProjectMetadataSave';

interface IProjectMetadataProps {
    pageState: any;
    defaultMetadataLookup: IProjectMetadataModel[];
    projectMetadata: IProjectMetadataModel[];
    refresh: () => void;
}

const Tab4Metadata: React.FunctionComponent<IProjectMetadataProps> = (props) => {

    const pageState: IProjectDetails = props.pageState;

    const { instance, accounts } = useMsal();

    const [editMode, setEditMode] = useState(Boolean);
    const [selectedMetadataCapabilities, setSelectedMetadataCapabilities] = useState<any>([]);
    const [selectedMetadataAssets, setSelectedMetadataAssets] = useState<any>([]);
    const [selectedMetadataSDGS, setSelectedMetadataSDGS] = useState<any>([]);
    const [isModalShowing, setIsModalShowing] = useState<boolean>(false);

    const defaultMetadataLookup: IProjectMetadataModel[] = props.defaultMetadataLookup;
    const projectMetadata: IProjectMetadataModel[] = props.projectMetadata;

    useEffect(() => {
        setSelectedMetadataCapabilities(loadMetadataForProject('Capability'));
        setSelectedMetadataAssets(loadMetadataForProject('Asset'));
        setSelectedMetadataSDGS(loadMetadataForProject('SDGS'));
     }, []);

    const onClickEditMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditMode(true);
    };

    const onClickDisplayMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditMode(false);
    };

    const onShowModal = () => {
        setIsModalShowing(true);
    }

    const onCloseModal = () => {
        setIsModalShowing(false);
    }

    const loadMetadata_Capability = () => {
        return loadMetadataForLookup('Capability');
    };

    const onSelectionChanged_MetadataCapability = (e: any) => {
        let selectedItems: number[] = [];
        e.forEach((i: number) => {
            selectedItems.push(i);
        });
        setSelectedMetadataCapabilities(selectedItems);
    };

    const loadMetadata_Asset = () => {
        return loadMetadataForLookup('Asset');
    };

    const onSelectionChanged_MetadataAssets = (e: any) => {
        let selectedItems: number[] = [];
        e.forEach((i: number) => {
            selectedItems.push(i);
        });
        setSelectedMetadataAssets(selectedItems);
    };

    const loadMetadata_SDGS = () => {
        return loadMetadataForLookup('SDGS');
    };

    const onSelectionChanged_MetadataSDGS = (e: any) => {
        let selectedItems: number[] = [];
        e.forEach((i: number) => {
            selectedItems.push(i);
        });
        setSelectedMetadataSDGS(selectedItems);
    };

    const onClickCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditMode(false);
    };

    const loadMetadataForLookup = (metadataType: string) => {
        const returnMetadata:any[] = [];
        defaultMetadataLookup.forEach((m: IProjectMetadataModel) => {
            if (m.type === metadataType) {
                var metadata = {};
                if (m.isParent) {
                    // metadata = {id: m.id, label: <span style={{fontWeight: 'bold'}}>{m.value}</span>, icon: '', value: m.value }
                    metadata = {id: m.id, label: m.value, icon: '', value: m.value }
                }
                else{
                    metadata = {id: m.id, label: m.value, icon: 'local_offer', value: m.value }
                }
                returnMetadata.push(metadata);
            }
        });
        return returnMetadata;
    }

    const loadMetadataForProject = (metadataType: string) => {
        const returnMetadata: any[] = [];
        projectMetadata.forEach((m: IProjectMetadataModel) => {
            if (m.type === metadataType) {
                returnMetadata.push(m.metadataId);
            }
        });
        return returnMetadata;
    }

    const getSelectedMetadataDisplayText = (metadataType: string) => {
        let returnText = '';

        projectMetadata.forEach((m: IProjectMetadataModel) => {
            if (m.type === metadataType) {
                if (returnText != '') returnText += ', ';
                returnText += m.value;
            }
        });

        if (returnText === '')
            returnText = 'None';

        return returnText;
    }

    const onSaveMetadata = () => {

        //combine the data for selected metadata
        let postBody: IProjectMetadataSave = {
            projectId: pageState.projectDetails.projectId,
            projectMetadata: []
        }

        selectedMetadataCapabilities.forEach((c: any) => {
            postBody.projectMetadata.push({ projectId: pageState.projectDetails.projectId, id: 0, metadataId: c });
        });

        selectedMetadataAssets.forEach((a: any) => {
            postBody.projectMetadata.push({ projectId: pageState.projectDetails.projectId, id: 0, metadataId: a });
        });

        selectedMetadataSDGS.forEach((s: any) => {
            postBody.projectMetadata.push({ projectId: pageState.projectDetails.projectId, id: 0, metadataId: s });
        });

        updateProjectMetadata(instance, accounts, pageState.projectDetails.projectId, postBody)
            .then((results: any) => {
                props.refresh();
                setEditMode(false);
            });
    }

    return (
        <div className={style.projectTabMetadata}>
            <div className={style.lineWrapper}>

                <div className={style.rightCommandButtons}>
                    <Button
                        title='View Mode'
                        type="icon-square"
                        size="medium"
                        icon="visibility"
                        onClick={onClickDisplayMode}
                        cssClass={`${editMode === false ? "buttonModeActive" : "buttonModeDeactive"}`}
                    />
                    &nbsp;
                    <Button
                        title='Edit Mode'
                        type="icon-square"
                        size="medium"
                        icon="edit"
                        onClick={onClickEditMode}
                        cssClass={`${editMode === true ? "buttonModeActive" : "buttonModeDeactive"}`}
                    />
                </div>
            </div>

            <div className={style.lineWrapper}>
                <div className={style.leftTitle}>
                    <b>Capability</b>
                </div>
            </div>
            <div className={style.left}>
                {editMode ?
                    <Dropdown placeholder='Select Capabilities'
                        items={loadMetadata_Capability()}
                        cssClass="metadataDropdown"
                        selectedMultipleItems={selectedMetadataCapabilities}
                        onSelectMultipleItems={onSelectionChanged_MetadataCapability}
                        multiple={true}
                        disabled={!editMode}
                    />
                    :
                    <span>
                        {getSelectedMetadataDisplayText('Capability')}
                    </span>
                }
            </div>

            {/* *********************************************** */}

            <div className={style.lineWrapper}>
                <div className={style.leftTitle}>
                    <b>Asset</b>
                </div>
            </div>
            <div className={style.left}>
                {editMode ?
                    <Dropdown placeholder='Select Assets'
                        items={loadMetadata_Asset()}
                        cssClass="metadataDropdown"
                        selectedMultipleItems={selectedMetadataAssets}
                        onSelectMultipleItems={onSelectionChanged_MetadataAssets}
                        multiple={true}
                        disabled={!editMode}
                    />
                    :
                    <span>
                        {getSelectedMetadataDisplayText('Asset')}
                    </span>
                }
            </div>

            {/* *********************************************** */}

            <div className={style.lineWrapper}>
                <div className={style.leftTitle}>
                    <b>Sustainability Development Goals</b>
                </div>
            </div>
            <div className={style.left}>
                {editMode ?
                    <Dropdown placeholder='Select SDGs'
                        items={loadMetadata_SDGS()}
                        cssClass="metadataDropdown"
                        selectedMultipleItems={selectedMetadataSDGS}
                        onSelectMultipleItems={onSelectionChanged_MetadataSDGS}
                        multiple={true}
                        disabled={!editMode}
                    />
                    :
                    <span>
                        {getSelectedMetadataDisplayText('SDGS')}
                    </span>
                }
            </div>

            <div className={style.lineWrapper}>
                <div className={style.notSureWhatToTag} onClick={onShowModal}>
                    <p><b><Icon type="error" /> Not sure what to tag?</b></p>
                </div>
            </div>
            {editMode &&
                <div className={style.lineWrapper}>
                    <div className={style.rightButtons}>
                        <Button
                            type="secondary"
                            size="medium"
                            label='Cancel'
                            width='100px'
                            onClick={onClickCancel}
                            cssClass={"buttonModeDeactive"}
                        />
                        <Button
                            type="primary"
                            size="medium"
                            label='Save'
                            width='100px'
                            onClick={onSaveMetadata}
                            cssClass={"buttonModeActive"}
                        />
                    </div>
                </div>
            }
            <Modal
                isShowing={isModalShowing}
                onClose={onCloseModal}
                shouldOverlayClose={false}
                isCloseButton={true}
            >
                <div className={style.modalContainer}>
                    <div>
                        <p><b>Not sure what to tag?</b></p>
                    </div>
                    <div>
                        <p>For more information on global taxonomy, click <a href="https://aurecongroup.sharepoint.com/sites/Knowledge/SitePages/KnowledgeTaxonomy.aspx" target="_blank" rel="noreferrer">here</a> to go to Knowledge at Aurecon</p>
                    </div>
                    <div className={style.rightButtons}>
                        <Button
                            type="secondary"
                            size="medium"
                            label='Got it'
                            width='100px'
                            onClick={onCloseModal}
                            cssClass={"buttonModeActive"}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Tab4Metadata;

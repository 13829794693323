import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getAsync, postAsync } from "../../utils/transportRequest";
import { IProjectsResponseModel } from '../../models/project/IProjectsResponseModel';
import { IProjectPlanningSubSectionModel } from '../../models/project/IProjectPlanningSubSectionModel';
import { IProjectRolesModel } from "../../models/project/IProjectRolesModel";
import { IProjectMetadataModel } from "../../models/project/IProjectMetadataModel";
import { IProjectMetadataSave } from "../../models/project/IProjectMetadataSave";
import { IContentEngineSelectionModel } from "../../models/project/IContentEngineSelectionModel";
import { I12dSynergyTriggerModel } from "../../models/project/I12dSynergyTriggerModel";
import { IProjectWiseTriggerModel } from "../../models/project/IProjectWiseTriggerModel";
import { IProjectContentEngine } from "../../models/project/IProjectContentEngine";
import { IOneSpaceTriggerModel } from "../../models/project/IOneSpaceTriggerModel";
import { IBIM360TriggerModel } from "../../models/project/IBIM360TriggerModel";
import { IOrganization } from "../../models/project/IOrganization";

export const getProjects = async (instance: IPublicClientApplication, accounts: AccountInfo[], query: string): Promise<IProjectsResponseModel> => {

    const emailAddress = accounts[0] && accounts[0].username;
    const apiUrl = `/api/Projects/MyProjects?email=${emailAddress}&${query}`;

    return getAsync<IProjectsResponseModel>(instance, accounts, apiUrl);
};

export const getGetContentEngineValues = async (instance: IPublicClientApplication, accounts: AccountInfo[], projectId: string): Promise<IProjectContentEngine> => {

    const apiUrl = `/api/ContentEngine/provisioning/${projectId}`;

    return getAsync<IProjectContentEngine>(instance, accounts, apiUrl);

};

export const getProjectPlanningSubSection = async (instance: IPublicClientApplication, accounts: AccountInfo[], projectId: string): Promise<IProjectPlanningSubSectionModel> => {

    const apiUrl = `/api/projectplanning/${projectId}/subsection`;

    return getAsync<IProjectPlanningSubSectionModel>(instance, accounts, apiUrl);
}

export const updateProjectPlanningSubSection = async (instance: IPublicClientApplication, accounts: AccountInfo[], body:IProjectPlanningSubSectionModel): Promise<IProjectPlanningSubSectionModel> => {
    const projectId = body.projectId;
    const apiUrl = `/api/projectplanning/${projectId}/subsection/save`;

    return postAsync<IProjectPlanningSubSectionModel>(instance, accounts, apiUrl, body);
};

export const getContentEngineLookup = async (instance: IPublicClientApplication, accounts: AccountInfo[]): Promise<IProjectMetadataModel[]> => {

    const apiUrl = `/api/ProjectPlanning/ContentEngine/list`;

   return getAsync<IProjectMetadataModel[]>(instance, accounts, apiUrl);
}

export const getContentEngineSelection = async (instance: IPublicClientApplication, accounts: AccountInfo[], projectId: string): Promise<IContentEngineSelectionModel> => {

    const apiUrl = `/api/projectplanning/${projectId}/contentengine`;

    return getAsync<IContentEngineSelectionModel>(instance, accounts, apiUrl);
}

export const updateContentEngineSelection = async (instance: IPublicClientApplication, accounts: AccountInfo[], body:IContentEngineSelectionModel): Promise<IContentEngineSelectionModel> => {
    const projectId = body.projectId;
    const apiUrl = `/api/projectplanning/${projectId}/contentengine/save`;

    return postAsync<IContentEngineSelectionModel>(instance, accounts, apiUrl, body);
};

export const getProjectRoles = async (instance: IPublicClientApplication, accounts: AccountInfo[], projectId: string): Promise<IProjectRolesModel> => {

    const apiUrl = `/api/Projects/${projectId}/Roles/`;

    return getAsync<IProjectRolesModel>(instance, accounts, apiUrl);
}

export const updateProjectRoles = async (instance: IPublicClientApplication, accounts: AccountInfo[], body:IProjectRolesModel): Promise<IProjectRolesModel> => {

    const projectId = body.ProjectId;
    const apiUrl = `/api/Projects/${projectId}/roles/save`;

    return postAsync<IProjectRolesModel>(instance, accounts, apiUrl, body);
};

export const getMetadataLookup = async (instance: IPublicClientApplication, accounts: AccountInfo[]): Promise<IProjectMetadataModel[]> => {

    const apiUrl = `/api/ProjectPlanning/metadata/list`;

    return getAsync<IProjectMetadataModel[]>(instance, accounts, apiUrl);
};


export const getProjectMetadata = async (instance: IPublicClientApplication, accounts: AccountInfo[], projectId: string): Promise<IProjectMetadataModel[]> => {

    const apiUrl = `/api/ProjectPlanning/${projectId}/metadata`;

    return getAsync<IProjectMetadataModel[]>(instance, accounts, apiUrl);
};

export const updateProjectMetadata = async (instance: IPublicClientApplication, accounts: AccountInfo[], projectId: string, body: IProjectMetadataSave): Promise<IProjectMetadataSave> => {

    const apiUrl = `/api/ProjectPlanning/${projectId}/metadata/save`;

    return postAsync<IProjectMetadataSave>(instance, accounts, apiUrl, body.projectMetadata);
};

export const triggerOneSpaceCreation = async (instance: IPublicClientApplication, accounts: AccountInfo[], body:IOneSpaceTriggerModel): Promise<IOneSpaceTriggerModel> => {

    const apiUrl = `/api/ContentEngine/OneSpace`;

    return postAsync<IOneSpaceTriggerModel>(instance, accounts, apiUrl, body);
};

export const triggerProjectWiseCreation = async (instance: IPublicClientApplication, accounts: AccountInfo[], body:IProjectWiseTriggerModel): Promise<IProjectWiseTriggerModel> => {

    const apiUrl = `/api/ContentEngine/ProjectWise`;

    return postAsync<IProjectWiseTriggerModel>(instance, accounts, apiUrl, body);
};

export const triggerBIM360Creation = async (instance: IPublicClientApplication, accounts: AccountInfo[], body:IBIM360TriggerModel): Promise<IBIM360TriggerModel> => {

    const apiUrl = `/api/ContentEngine/BIM360/${body.projectNumber}?CreatedBy=${body.createdBy}&CreatedByName=${body.createdByName}`;

    return postAsync<any>(instance, accounts, apiUrl, body);
};

export const trigger12dSynergyCreation = async (instance: IPublicClientApplication, accounts: AccountInfo[], body:I12dSynergyTriggerModel): Promise<I12dSynergyTriggerModel> => {

    const apiUrl = `/api/ContentEngine/12dSynergy`;

    return postAsync<I12dSynergyTriggerModel>(instance, accounts, apiUrl, body);
};

export const getOrganisation = async (instance: IPublicClientApplication, accounts: AccountInfo[]): Promise<IOrganization[]> => {

    const apiUrl = `/api/ProjectPlanning/organisation/list`;

   return getAsync<IOrganization[]>(instance, accounts, apiUrl);
}
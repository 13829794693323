import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { builderSettingConst } from '../constants/builderSettingConst';
import { ISettingsModel } from '../models/shared/ISettingsModel';

export const RequestAccessToken = async (instance: IPublicClientApplication, accounts: AccountInfo[]): Promise<string> => {
    const settingsJsonString = localStorage.getItem(builderSettingConst);
    const settings = settingsJsonString ? (JSON.parse(settingsJsonString) as ISettingsModel) : null;

    let scopes: string[] = [];
    scopes.push(settings?.appApiScope ? settings?.appApiScope : "");

    const result = await instance.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
    });

    return result.accessToken;
}
import { Loader, TabContent } from "@aurecon-creative-technologies/styleguide";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { TabMenu } from "../../overrides/TabMenu";
import style from "../../styles/components/project/ProjectTabs.module.scss";
import Tab1Planning from "./tabs/Tab1Planning";
import Tab2Roles from "./tabs/Tab2Roles";
import Tab3Location from "./tabs/Tab3Location";
import Tab4Metadata from "./tabs/Tab4Metadata";
import Tab5ContentEngines from "./tabs/Tab5ContentEngines";
import {
  getProjectPlanningSubSection,
  getProjectRoles,
  getMetadataLookup,
  getProjectMetadata,
  getContentEngineLookup,
  getContentEngineSelection,
  getGetContentEngineValues,
  getOrganisation,
} from "../../apis/project/Project";
import { IProjectRolesModel } from "../../models/project/IProjectRolesModel";
import {
  bindContentEngineSelectionData,
  bindMetadata,
  bindRolesData,
} from "../../utils/bindProjectData";
import { IProjectMetadataModel } from "../../models/project/IProjectMetadataModel";
import { IProjectPlanningSubSectionModel } from "../../models/project/IProjectPlanningSubSectionModel";
import { IContentEngineSelectionModel } from "../../models/project/IContentEngineSelectionModel";
import { IProjectContentEngine } from "../../models/project/IProjectContentEngine";
import { IOrganization } from "../../models/project/IOrganization";

export interface IProjectTabsProps {
  pageState: any;
}

const ProjectTabs: React.FunctionComponent<IProjectTabsProps> = (props) => {
  const pageState = props.pageState;

  const { instance, accounts } = useMsal();

  const [defaultPlanningSubSection, setDefaultPlanningSubSection] = useState<IProjectPlanningSubSectionModel[]>([]);
  const [isPlanningSubSectionLoaded, setIsPlanningSubSectionLoaded] = useState<boolean>(false);
  const [defaultContentEngineLookup, setDefaultContentEngineLookup] = useState<IProjectMetadataModel[]>([]);
  const [defaultContentEngineSelection, setDefaultContentEngineSelection] = useState<IContentEngineSelectionModel[]>([]);
  const [isContentEngineSelectionLoaded, setIsContentEngineSelectionLoaded] = useState<boolean>(false);
  const [isContentEngineForLookupLoaded, setIsContentEngineForLookupLoaded] = useState<boolean>(false);
  const [isProjectPlanningSubSectionSaved, setIsProjectPlanningSubSectionSaved] = useState<boolean>(false);
  const [isContentEngineSelectionSaved, setIsContentEngineSelectionSaved] = useState<boolean>(false);

  const [locationsAdded, setLocationsAdded] = useState<boolean>(false);

  const [defaultRoles, setDefaultRoles] = useState<IProjectRolesModel[]>([]);
  const [isMandatoryRolesComplete, setIsMandatoryRolesComplete] = useState<boolean>(false);

  const [defaultMetadataLookup, setDefaultMetadataLookup] = useState<IProjectMetadataModel[]>([]);
  const [projectMetadata, setProjectMetadata] = useState<IProjectMetadataModel[]  >([]);

  const [projectContentEngineData, setProjectContentEngineData] = useState<IProjectContentEngine>();
  
  const [organisationList, setOrganisationlist] = useState<IOrganization[]>([]);

  useEffect(() => {
    loadProjectPlanningSubSection();
    loadContentEngineForLookup();
    loadContentEngineSelection();
    loadProjectRoles();
    loadMetadataForLookup();
    loadProjectMetadata();
    loadProjectContentEngine();
    loadOrganisation();
  }, [instance, accounts, pageState.projectDetails.projectId]);

  const loadProjectPlanningSubSection = () => {
    getProjectPlanningSubSection(
      instance,
      accounts,
      pageState.projectDetails.projectId
    ).then((results: any) => {
      setDefaultPlanningSubSection(results);
      setIsPlanningSubSectionLoaded(true);
      if (results.length > 0) {
        setIsProjectPlanningSubSectionSaved(true);
      }
    });
  };

  const loadContentEngineForLookup = () => {
    getContentEngineLookup(instance, accounts).then((results: any) => {
      setDefaultContentEngineLookup(bindMetadata(results));
      setIsContentEngineForLookupLoaded(true);
    });
  };

  const loadContentEngineSelection = () => {
    getContentEngineSelection(
      instance,
      accounts,
      pageState.projectDetails.projectId
    ).then((results: any) => {
      setDefaultContentEngineSelection(bindContentEngineSelectionData(results));
      setIsContentEngineSelectionLoaded(true);
      if (results) {
        setIsContentEngineSelectionSaved(true);
      }
    });
  };

  const loadProjectRoles = () => {
    getProjectRoles(
      instance,
      accounts,
      pageState.projectDetails.projectId
    ).then((results: any) => {
      var rolesData = bindRolesData(results);
      setDefaultRoles(rolesData);
      setIsMandatoryRolesComplete(checkMandatoryRolesComplete(rolesData));
    });
  };

  const loadMetadataForLookup = () => {
    getMetadataLookup(instance, accounts).then((results: any) => {
      setDefaultMetadataLookup(bindMetadata(results));
    });
  };

  const loadProjectMetadata = () => {
    getProjectMetadata(
      instance,
      accounts,
      pageState.projectDetails.projectId
    ).then((results: any) => {
      setProjectMetadata(bindMetadata(results));
    });
  };

  const loadProjectContentEngine = () => {
    getGetContentEngineValues(
      instance,
      accounts,
      pageState.projectDetails.projectId
    ).then((result: IProjectContentEngine) => {
      setProjectContentEngineData(result);
    });
  };

  const loadOrganisation = () => {
    getOrganisation(
      instance,
      accounts
    ).then((result: IOrganization[]) => {
      setOrganisationlist(result);
    });
  };

  const checkMandatoryRolesComplete = (roles: any) => {
    var isProjectManagersComplete = false;
    var isInformationControllersComplete = false;
    var isProjectSupportGroupComplete = false;
    var isProjectAccountantsComplete = false;

    if ((roles[0].ProjectManagers && roles[0].ProjectManagers?.length > 0) ||
      (roles[1].ProjectManagers && roles[1].ProjectManagers?.length > 0)) {
        isProjectManagersComplete = true;
    }

    if ((roles[0].InformationControllers && roles[0].InformationControllers?.length > 0) ||
      (roles[1].InformationControllers && roles[1].InformationControllers?.length > 0)) {
        isInformationControllersComplete = true;
    }

    if ((roles[0].ProjectSupportGroup && roles[0].ProjectSupportGroup?.length > 0) ||
      (roles[1].ProjectSupportGroup && roles[1].ProjectSupportGroup?.length > 0)) {
        isProjectSupportGroupComplete = true;
    }

    if ((roles[0].ProjectAccountants && roles[0].ProjectAccountants?.length > 0) ||
      (roles[1].ProjectAccountants && roles[1].ProjectAccountants?.length > 0)) {
        isProjectAccountantsComplete = true;
    }

    if (isProjectManagersComplete &&
      isInformationControllersComplete &&
      isProjectSupportGroupComplete &&
      isProjectAccountantsComplete) {
        return true;
    } 
    else {
      return false;
    }
  };

  const getIconForPlanning = () => {
    if (isProjectPlanningSubSectionSaved && isContentEngineSelectionSaved) {
      return "check_circle";
    } else {
      return "error";
    }
  };

  const getColourStyleForPlanning = () => {
    if (isProjectPlanningSubSectionSaved && isContentEngineSelectionSaved) {
      return "iconColorGreen";
    } else {
      return "iconColorRed";
    }
  };

  const getIconForRoles = () => {
    if (isMandatoryRolesComplete) {
      return "check_circle";
    } else {
      return "error";
    }
  };

  const getColourStyleForRoles = () => {
    if (isMandatoryRolesComplete) {
      return "iconColorGreen";
    } else {
      return "iconColorRed";
    }
  };

  const getIconForLocation = () => {
    return "check_circle";
  };

  const getColourStyleForLocation = () => {
    return locationsAdded ? "iconColorGreen" : "iconColorYellow";
  };

  const getIconForMetadata = () => {
    return "check_circle";
  };

  const getColourStyleForMetadata = () => {
    if (projectMetadata.length > 0) {
      return "iconColorGreen";
    } else {
      return "iconColorYellow";
    }
  };

  const getIconForContentEngine = () => {
    if (projectContentEngineData) {
      return "check_circle";
    } else {
      return "error";
    }
  };

  const getColourStyleForContentEngine = () => {
    if (projectContentEngineData) {
      return "iconColorGreen";
    } else {
      return "iconColorRed";
    }
  };

  const tabsToShow = [
    {
      id: 1,
      label: "Planning",
      icon: getIconForPlanning(),
      iconcolor: getColourStyleForPlanning(),
    },
    {
      id: 2,
      label: "Roles",
      icon: getIconForRoles(),
      iconcolor: getColourStyleForRoles(),
    },
    {
      id: 3,
      label: "Location",
      icon: getIconForLocation(),
      iconcolor: getColourStyleForLocation(),
    },
    {
      id: 4,
      label: "Metadata",
      icon: getIconForMetadata(),
      iconcolor: getColourStyleForMetadata(),
    },
    {
      id: 5,
      label: "Content Engine",
      icon: getIconForContentEngine(),
      iconcolor: getColourStyleForContentEngine(),
    },
  ];

  return (
    <TabMenu tabs={tabsToShow} cssClass={style.projectTabsMenu}>
      <TabContent for={1}>
        {isPlanningSubSectionLoaded &&
        isContentEngineForLookupLoaded &&
        isContentEngineSelectionLoaded ? (
          <Tab1Planning
            pageState={pageState}
            defaultPlanningSubSection={defaultPlanningSubSection}
            defaultContentEngineLookup={defaultContentEngineLookup}
            defaultContentEngineSelection={defaultContentEngineSelection}
            projectContentEngineData={projectContentEngineData}
            handlePlanningSubSectionSave={loadProjectPlanningSubSection}
            handleLoadSavedContentEngineSelection={loadContentEngineSelection}
          />
        ) : (
          <Loader label="Loading Planning Tab..." size="large" />
        )}
      </TabContent>
      <TabContent for={2}>
        <Tab2Roles
          pageState={pageState}
          defaultRoles={defaultRoles}
          defaultContentEngineSelection={defaultContentEngineSelection}
          defaultPlanningSubSection={defaultPlanningSubSection}
          handleCheckMandatoryRoles={loadProjectRoles}
          projectContentEngineData={projectContentEngineData}

          organisationList ={organisationList}
        />
      </TabContent>
      <TabContent for={3}>
        <Tab3Location
          pageState={pageState}
          onLocationsAdded={(added) => setLocationsAdded(added)}
        />
      </TabContent>
      <TabContent for={4}>
        <Tab4Metadata
          pageState={pageState}
          defaultMetadataLookup={defaultMetadataLookup}
          projectMetadata={projectMetadata}
          refresh={loadProjectMetadata}
        />
      </TabContent>
      <TabContent for={5}>
        <Tab5ContentEngines
          pageState={pageState}
          projectContentEngineData={projectContentEngineData}
          defaultPlanningSubSection={defaultPlanningSubSection}
          defaultContentEngineSelection={defaultContentEngineSelection}
          defaultRoles={defaultRoles}
          isProjectPlanningSubSectionSaved={isProjectPlanningSubSectionSaved}
          isContentEngineSelectionSaved={isContentEngineSelectionSaved}
          isMandatoryRolesComplete={isMandatoryRolesComplete}
          handleContentEngineCreation={loadProjectContentEngine}
        />
      </TabContent>
    </TabMenu>
  );
};

export default ProjectTabs;

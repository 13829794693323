import { IWebMaperDictionary } from "../../../models/map/IWebMaperDictionary";
import LayerView from "@arcgis/core/views/layers/LayerView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayerView from "@arcgis/core/views/layers/FeatureLayerView";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import { ILocationItem } from "../../../models/map/ILocationItem";
import { GeometryType } from "../../../constants/ErsiMapConst";
import { Point } from "@arcgis/core/geometry";

let layers: IWebMaperDictionary = {};

export const cacheLayerViews = (
  layerView: LayerView,
  onLayerViewUpdating?: (lView: LayerView) => void
): void => {
  const layer = layerView.layer as FeatureLayer;

  if (!layer || !layer.geometryType || layers[layer.geometryType]) return;

  layer.visible = false;
  reactiveUtils.when(
    () => !layerView.updating,
    () => onLayerViewUpdating && onLayerViewUpdating(layerView)
  );

  layers[layer.geometryType] = layerView;
};

export const clearLayerViewsCache = (): void => {
  layers = {};
};

export const getLayerView = (geometryType: string): LayerView | null => {
  if (!geometryType || !layers[geometryType]) return null;
  return layers[geometryType];
};

export const queryFeatureLayerView = async (
  projectId: string,
  layerView: LayerView
): Promise<ILocationItem[]> => {
  const layer = layerView.layer as FeatureLayer;
  layer.definitionExpression = `dwsid = '${projectId}' AND date_superseded IS NULL`;
  layer.outFields = ["*"];
  const results = await (layerView as FeatureLayerView).queryFeatures();

  layer.visible = true;
  return results.features
    .filter((ft) => !!ft.attributes.dwsid)
    .map((ft) => {
      const extent =
        ft.attributes.origin !== "Drawn" &&
        ft.geometry.type !== GeometryType.Point
          ? JSON.parse(ft.attributes.extent)
          : null;

      return {
        id: ft.attributes.objectid,
        name: ft.attributes.name,
        origin: ft.attributes.origin,
        geometryType: ft.geometry.type,
        createdBy: ft.attributes.lic_create_user,
        createdDate: ft.attributes.lic_create_date,
        updatedBy: ft.attributes.last_edited_user,
        updatedDate: ft.attributes.last_edited_date,
        geometryLocation:
          ft.geometry.type === GeometryType.Point
            ? (ft.geometry as Point)
            : null,
        geometryExtent: {
          xmin: extent?.xmin || 0,
          xmax: extent?.xmax || 0,
          ymin: extent?.ymin || 0,
          ymax: extent?.ymax || 0,
        },
      };
    });
};

export const addNewFeature = async (
  projectId: string,
  userName: string,
  graphic: Graphic | null
): Promise<void> => {
  if (!graphic || !projectId || !layers[graphic.geometry.type]) return;
  const now = new Date().getTime();
  const attributes = {
    created_user: userName,
    created_date: now,
    last_edited_user: userName,
    last_edited_date: now,
    lic_create_user: userName,
    lic_create_date: now,
    date_superseded: null,
    dwsid: projectId,
    source: "LIC",
    extent: JSON.stringify(graphic.geometry.extent),
  };

  const newGraphic = graphic.clone();
  newGraphic.attributes = {
    ...attributes,
    origin: graphic.attributes.origin,
    name: graphic.attributes.name,
  };
  const layer = layers[graphic.geometry.type].layer as FeatureLayer;
  await layer.applyEdits({
    addFeatures: [newGraphic],
  });
};

export const deleteFeature = async (item: ILocationItem): Promise<number> => {
  const layer = layers[item.geometryType].layer as FeatureLayer;
  if (!layer) return 0;

  const result = await layer.applyEdits({
    deleteFeatures: [{ objectId: item.id }],
  });
  return result.deleteFeatureResults.length;
};

import {
  SimpleFillSymbol,
  SimpleLineSymbol,
  SimpleMarkerSymbol,
} from "@arcgis/core/symbols";
import { GeometryType } from "../../../constants/ErsiMapConst";

export const geometrySymbols = {
  [GeometryType.Point]: new SimpleMarkerSymbol({
    style: "square",
    color: "#8A2BE2",
    size: "16px",
    outline: {
      color: [255, 255, 255],
      width: 3,
    },
  }),
  [GeometryType.Polygon]: new SimpleFillSymbol({
    color: "rgba(138,43,226, 0.2)",
    style: "solid",
    outline: {
      color: "white",
      width: 1,
    },
  }),
  [GeometryType.Polyline]: new SimpleLineSymbol({
    color: "#8A2BE2",
    width: "4",
    style: "dash",
  }),
};

import React, { useState } from 'react';
import { Button, Dropdown, Grid, Pagination, SearchBox, IDropdownItemProps, Loader } from '@aurecon-creative-technologies/styleguide';

import style from '../../styles/components/projects/ProjectGrid.module.scss';
import { SortType } from '../../enums/SortType';
import { IProjectModel } from '../../models/project/IProjectModel';
import NoProjects from '../project/NoProjects';
import ProjectCard from '../project/ProjectCard';

interface Props {
    projectData: IProjectModel[];
    totalProjectsCount: number;
    isLoading: boolean;
    onSearch: (searchValue: string) => void;
    onPageChange: (pageNumber: number) => void;
    onSortByChange: (sortBy: string) => void;
    onSortOrderChange: (sortDirection: string) => void;
}

const ProjectGrid: React.FunctionComponent<Props> = (props) => {
    const pageSize = 8;    
    const sortDropdownItems: IDropdownItemProps[] = [{id: 1, label: 'Project Id'}, {id: 2, label: 'Project Name'}];
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedSortValue, setSelectedSortValue] = useState<number>(1);
    const [selectedSortDirection, setSelectedSortDirection] = useState<SortType>(SortType.Desc);

    const handleSearchProject = () => {
        setCurrentPage(1);
        props.onSearch(searchValue);
    }

    const handleSearchChange = (searchText: string, isReset: boolean) => {
        setSearchValue(searchText);
        if(isReset) {
            setCurrentPage(1);
            props.onSearch("");
        }
        
        // if (doSearch || searchText.length >= 3) {
        //     setCurrentPage(1);
        //     props.onSearch(searchText);
        // }
    }

    const handleSortDropdownChange = (sortBy: string | number) => {
        setSelectedSortValue(Number(sortBy));
        switch (Number(sortBy)) {
            case 1:
                setCurrentPage(1);
                props.onSortByChange("ProjectId");
                break;
            case 2:
                setCurrentPage(1);
                props.onSortByChange("ProjectName");
                break;
            default:
                setCurrentPage(1);
                props.onSortByChange("ProjectId");
                break;
        }
    }

    const handleSortOrderDirection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        var tmpSelectedSortDirection = selectedSortDirection === SortType.Desc ? SortType.Asc : SortType.Desc;
        setSelectedSortDirection(tmpSelectedSortDirection);
        e.currentTarget?.blur();
        setCurrentPage(1);
        props.onSortOrderChange(tmpSelectedSortDirection);
    };

    const handlePageChange = (page: number) => {
        //check current page - might not be a 'change'
        if (page !== 0) {
            setCurrentPage(page);
            props.onPageChange(page);
        }
    }

    const formatTotalProjects = () => {
        const firstItemOnPage  = (currentPage - 1) * pageSize + 1;
        const lastItemOnPage = Math.min(currentPage * pageSize, props.totalProjectsCount);

        const showNumberOfProjects = `${firstItemOnPage} - ${lastItemOnPage} of ${props.totalProjectsCount} Projects`;
        return (
            <span>{showNumberOfProjects}</span>
        )
    }

    return (
        <React.Fragment>
            <Grid row md={12} cssClass={style.projectsPageContainerHeader}>
                <h2>Projects</h2>
                <p>
                    Find your project by searching on project information like project name, project ID and client name.
                    Click on the project name to open your project to complete planning stages and mobilise your project environments.
                </p>
            </Grid>
            <Grid row md={12} cssClass={style.projectsPageContainerFiltersContainer}>
                <Grid item md={6} cssClass={style.projectsPageContainerSearchContainer}>
                    <SearchBox
                        defaultValue={ searchValue }
                        placeholder="Search by Project ID, Project Name or Client..."
                        onChange={(value: string) => { handleSearchChange(value, false) }}
                        onSearch={() => { handleSearchProject() }}
                        onClear={() => { handleSearchChange('', true) }}
                    />
                </Grid>
                <Grid item md={6} cssClass={style.projectsPageContainerSortContainer}>
                    <span>Sort</span>
                    <div className={style.projectsPageSortDropdownContainer}>
                        <Dropdown
                            items={sortDropdownItems}
                            selectedItem={selectedSortValue}
                            onSelectItem={(selected) => { handleSortDropdownChange(selected) }}
                            size="medium" />
                    </div>
                    <Button
                        type="icon-square"
                        size="medium"
                        icon="sort"
                        onClick={handleSortOrderDirection}
                        cssClass={`${style.sortButton} ${selectedSortDirection === SortType.Asc ? style.asc : ""}`}
                    />
                </Grid>
            </Grid>
            <Grid row md={12} gap={12} cssClass={style.projectCardsContainer}>
                {!props.isLoading && props.projectData.length > 0 && props.projectData.map((project: IProjectModel) => {
                    return (
                        <Grid item md={3} key={"project-grid" + project.projectId}>
                            <ProjectCard projectData={project} />
                        </Grid>
                    );
                })}
                {!props.isLoading && props.projectData.length === 0 &&
                    <NoProjects />
                }
                {props.isLoading && 
                    <div className={style.projectCardsLoaderContainer}>
                        <Loader label="Loading data..." size="extra large" />
                    </div>
                }
            </Grid>
            <Grid row md={12} cssClass={style.projectsPaginationContainer}>
                {props.projectData.length > 0 &&
                    <div className={style.projectsPagination}>
                        {Math.ceil(props.totalProjectsCount / pageSize) > 1 &&
                            <Pagination
                                page={currentPage}
                                pageCount={Math.ceil(props.totalProjectsCount / pageSize)}
                                onChange={(page: number) => {
                                    handlePageChange(page);
                                }}
                                showJumpToInput={true}
                            />
                        }
                        <div className={style.projectsTotalCountContainer}>{formatTotalProjects()}</div>
                    </div>
                }
            </Grid>
        </React.Fragment>
    )
};

export default ProjectGrid;
import style from '../../../styles/components/project/ProjectTabs.module.scss';

import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { PeoplePicker } from '@microsoft/mgt-react';
import { Button, InfoTooltip } from '@aurecon-creative-technologies/styleguide';

import { IProjectRolesModel } from '../../../models/project/IProjectRolesModel';
import { IPeoplePickerData } from '../../../models/project/IPeoplePickerData';
import { IProjectPerson } from '../../../models/project/IProjectPerson';
import { IProjectDetails } from '../../../models/project/IProjectDetails';
import { updateProjectRoles } from '../../../apis/project/Project';
import { IExternalRoles } from '../../../models/project/IExternalRoles';
import { name } from 'esri/layers/support/VoxelVariable';
import { IContentEngineSelectionModel } from '../../../models/project/IContentEngineSelectionModel';
import { IProjectPlanningSubSectionModel } from '../../../models/project/IProjectPlanningSubSectionModel';
import { IProjectContentEngine } from '../../../models/project/IProjectContentEngine';
import { IOrganization } from '../../../models/project/IOrganization';
import { IProjectWiseTriggerClientAndOrg, IProjectWiseTriggerModel, IProjectWiseTriggerRoles } from '../../../models/project/IProjectWiseTriggerModel';
import { triggerProjectWiseCreation } from '../../../apis/project/Project';

import Tooltip from '../../../constants/CustomTooltip';


interface IProjectRolesProps {
    pageState: any;
    projectContentEngineData?: IProjectContentEngine;
    defaultRoles: IProjectRolesModel[];
    defaultContentEngineSelection: IContentEngineSelectionModel[];
    defaultPlanningSubSection: IProjectPlanningSubSectionModel[];
    organisationList: IOrganization[];
    handleCheckMandatoryRoles: () => void;
}

const Tab2Roles: React.FunctionComponent<IProjectRolesProps> = (props) => {

    const pageState: IProjectDetails = props.pageState;
    const defaultRoles: IProjectRolesModel[] = props.defaultRoles;

    const { instance, accounts } = useMsal();
    const currentUserEmailAddress = accounts[0] && accounts[0].username;

    const [editMode, setEditMode] = useState(Boolean);
    const [isLoading, setIsLoading] = useState(false);
 
    const [isExternalProcessRequired, setisExternalProcessRequired] = useState(Boolean);
    const [isConfidential, setisConfidential] = useState(Boolean);
    const [isSameExternalAccess, setisSameExternalAccess] = useState(Boolean);

    const [selectedProjectManagers, setSelectedProjectManagers] = useState<any>([]);
    const [RemovedProjectManagers, setRemovedProjectManagers] = useState<any>([]);
    const [AdditionalProjectManagers, setAdditionalProjectManagers] = useState<any>([]);
    const [ProjectManagererrorMessage, setProjectManagerErrorMessage] = useState('');
    
    const [selectedInformationControllers, setSelectedInformationControllers] = useState<any>([]);
    const [RemovedInformationControllers, setRemovedInformationControllers] = useState<any>([]);
    const [AdditionalInformationControllers, setAdditionalInformationControllers] = useState<any>([]);
    const [InformationControllererrorMessage, setInformationControllerErrorMessage] = useState('');

    const [selectedProjectSupportGroup, setSelectedProjectSupportGroup] = useState<any>([]);
    const [RemovedProjectSupportGroup, setRemovedProjectSupportGroup] = useState<any>([]);
    const [AdditionalProjectSupportGroup, setAdditionalProjectSupportGroup] = useState<any>([]);
    const [ProjectSupportGroupErrorMessage, setProjectSupportGroupErrorMessage] = useState('');

    const [selectedProjectAccountants, setSelectedProjectAccountants] = useState<any>([]);
    const [RemovedProjectAccountants, setRemovedProjectAccountants] = useState<any>([]);
    const [AdditionalProjectAccountants, setAdditionalProjectAccountants] = useState<any>([]);
    const [ProjectAccountantErrorMessage, setProjectAccountantErrorMessage] = useState('');

    const [selectedReviewers, setSelectedReviewers] = useState<any>([]);
    const [RemovedReviewers, setRemovedReviewers] = useState<any>([]);
    const [AdditionalReviewers, setAdditionalReviewers] = useState<any>([]);
    const [ReviewerErrorMessage, setReviewerErrorMessage] = useState('');

    const [selectedApprovers, setSelectedApprovers] = useState<any>([]);
    const [RemovedApprovers, setRemovedApprovers] = useState<any>([]);
    const [AdditionalApprovers, setAdditionalApprovers] = useState<any>([]);
    const [ApproverErrorMessage, setApproverErrorMessage] = useState('');

    const [selectedAuthorisers, setSelectedAuthorisers] = useState<any>([]);
    const [RemovedAuthorisers, setRemovedAuthorisers] = useState<any>([]);
    const [AdditionalAuthorisers, setAdditionalAuthorisers] = useState<any>([]);
    const [AuthoriserErrorMessage, setAuthoriserErrorMessage] = useState('');

    const [selectedVerifiers, setSelectedVerifiers] = useState<any>([]);
    const [RemovedVerifiers, setRemovedVerifiers] = useState<any>([]);
    const [AdditionalVerifiers, setAdditionalVerifiers] = useState<any>([]);
    const [VerifierErrorMessage, setVerifierErrorMessage] = useState('');

    const [selectedDigitalPracticeLeader, setSelectedDigitalPracticeLeader] = useState<any>([]);
    const [RemovedDigitalPracticeLeader, setRemovedDigitalPracticeLeader] = useState<any>([]);
    const [AdditionalDigitalPracticeLeader, setAdditionalDigitalPracticeLeader] = useState<any>([]);
    const [DigitalPracticeLeaderErrorMessage, setDigitalPracticeLeaderErrorMessage] = useState('');

    const [selectedProjectMember, setSelectedProjectMember] = useState<any>([]);
    const [RemovedProjectMember, setRemovedProjectMember] = useState<any>([]);
    const [AdditionalProjectMember, setAdditionalProjectMember] = useState<any>([]);
    const [ProjectMemberErrorMessage, setProjectMemberErrorMessage] = useState('');

    const [selectedInternalViewer, setselectedInternalViewer] = useState<any>([]);
    const [previousPWInternalViewer, setpreviousPWInternalViewer] = useState<any>([]);
    const [AddedPWInternalViewer, setAddedPWInternalViewer] = useState<any>([]);
    const [RemovedPWInternalViewer, setRemovedPWInternalViewer] = useState<any>([]);
    const [InternalViewerErrorMessage, setInternalViewerErrorMessage] = useState('');
    
    const [selectedOrganization, setselectedOrganization] = useState<any>();
    const [selectedOrgChar, setselectedOrgChar] = useState<any>();
    const [OrganisationList, SetOrganisationList] = useState<IOrganization[]>();
    const [rows, setRows] = useState<IExternalRoles[]>([
        {
            Name: '',
            Email: '',
            Role: 'Viewer',
            Organization: '',
            OrganizationCode: '',
            IsInformationController: false
        },
    ]);
    const[removedExternalMember, setRemovedExternal] = useState<IExternalRoles[]>([]);
    const[PreviousExternalMember, setPreviousExternalMember] = useState<IExternalRoles[]>([]);
    
    const [externalMember, setExternalMember] = useState([
        { email:'', role: 'SupplierCollaborator'},
      ]);
    const[PreviousOSExternalMember, setPreviousOSExternalMember] = useState([
        { email:'', role: 'SupplierCollaborator'},
      ]);
    const [removedOnespaceExternalMember, setRemovedOnespaceExternalMember] = useState<IExternalRoles[]>([]);
    
    const [is12dProjectWiseEnabled, setis12dProjectWiseEnabled]= useState<boolean>(false);
    const [isProjectWiseEnabled, setisProjectWiseEnabled]= useState<boolean>(false);
    const [isBIM360Enabled, setisBIM360Enabled]= useState<boolean>(false);
    const [is12dEnabled, setis12dEnabled]= useState<boolean>(false);
    const [isTransmittalsRequired, setisTransmittalsRequired]= useState<boolean>(false);

    const [isOneSpaceEnabled, setisOneSpaceEnabled]= useState<boolean>(false);

    const [IsOneSpaceExternalRolesModified, setIsOneSpaceExternalRolesModified]= useState<boolean>(false);
    const [IsMandatoryRolesModified, setIsMandatoryRolesModified]= useState<boolean>(false);
    const [isPWExmodified, setisPWExmodified]= useState<boolean>(false);

    



    useEffect(() => {
        setRolesAndPeople();
    }, []);

    const setRolesAndPeople = () => {
        setSelectedProjectManagers(setDefaultRoles('ProjectManagers'));
        setSelectedInformationControllers(setDefaultRoles('InformationControllers'));
        setSelectedProjectSupportGroup(setDefaultRoles('ProjectSupportGroup'));
        setSelectedProjectAccountants(setDefaultRoles('ProjectAccountants'));
        setSelectedReviewers(setDefaultRoles('Reviewers'));
        setSelectedApprovers(setDefaultRoles('Approvers'));
        setSelectedAuthorisers(setDefaultRoles('Authorisers'));
        setSelectedVerifiers(setDefaultRoles('Verifiers'));
        setSelectedDigitalPracticeLeader(setDefaultRoles('DigitalPracticeLeader'));
        setSelectedProjectMember(setDefaultRoles('ProjectMembers'));
        setselectedInternalViewer(setDefaultExternalRolesPeople('InternalViewer'));
        setExternalMember(setDefaultExternalRolesText('SupplierCollaborator'));
        setPreviousOSExternalMember(setDefaultExternalRolesText('SupplierCollaborator'));
        setpreviousPWInternalViewer(setDefaultExternalRolesPeople('InternalViewer'));
        setselectedOrganization(setDefaultExternalOrganization(true));
        setselectedOrgChar(setDefaultExternalOrganization(false));
        SetOrganisationList(props.organisationList);

        setRows(setDefaultRow());
        setPreviousExternalMember(setDefaultRow());
        setis12dProjectWiseEnabled(set12dProjectWiseEnabled());
        setisOneSpaceEnabled(setOneSpaceEnabled());

        if(props.defaultPlanningSubSection[0]){
            setisExternalProcessRequired(props.defaultPlanningSubSection[0].isExternalProcessRequired? props.defaultPlanningSubSection[0].isExternalProcessRequired : false);
            setisConfidential(props.defaultPlanningSubSection[0].isProjectConfidential? props.defaultPlanningSubSection[0].isProjectConfidential: false);
            setisSameExternalAccess(props.defaultPlanningSubSection[0].isSameAccess? props.defaultPlanningSubSection[0].isSameAccess: false);
        }else{
            setisExternalProcessRequired( false);
            setisConfidential(false);
            setisSameExternalAccess(false);
        }
        if(props.defaultContentEngineSelection && props.defaultContentEngineSelection[0]){
            setisTransmittalsRequired(props.defaultContentEngineSelection[0].projectWiseSelection ? props.defaultContentEngineSelection[0].projectWiseSelection.IsTransmittalsRequired : false)
        }else{
            setisTransmittalsRequired(false);
        }

    }

    const addExternalMember = () => {
        const newMember = {
            email: '',
            role: 'SupplierCollaborator'
        };
        setExternalMember([...externalMember, newMember]);
    };

    
    const onClickEditMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditMode(true);
    };

    const onClickDisplayMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditMode(false);
    };

    const onClickCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setRolesAndPeople();
        setEditMode(false);
    };

    const checkAurecongroup = (email: string) => {
        if(email){
            const domain = email.split('@')[1];
            const isAurecon = /^aurecongroup\.com$/.test(domain);
            
            return isAurecon;

        }else{
            return false;
        }
      };

    
    const RemoveDuplicateNames = (selectedPeople: any) =>{
        let distinctselectedPeople: IPeoplePickerData[] = [];
        const sanitizedArray: IPeoplePickerData[] = [];

        if(selectedPeople){
            selectedPeople.map((person: any) => {
                var defaultRole: IPeoplePickerData = {
                    userPrincipalName: person.userPrincipalName.toLowerCase(),
                    displayName: person.displayName
                }
                sanitizedArray.push(defaultRole);
            });


            distinctselectedPeople = sanitizedArray.filter( (ele, ind) => ind === sanitizedArray.findIndex( person => person.userPrincipalName === ele.userPrincipalName && person.displayName === ele.displayName))
        }
        return distinctselectedPeople;

    }

    const onSelectionChanged_ProjectManager = (e: any) => {
        setProjectManagerErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let PMs = DistinctNames;
        let hasExternal = false;

        //validate if the user is internal
        PMs.forEach((pm: any) => {
            if(!(pm.userPrincipalName) && !(checkAurecongroup(pm.userPrincipalName))){
                hasExternal= true
                setProjectManagerErrorMessage("External user is not allowed.");
                return;
            }
        });
              
        //Check the removed PMs
        
        setSelectedProjectManagers(DistinctNames);
        
        //validation: check first if the added users exists in removed user list
        if(RemovedProjectManagers && RemovedProjectManagers.length > 0){
            const existsinList = PMs.filter((pm: IPeoplePickerData) => !(RemovedProjectManagers.some((removedpm: any) => removedpm.displayName === pm.displayName)));
            if(existsinList){
                const filteredPMs = PMs.filter((pm: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === pm.displayName));
                setRemovedProjectManagers(filteredPMs);
            }
        }
        if((props.defaultRoles[0].ProjectManagers && props.defaultRoles[0].ProjectManagers?.length > 0) || props.defaultRoles[1].ProjectManagers && props.defaultRoles[1].ProjectManagers?.length > 0) {
            let previousPMs: IPeoplePickerData[]= [];
            for (var i in props.defaultRoles) {
                props.defaultRoles[i].ProjectManagers?.forEach((defRole: IProjectPerson) => {
                    var defaultRole: IPeoplePickerData = {
                        userPrincipalName: defRole.email,
                        displayName: defRole.name
                    }
                    previousPMs.push(defaultRole);
                })
                
            }
            //match all previous pm list to the new selected pm
            const removed = previousPMs.filter(pm => !(PMs.some((newPM: any) => newPM.displayName === pm.displayName)));
            if(removed && removed.length > 0){
                setRemovedProjectManagers(removed);
                setIsMandatoryRolesModified(true);
            }
            // check additional pm
            const added = PMs.filter((pm: IPeoplePickerData) => !(previousPMs.some((prevPM: any) => prevPM.displayName === pm.displayName)));
            if(added && added.length > 0){
                setAdditionalProjectManagers(added);
                setIsMandatoryRolesModified(true);
            }
        }

    };

    const onSelectionChanged_InformationControllers = (e: any) => {
        setInformationControllerErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let ICs = DistinctNames;
        let hasExternal = false;

        //validate if the user is internal
        ICs.forEach((ic: any) => {
            if(!(ic.userPrincipalName) && !(checkAurecongroup(ic.userPrincipalName))){
                hasExternal= true
                setInformationControllerErrorMessage("External user is not allowed.");
                return;
            }
        });
            

        setSelectedInformationControllers(DistinctNames);

        //validation: check first if the added users exists in removed user list
        if(RemovedInformationControllers && RemovedInformationControllers.length > 0){
            const existsinList = ICs.filter((ic: IPeoplePickerData) => !(RemovedInformationControllers.some((removedic: any) => removedic.displayName === ic.displayName)));
            if(existsinList){
                const filteredPMs = ICs.filter((pm: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === pm.displayName));
                setRemovedInformationControllers(filteredPMs);
            }
        }

        if((props.defaultRoles[0].InformationControllers && props.defaultRoles[0].InformationControllers?.length > 0) || props.defaultRoles[1].InformationControllers && props.defaultRoles[1].InformationControllers?.length > 0) {
            let previousICs: IPeoplePickerData[]= [];
            for (var i in props.defaultRoles) {
                props.defaultRoles[i].InformationControllers?.forEach((defRole: IProjectPerson) => {
                    var defaultRole: IPeoplePickerData = {
                        userPrincipalName: defRole.email,
                        displayName: defRole.name
                    }
                    previousICs.push(defaultRole);
                })
                
            }
            //match all previous ic list to the new selected ic
            const removed = previousICs.filter(pm => !(ICs.some((newIC: any) => newIC.displayName === pm.displayName)));
            if(removed && removed.length > 0){
                setRemovedInformationControllers(removed);
                setIsMandatoryRolesModified(true);
            }
            // check additional ic
            const added = ICs.filter((ic: IPeoplePickerData) => !(previousICs.some((prevIC: any) => prevIC.displayName === ic.displayName)));
            if(added && added.length > 0){
                setAdditionalInformationControllers(added);
                setIsMandatoryRolesModified(true);
            }
        }else{
            setAdditionalInformationControllers(ICs);
            setIsMandatoryRolesModified(true);
        }
    };

    const onSelectionChanged_ProjectSupportGroup = (e: any) => {
        setProjectSupportGroupErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let PSGs = DistinctNames;
        let hasExternal = false;

        //validate if the user is internal
        PSGs.forEach((psg: any) => {
            if(!(psg.userPrincipalName) && !(checkAurecongroup(psg.userPrincipalName))){
                hasExternal= true
                setProjectSupportGroupErrorMessage("External user is not allowed.");
                return;
            }
        });
      
            setSelectedProjectSupportGroup(DistinctNames);

            if(RemovedProjectSupportGroup && RemovedProjectSupportGroup.length > 0){
                const existsinList = PSGs.filter((psg: IPeoplePickerData) => !(RemovedProjectSupportGroup.some((removedpsg: any) => removedpsg.displayName === psg.displayName)));
                if(existsinList){
                    const filteredPMs = PSGs.filter((psg: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === psg.displayName));
                    setRemovedProjectSupportGroup(filteredPMs);
                }
            }
            if((props.defaultRoles[0].ProjectSupportGroup && props.defaultRoles[0].ProjectSupportGroup?.length > 0) || props.defaultRoles[1].ProjectSupportGroup && props.defaultRoles[1].ProjectSupportGroup?.length > 0) {
                let previousPSGs: IPeoplePickerData[]= [];
                for (var i in props.defaultRoles) {
                    props.defaultRoles[i].ProjectSupportGroup?.forEach((defRole: IProjectPerson) => {
                        var defaultRole: IPeoplePickerData = {
                            userPrincipalName: defRole.email,
                            displayName: defRole.name
                        }
                        previousPSGs.push(defaultRole);
                    })
                    
                }
                //match all previous projectsupportgroup list to the newly selected projectsupportgroup
                const removed = previousPSGs.filter(psg => !(PSGs.some((newPSG: any) => newPSG.displayName === psg.displayName)));

                if(removed && removed.length > 0){
                    setRemovedProjectSupportGroup(removed);
                    setIsMandatoryRolesModified(true);
                }

                // check additional project support group
                const addedpsg = PSGs.filter((psg: IPeoplePickerData) => !(previousPSGs.some((prevPsg: any) => prevPsg.displayName === psg.displayName)));
                if(addedpsg && addedpsg.length > 0){
                    setAdditionalProjectSupportGroup(addedpsg);
                    setIsMandatoryRolesModified(true);
                }
            }else{
                setAdditionalProjectSupportGroup(PSGs);
                setIsMandatoryRolesModified(true);

            }
    };

    const onSelectionChanged_ProjectAccountant = (e: any) => {
        setProjectAccountantErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let PAs = DistinctNames; // PA is Project Accountants
        let hasExternal = false;

        //validate if the user is internal
        PAs.forEach((pa: any) => {
            if(!(pa.userPrincipalName) && !(checkAurecongroup(pa.userPrincipalName))){
                hasExternal= true
                setProjectAccountantErrorMessage("External user is not allowed.");
                return;
            }
        });
        
            setSelectedProjectAccountants(DistinctNames);
            
            if(RemovedProjectAccountants && RemovedProjectAccountants.length > 0){
                const existsinList = PAs.filter((pa: IPeoplePickerData) => !(RemovedProjectAccountants.some((removedpa: any) => removedpa.displayName === pa.displayName)));
                if(existsinList){
                    const filteredPAs = PAs.filter((pa: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === pa.displayName));
                    setRemovedProjectAccountants(filteredPAs);
                }
            }

            if((props.defaultRoles[0].ProjectAccountants && props.defaultRoles[0].ProjectAccountants?.length > 0) || props.defaultRoles[1].ProjectAccountants && props.defaultRoles[1].ProjectAccountants?.length > 0) {
                let previousPAs: IPeoplePickerData[]= [];
                for (var i in props.defaultRoles) {
                    props.defaultRoles[i].ProjectAccountants?.forEach((defRole: IProjectPerson) => {
                        var defaultRole: IPeoplePickerData = {
                            userPrincipalName: defRole.email,
                            displayName: defRole.name
                        }
                        previousPAs.push(defaultRole);
                    })
                    
                }
                //match all previous ProjectAccountants list to the newly selected ProjectAccountants
                const removed = previousPAs.filter(pa => !(PAs.some((newPA: any) => newPA.displayName === pa.displayName)));
                if(removed && removed.length > 0){
                    setRemovedProjectAccountants(removed);
                    setIsMandatoryRolesModified(true);
                }

                // check additional Project Accountants
                const addedpas = PAs.filter((pa: IPeoplePickerData) => !(previousPAs.some((prevPA: any) => prevPA.displayName === pa.displayName)));
                if(addedpas && addedpas.length > 0){
                    setAdditionalProjectAccountants(addedpas);
                    setIsMandatoryRolesModified(true);
                }
            }else{
                setAdditionalProjectAccountants(PAs);
                setIsMandatoryRolesModified(true);
            }
    };

    const onSelectionChanged_Reviewers = (e: any) => {
        setReviewerErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let Reviewers = DistinctNames; 
        let hasExternal = false;

        //validate if the user is internal
        Reviewers.forEach((rev: any) => {
            if(!(rev.userPrincipalName) && !(checkAurecongroup(rev.userPrincipalName))){
                hasExternal= true
                setReviewerErrorMessage("External user is not allowed.");return;
            }
        });
      
         
            setSelectedReviewers(DistinctNames);
            
            if(RemovedReviewers && RemovedReviewers.length > 0){
                const existsinList = Reviewers.filter((reviewer: IPeoplePickerData) => !(RemovedReviewers.some((removedreviewer: any) => removedreviewer.displayName === reviewer.displayName)));
                if(existsinList){
                    const filteredreviewer = Reviewers.filter((reviewer: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === reviewer.displayName));
                    setRemovedReviewers(filteredreviewer);
                }
            }
            if((props.defaultRoles[0].Reviewers && props.defaultRoles[0].Reviewers?.length > 0) || props.defaultRoles[1].Reviewers && props.defaultRoles[1].Reviewers?.length > 0) {
                let previousReviewers: IPeoplePickerData[]= [];
                for (var i in props.defaultRoles) {
                    props.defaultRoles[i].Reviewers?.forEach((defRole: IProjectPerson) => {
                        var defaultRole: IPeoplePickerData = {
                            userPrincipalName: defRole.email,
                            displayName: defRole.name
                        }
                        previousReviewers.push(defaultRole);
                    })
                    
                }
                //match all previous ProjectAccountants list to the newly selected ProjectAccountants
                const removed = previousReviewers.filter(reviewer => !(Reviewers.some((newReviewer: any) => newReviewer.displayName === reviewer.displayName)));
                if(removed && removed.length > 0){
                    setIsMandatoryRolesModified(true);
                    setRemovedReviewers(removed);
                }

                // check additional Project Accountants
                const addedpas = Reviewers.filter((reviewer: IPeoplePickerData) => !(previousReviewers.some((prevReviewer: any) => prevReviewer.displayName === reviewer.displayName)));
                if(addedpas && addedpas.length > 0){
                    setIsMandatoryRolesModified(true);
                    setAdditionalReviewers(addedpas);
                }
            }else{
                    setIsMandatoryRolesModified(true);
                    setAdditionalReviewers(Reviewers);
            }
    };

    const onSelectionChanged_Approvers = (e: any) => {
        setApproverErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let Approvers = DistinctNames; 
        let hasExternal = false;

        //validate if the user is internal
        Approvers.forEach((app: any) => {
            if(!(app.userPrincipalName) && !(checkAurecongroup(app.userPrincipalName))){
                hasExternal= true
                setApproverErrorMessage("External user is not allowed.");
                return;
            }
        });
      
            setSelectedApprovers(DistinctNames);
            
            if(RemovedApprovers && RemovedApprovers.length > 0){
                const existsinList = Approvers.filter((Approver: IPeoplePickerData) => !(RemovedApprovers.some((removedapprover: any) => removedapprover.displayName === Approver.displayName)));
                if(existsinList){
                    const filteredApprover = Approvers.filter((Approver: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === Approver.displayName));
                    setRemovedApprovers(filteredApprover);
                }
            }
            if((props.defaultRoles[0].Approvers && props.defaultRoles[0].Approvers?.length > 0) || props.defaultRoles[1].Approvers && props.defaultRoles[1].Approvers?.length > 0) {
                let previousApprovers: IPeoplePickerData[]= [];
                for (var i in props.defaultRoles) {
                    props.defaultRoles[i].Approvers?.forEach((defRole: IProjectPerson) => {
                        var defaultRole: IPeoplePickerData = {
                            userPrincipalName: defRole.email,
                            displayName: defRole.name
                        }
                        previousApprovers.push(defaultRole);
                    })
                    
                }
                //match all previous approvers list to the newly selected approvers
                const removed = previousApprovers.filter(Approver => !(Approvers.some((newReviewer: any) => newReviewer.displayName === Approver.displayName)));
                if(removed && removed.length > 0){
                    setIsMandatoryRolesModified(true);
                    setRemovedApprovers(removed);
                }

                // check additional approvers
                const addedApprover = Approvers.filter((Approver: IPeoplePickerData) => !(previousApprovers.some((prevReviewer: any) => prevReviewer.displayName === Approver.displayName)));
                if(addedApprover && addedApprover.length > 0){
                    setIsMandatoryRolesModified(true);
                    setAdditionalApprovers(addedApprover);
                }
            }else{
                    setIsMandatoryRolesModified(true);
                    setAdditionalApprovers(Approvers);
            }
    };

    const onSelectionChanged_Authorisers = (e: any) => {
        setAuthoriserErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let Authorisers = DistinctNames; 
        let hasExternal = false;

        //validate if the user is internal
        Authorisers.forEach((aut: any) => {
            if(!(aut.userPrincipalName) && !(checkAurecongroup(aut.userPrincipalName))){
                hasExternal= true
                setAuthoriserErrorMessage("External user is not allowed.");
                return;
            }
        });
      
        setSelectedAuthorisers(DistinctNames);
        
        if(RemovedAuthorisers && RemovedAuthorisers.length > 0){
            const existsinList = Authorisers.filter((Authoriser: IPeoplePickerData) => !(RemovedAuthorisers.some((removedAuthoriser: any) => removedAuthoriser.displayName === Authoriser.displayName)));
            if(existsinList){
                const filteredAuthoriser = Authorisers.filter((Authoriser: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === Authoriser.displayName));
                setRemovedAuthorisers(filteredAuthoriser);
            }
        }
        if((props.defaultRoles[0].Authorisers && props.defaultRoles[0].Authorisers?.length > 0) || props.defaultRoles[1].Authorisers && props.defaultRoles[1].Authorisers?.length > 0) {
            let previousAuthorisers: IPeoplePickerData[]= [];
            for (var i in props.defaultRoles) {
                props.defaultRoles[i].Authorisers?.forEach((defRole: IProjectPerson) => {
                    var defaultRole: IPeoplePickerData = {
                        userPrincipalName: defRole.email,
                        displayName: defRole.name
                    }
                    previousAuthorisers.push(defaultRole);
                })
                
            }
            //match all previous Authoriser list to the newly selected Authoriser
            const removed = previousAuthorisers.filter(Authoriser => !(Authorisers.some((newAuthoriser: any) => newAuthoriser.displayName === Authoriser.displayName)));
            if(removed && removed.length > 0){
                    setIsMandatoryRolesModified(true);
                    setRemovedAuthorisers(removed);
            }

            // check additional approvers
            const addedAuthoriser = Authorisers.filter((Authoriser: IPeoplePickerData) => !(previousAuthorisers.some((prevReviewer: any) => prevReviewer.displayName === Authoriser.displayName)));
            if(addedAuthoriser && addedAuthoriser.length > 0){
                    setIsMandatoryRolesModified(true);
                    setAdditionalAuthorisers(addedAuthoriser);
            }
        }else{
                    setIsMandatoryRolesModified(true);
                    setAdditionalAuthorisers(Authorisers);
        }
    };

    const onSelectionChanged_Verifiers = (e: any) => {
        setVerifierErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let Verifiers = DistinctNames; 
        let hasExternal = false;

        //validate if the user is internal
        Verifiers.forEach((ver: any) => {
            if(!(ver.userPrincipalName) && !(checkAurecongroup(ver.userPrincipalName))){
                hasExternal= true
                setVerifierErrorMessage("External user is not allowed.");
                return;
            }
        });
      
        setSelectedVerifiers(DistinctNames);
        
        if(RemovedVerifiers && RemovedVerifiers.length > 0){
            const existsinList = Verifiers.filter((Verifier: IPeoplePickerData) => !(RemovedVerifiers.some((removedVerifier: any) => removedVerifier.displayName === Verifier.displayName)));
            if(existsinList){
                const filteredVerifier = Verifiers.filter((Verifier: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === Verifier.displayName));
                setRemovedVerifiers(filteredVerifier);
            }
        }
        if((props.defaultRoles[0].Verifiers && props.defaultRoles[0].Verifiers?.length > 0) || props.defaultRoles[1].Verifiers && props.defaultRoles[1].Verifiers?.length > 0) {
            let previousVerifiers: IPeoplePickerData[]= [];
            for (var i in props.defaultRoles) {
                props.defaultRoles[i].Verifiers?.forEach((defRole: IProjectPerson) => {
                    var defaultRole: IPeoplePickerData = {
                        userPrincipalName: defRole.email,
                        displayName: defRole.name
                    }
                    previousVerifiers.push(defaultRole);
                })
                
            }
            //match all previous Verifier list to the newly selected Verifier
            const removed = previousVerifiers.filter(Verifier => !(Verifiers.some((newVerifier: any) => newVerifier.displayName === Verifier.displayName)));
            if(removed && removed.length > 0){
                    setIsMandatoryRolesModified(true);
                    setRemovedVerifiers(removed);
            }

            // check additional Verifier
            const addedAuthoriser = Verifiers.filter((Verifier: IPeoplePickerData) => !(previousVerifiers.some((prevReviewer: any) => prevReviewer.displayName === Verifier.displayName)));
            if(addedAuthoriser && addedAuthoriser.length > 0){
                    setIsMandatoryRolesModified(true);
                    setAdditionalVerifiers(addedAuthoriser);
            }
        }else{
                    setIsMandatoryRolesModified(true);
                    setAdditionalVerifiers(Verifiers);
        }
    };

    const onSelectionChanged_DigitalPracticeLeader = (e: any) => {
        setDigitalPracticeLeaderErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let DigitalPracticeLeaders = DistinctNames; 
        let hasExternal = false;

        //validate if the user is internal
        DigitalPracticeLeaders.forEach((dpl: any) => {
            if(!(dpl.userPrincipalName) && !(checkAurecongroup(dpl.userPrincipalName))){
                hasExternal= true
                setDigitalPracticeLeaderErrorMessage("External user is not allowed.");
                return;
            }
        });
      
        setSelectedDigitalPracticeLeader(DistinctNames);
        
        if(RemovedDigitalPracticeLeader && RemovedDigitalPracticeLeader.length > 0){
            const existsinList = DigitalPracticeLeaders.filter((DigitalPracticeLeader: IPeoplePickerData) => !(RemovedDigitalPracticeLeader.some((removedDigitalPracticeLeader: any) => removedDigitalPracticeLeader.displayName === DigitalPracticeLeader.displayName)));
            if(existsinList){
                const filteredDigitalPracticeLeader = DigitalPracticeLeaders.filter((DigitalPracticeLeader: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === DigitalPracticeLeader.displayName));
                setRemovedDigitalPracticeLeader(filteredDigitalPracticeLeader);
            }
        }
        if((props.defaultRoles[0].DigitalPracticeLeader && props.defaultRoles[0].DigitalPracticeLeader?.length > 0) || props.defaultRoles[1].DigitalPracticeLeader && props.defaultRoles[1].DigitalPracticeLeader?.length > 0) {
            let previousDigitalPracticeLeader: IPeoplePickerData[]= [];
            for (var i in props.defaultRoles) {
                props.defaultRoles[i].DigitalPracticeLeader?.forEach((defRole: IProjectPerson) => {
                    var defaultRole: IPeoplePickerData = {
                        userPrincipalName: defRole.email,
                        displayName: defRole.name
                    }
                    previousDigitalPracticeLeader.push(defaultRole);
                })
                
            }
            //match all previous DigitalPracticeLeader list to the newly selected DigitalPracticeLeader
            const removed = previousDigitalPracticeLeader.filter(DigitalPracticeLeader => !(DigitalPracticeLeaders.some((newDigitalPracticeLeader: any) => newDigitalPracticeLeader.displayName === DigitalPracticeLeader.displayName)));
            if(removed && removed.length > 0){
                setIsMandatoryRolesModified(true);
                setRemovedDigitalPracticeLeader(removed);
            }

            // check additional DigitalPracticeLeader
            const addedDigitalPracticeLeader = DigitalPracticeLeaders.filter((DigitalPracticeLeader: IPeoplePickerData) => !(previousDigitalPracticeLeader.some((prevDigitalPracticeLeader: any) => prevDigitalPracticeLeader.displayName === DigitalPracticeLeader.displayName)));
            if(addedDigitalPracticeLeader && addedDigitalPracticeLeader.length > 0){
                setIsMandatoryRolesModified(true);
                setAdditionalDigitalPracticeLeader(addedDigitalPracticeLeader);
            }
        }else{
                setIsMandatoryRolesModified(true);
                setAdditionalDigitalPracticeLeader(DigitalPracticeLeaders);
        }
    };

    const onSelectionChanged_ProjectMember = (e: any) => {
        setProjectMemberErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let ProjectMember = DistinctNames; 
        let hasExternal = false;

        //validate if the user is internal
        ProjectMember.forEach((pm: any) => {
            if(!(pm.userPrincipalName) && !(checkAurecongroup(pm.userPrincipalName))){
                hasExternal= true
                setProjectMemberErrorMessage("External user is not allowed.");
                return;
            }
        });
      
        setSelectedProjectMember(DistinctNames);
        
        if(RemovedProjectMember && RemovedProjectMember.length > 0){
            const existsinList = ProjectMember.filter((Authoriser: IPeoplePickerData) => !(RemovedProjectMember.some((removedAuthoriser: any) => removedAuthoriser.displayName === Authoriser.displayName)));
            if(existsinList){
                const filteredProjectMember = ProjectMember.filter((Authoriser: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === Authoriser.displayName));
                setRemovedProjectMember(filteredProjectMember);
            }
        }
        if((props.defaultRoles[0].ProjectMembers && props.defaultRoles[0].ProjectMembers?.length > 0) || props.defaultRoles[1].ProjectMembers && props.defaultRoles[1].ProjectMembers?.length > 0) {
            let previousProjectMember: IPeoplePickerData[]= [];
            for (var i in props.defaultRoles) {
                props.defaultRoles[i].ProjectMembers?.forEach((defRole: IProjectPerson) => {
                    var defaultRole: IPeoplePickerData = {
                        userPrincipalName: defRole.email,
                        displayName: defRole.name
                    }
                    previousProjectMember.push(defaultRole);
                })
                
            }
            //match all previous Authoriser list to the newly selected Authoriser
            const removed = previousProjectMember.filter(Member => !(ProjectMember.some((newMember: any) => newMember.displayName === Member.displayName)));
            if(removed && removed.length > 0){
                    setIsMandatoryRolesModified(true);
                    setRemovedProjectMember(removed);
            }

            // check additional approvers
            const addedProjectmember = ProjectMember.filter((Member: IPeoplePickerData) => !(previousProjectMember.some((prevMember: any) => prevMember.displayName === Member.displayName)));
            if(addedProjectmember && addedProjectmember.length > 0){
                    setIsMandatoryRolesModified(true);
                    setAdditionalProjectMember(addedProjectmember);
            }
        }else{
                    setIsMandatoryRolesModified(true);
                    setAdditionalProjectMember(ProjectMember);
        }
    };

    const onSelectionChanged_OnespaceInternalRole = (e: any) =>{
        setInternalViewerErrorMessage("");
        // Remove duplication
        const DistinctNames = RemoveDuplicateNames(e.target.selectedPeople);
        let PWInternalViewer = DistinctNames; 
        let hasExternal = false;

        //validate if the user is internal
        PWInternalViewer.forEach((iv: any) => {
            if(!(iv.userPrincipalName) && !(checkAurecongroup(iv.userPrincipalName))){
                hasExternal= true
                setInternalViewerErrorMessage("External user is not allowed.");
                return;
            }
        });
      
        setselectedInternalViewer(DistinctNames);
        
        if(RemovedPWInternalViewer && RemovedPWInternalViewer.length > 0){
            const existsinList = PWInternalViewer.filter((viewer: IPeoplePickerData) => !(RemovedPWInternalViewer.some((removedviewer: any) => removedviewer.displayName === viewer.displayName)));
            if(existsinList){
                const filteredviewer = PWInternalViewer.filter((viewer: IPeoplePickerData) => !existsinList.some((item: IPeoplePickerData) => item.displayName === viewer.displayName));
                setRemovedPWInternalViewer(filteredviewer);
            }
        }
        if(previousPWInternalViewer && previousPWInternalViewer?.length > 0) {
            let InternalViewer: IPeoplePickerData[]= [];
            for (var i in props.defaultRoles) {
                props.defaultRoles[i].DigitalPracticeLeader?.forEach((defRole: IProjectPerson) => {
                    var defaultRole: IPeoplePickerData = {
                        userPrincipalName: defRole.email,
                        displayName: defRole.name
                    }
                    InternalViewer.push(defaultRole);
                })
                
            }
            //match all previous InternalViewer list to the newly selected InternalViewer
            const removed = InternalViewer.filter(viewer => !(PWInternalViewer.some((newPWInternalViewer: any) => newPWInternalViewer.displayName === viewer.displayName)));
            if(removed && removed.length > 0){
                setIsMandatoryRolesModified(true);
                setRemovedPWInternalViewer(removed);
            }

            // check additional InternalViewer
            const addedPWInternalViewer = PWInternalViewer.filter((viewer: IPeoplePickerData) => !(InternalViewer.some((prevPWInternalViewer: any) => prevPWInternalViewer.displayName === viewer.displayName)));
            if(addedPWInternalViewer && addedPWInternalViewer.length > 0){
                setIsMandatoryRolesModified(true);
                setAddedPWInternalViewer(addedPWInternalViewer);
            }
        }else{
            setIsMandatoryRolesModified(true);
            setAddedPWInternalViewer(PWInternalViewer);
        }
        
    };

    const handleRowChange_OnespaceExternalRole = (event:  any, rowIndex: number) => {
        let distinctselectedPeople = [
            { email:'', role: 'SupplierCollaborator'},
        ];
        const updatedRows = [...externalMember];

        if (rowIndex >= 0 && rowIndex < updatedRows.length) {
            updatedRows[rowIndex]['email'] = event.target.value as string;
            distinctselectedPeople = updatedRows.filter( (ele, ind) => ind === updatedRows.findIndex( person => person.email === ele.email && person.role === ele.role))
            setExternalMember(distinctselectedPeople);
          } 
          
    };

    const handleRoleChange_OnespaceExternalRole = (event:  any, rowIndex: number) => {
        let distinctselectedPeople = [
            { email:'', role: 'SupplierCollaborator'},
        ];

        const newMembers = [...externalMember];
        const selectedRole = event.target.value;
      
        newMembers[rowIndex] = {
          ...newMembers[rowIndex],
          role: selectedRole,
        };
        distinctselectedPeople = newMembers.filter( (ele, ind) => ind === newMembers.findIndex( person => person.email === ele.email && person.role === ele.role))
      
        setExternalMember(distinctselectedPeople);                       
    };

    const onSaveRoles = () => {

        setIsLoading(true); 
        // setup for projectwise
        //get the previouse list of external user first and compare it to new external user list
        const pwExternalRole = rows.filter((external: IExternalRoles) => !(PreviousExternalMember.some((prevEX: any) => prevEX.Email === external.Email && prevEX.role === external.Role)));
        let PWexModified = isPWExmodified;
        if(pwExternalRole.length > 0 || removedExternalMember.length > 0){
            PWexModified = true;
        }

        const onespaceExternalRole = externalMember.filter((external) => !(PreviousOSExternalMember.some((prevOSEX) => prevOSEX.email === external.email)));
        const ExternalRoleForOnspace = AddNonTableOSExternalRole(onespaceExternalRole,selectedOrganization, selectedOrgChar, true);
        let OSexModified = IsOneSpaceExternalRolesModified;
        if(onespaceExternalRole.length > 0 || removedOnespaceExternalMember.length > 0){
            OSexModified = true;
        }

        const AdditionalRole: IExternalRoles[] = [];

        rows.forEach((row) => {
            AdditionalRole.push({ ...row });
        });

        const InternalViewer = AddNonTableRole(selectedInternalViewer, "InternalViewer",selectedOrganization, selectedOrgChar, true); 
        InternalViewer.forEach((row) => {
            AdditionalRole.push({ ...row });
        });
        
        const OSExternal = AddNonTableOSExternalRole(externalMember,selectedOrganization, selectedOrgChar, true);

        OSExternal.forEach((row) => {
            AdditionalRole.push({ ...row });
        });
               

        const postData: IProjectRolesModel = {
            ProjectId: pageState.projectDetails.projectId,
            ProjectManagers: bindPostData(selectedProjectManagers),
            InformationControllers: bindPostData(selectedInformationControllers),
            ProjectSupportGroup: bindPostData(selectedProjectSupportGroup),
            ProjectAccountants: bindPostData(selectedProjectAccountants),
            Reviewers: bindPostData(selectedReviewers),
            Approvers: bindPostData(selectedApprovers),
            Authorisers: bindPostData(selectedAuthorisers),
            Verifiers: bindPostData(selectedVerifiers),
            DigitalPracticeLeader: bindPostData(selectedDigitalPracticeLeader),
            ProjectMembers: bindPostData(selectedProjectMember),
            ExternalRolesModels: AdditionalRole,
            isExternalProcessRequired: isExternalProcessRequired ,
            IsConfidential: isConfidential,
            Is12DEnabled: is12dEnabled,
            IsProjectWiseEnabled: isProjectWiseEnabled,
            IsOneSpaceEnabled: isOneSpaceEnabled,
            IsBIM360Enabled: isBIM360Enabled,
            isSameExternalAccess:false,
            RemovedProjectManagers: bindPostData(RemovedProjectManagers),
            RemovedInformationControllers: bindPostData(RemovedInformationControllers),
            RemovedProjectSupportGroup: bindPostData(RemovedProjectSupportGroup),
            RemovedProjectAccountants: bindPostData(RemovedProjectAccountants),
            RemovedReviewers: bindPostData(RemovedReviewers),
            RemovedApprovers: bindPostData(RemovedApprovers),
            RemovedAuthorisers: bindPostData(RemovedAuthorisers),
            RemovedVerifiers: bindPostData(RemovedVerifiers),
            RemovedDigitalPracticeLeader: bindPostData(RemovedDigitalPracticeLeader),
            RemovedProjectMember: bindPostData(RemovedProjectMember),
            RemovedInternalViewer: bindPostData(RemovedPWInternalViewer),
            AddedProjectManagers: bindPostData(AdditionalProjectManagers),
            AddedProjectAccountants: bindPostData(AdditionalProjectAccountants),
            AddedInformationControllers: bindPostData(AdditionalInformationControllers),
            AddedProjectSupportGroup: bindPostData(AdditionalProjectSupportGroup), 
            AddedReviewers: bindPostData(AdditionalReviewers),
            AddedApprovers: bindPostData(AdditionalApprovers),
            AddedAuthorisers: bindPostData(AdditionalAuthorisers),
            AddedVerifiers: bindPostData(AdditionalVerifiers),
            AddedDigitalPracticeLeader: bindPostData(AdditionalDigitalPracticeLeader),
            AddedProjectMembers: bindPostData(AdditionalProjectMember),
            AddedInternalViewer: bindPostData(AddedPWInternalViewer),
            AddedExternalMembers: [...pwExternalRole,...ExternalRoleForOnspace],
            PWExternalRolesToBeRemoved: removedExternalMember,
            OneSpaceExternalRolesToBeRemoved: removedOnespaceExternalMember,
            IsMandatoryRolesModified: IsMandatoryRolesModified,
            IsPWExternalRolesModified: PWexModified,
            IsOneSpaceExternalRolesModified: OSexModified,
            transmittalsRequired: isTransmittalsRequired,
            projectName: pageState.projectDetails.projectName,
            projectManager: pageState.projectDetails.projectManagerName,
            createdBy: currentUserEmailAddress,
            Url12D: is12dEnabled ? props.projectContentEngineData?.synergy12dLink : "",  
            ProjectWiseURl: isProjectWiseEnabled ? props.projectContentEngineData?.projectWiseLink: "",  
            OnespaceURL: isOneSpaceEnabled ? props.projectContentEngineData?.oneSpaceGUID: "",
            Bim360URL: ""
        };

        updateProjectRoles(instance, accounts, postData)
        .then((results: IProjectRolesModel) => {
            setEditMode(false);
            props.handleCheckMandatoryRoles();
            setIsLoading(false);
            resetArrayList(InternalViewer);
        });
        
        if(isProjectWiseEnabled){ 
            let RemovedRole: IProjectWiseTriggerRoles[]=[];
            let removedpms = PWroles(bindRoleData(RemovedProjectManagers), "ProjectManager");
            let removedICs = PWroles(bindRoleData(RemovedInformationControllers), "InformationController");
            let removedpsgs = PWroles(bindRoleData(RemovedProjectSupportGroup), "User");
            let removedprojectAccountants = PWroles(bindRoleData(RemovedProjectAccountants), "User");
            let removedReviewers = PWroles(bindRoleData(RemovedReviewers), "Verifier");
            let removedApprovers = PWroles(bindRoleData(RemovedApprovers), "Approver");
            let removedAuthorisers = PWroles(bindRoleData(RemovedAuthorisers), "Authoriser");
            let removedVerifiers = PWroles(bindRoleData(RemovedVerifiers), "Verifier");
            let removedDigitalPracticeLeaders = PWroles(bindRoleData(RemovedDigitalPracticeLeader), "InformationController");
            let removedPWInternalViewer = PWroles(bindRoleData(RemovedPWInternalViewer), "Viewer");
            let removedmember = PWroles(bindRoleData(RemovedProjectMember), "Member");
        RemovedRole =  [...removedpms, ...removedICs, ...removedpsgs,...removedprojectAccountants,...removedReviewers,...removedApprovers,...removedAuthorisers,...removedVerifiers,...removedDigitalPracticeLeaders,...removedPWInternalViewer,...removedmember];


        //AddedRoles
        let PWAdditionalRole: IProjectWiseTriggerRoles[]=[];
            let pms = PWroles(bindRoleData(AdditionalProjectManagers), "ProjectManager");
            let ics = PWroles(bindRoleData(AdditionalInformationControllers), "InformationController");
            let psgs = PWroles(bindRoleData(AdditionalProjectSupportGroup), "User");
            let projectAccountants = PWroles(bindRoleData(AdditionalProjectAccountants), "User");
            let Reviewers = PWroles(bindRoleData(AdditionalReviewers), "Verifier");
            let Approvers = PWroles(bindRoleData(AdditionalApprovers), "Approver");
            let Authorisers = PWroles(bindRoleData(AdditionalAuthorisers), "Authoriser");
            let Verifiers = PWroles(bindRoleData(AdditionalVerifiers), "Verifier");
            let DigitalPracticeLeaders = PWroles(bindRoleData(AdditionalDigitalPracticeLeader), "InformationController");
            let PWInternalViewer = PWroles(bindRoleData(AddedPWInternalViewer), "Viewer");
            let Member = PWroles(bindRoleData(AdditionalProjectMember), "Member");
        PWAdditionalRole =  [...pms, ...ics, ...psgs,...projectAccountants,...Reviewers,...Approvers,...Authorisers,...Verifiers,...DigitalPracticeLeaders,...PWInternalViewer,...Member];


            //onCreateProjectWise(removedExternalMember, PWAdditionalRole, RemovedRole, pwExternalRole, selectedInternalViewer);
            
        }
    
    }

    const PWroles =(roleArray: any, roleName: string )=>{
        const role: IProjectWiseTriggerRoles[]=[];
        roleArray.forEach((defRole: IProjectPerson) => {
         var users: IProjectWiseTriggerRoles = {
                userName: defRole.name,
                roleName: roleName,
                resourceValue: pageState.projectDetails.projectId,
                assignedTime: new Date().toDateString(),
            }
            role.push(users);
        })
        return role;
    }

    const bindPostData = (selectedUsers: any) => {
        const toSaveUsers: IProjectPerson[] = [];

        selectedUsers.forEach((selectedUser: IPeoplePickerData) => {
            var toSaveUser: IProjectPerson = {
                email: selectedUser.userPrincipalName,
                name: selectedUser.displayName,
                isWorkday: selectedUser.isWorkdayData ? selectedUser.isWorkdayData : false
            } 
            toSaveUsers.push(toSaveUser);            
        })

        return toSaveUsers;
    }
    
    const bindRoleData = (selectedUsers: any) => {
        const toSaveUsers: IProjectPerson[] = [];
      
        selectedUsers.forEach((selectedUser: IPeoplePickerData) => {
                var toSaveUser: IProjectPerson = {
                    email: selectedUser.userPrincipalName,
                    name: selectedUser.displayName,
                    isWorkday: selectedUser.isWorkdayData ? selectedUser.isWorkdayData : false
                }
                toSaveUsers.push(toSaveUser);
        })
        return toSaveUsers;
    }

    const setDefaultExternalRolesPeople = (roleType: string) => {
        const returnRoles: IPeoplePickerData[] = [];

        defaultRoles.forEach((roles: any) => {
            var external = roles.ExternalRolesModels;
            if (Array.isArray(external)) { 
                external.forEach((role: any) => {
                    if(role.role === roleType){
                        var defaultRole: IPeoplePickerData = {
                            userPrincipalName: role.email,
                            displayName: role.name
                        }
                        returnRoles.push(defaultRole);
                    }
                });
            }
        });
        return returnRoles;
    };

    const setDefaultExternalOrganization =(isName: boolean) =>{
    let organization: string ="";

    if(isName){

        defaultRoles.forEach((roles: any) => {
            var external = roles.ExternalRolesModels;
            if (Array.isArray(external)) { 
                external.forEach((role: any) => {
                    if(role.role === "InternalViewer"){
                        organization = role.organization;
                    }
                    if(role.role === "OneSpaceExternalRole"){
                        organization = role.organization;
                    }
                });
            }
        });

    }else{

        defaultRoles.forEach((roles: any) => {
            var external = roles.ExternalRolesModels;
            if (Array.isArray(external)) { 
                external.forEach((role: any) => {
                    if(role.role === "InternalViewer"){
                        organization = role.organizationCode;
                    }
                    if(role.role === "OneSpaceExternalRole"){
                        organization = role.organizationCode;
                    }
                });
            }
        });

    }

    return organization;


    };

    const setDefaultRow =()=>{
    const externalTable: IExternalRoles[] = []
    defaultRoles.forEach((roles: any) => {
        var external = roles.ExternalRolesModels;
        if (Array.isArray(external)) { 
            external.forEach((role: any) => {
                if(role.role !== "InternalViewer" && role.role !== "SupplierInformalExchange" && role.role !== "SupplierCollaborator"){
                    externalTable.push({
                        Name: role.name,
                        Email: role.email,
                        Role: role.role,
                        Organization: role.organization,
                        OrganizationCode: role.organizationCode,
                        IsInformationController: role.isInformationController
                    })
                }
            });
        }
    });

    return externalTable;

    }

    const set12dProjectWiseEnabled =()=>{
    var isEnabled: boolean = false;
    var ProjectWiseprovisioningStatus;
    var Bim360provisioningStatus;
    var CEenabled: boolean =false;
    var SynergyprovisioningStatus;
        
    if(props.defaultContentEngineSelection[0]) {
        let def = props.defaultContentEngineSelection[0];            
        CEenabled = def.isProjectWiseEnabled || def.is12dEnabled;
        SynergyprovisioningStatus = props.projectContentEngineData?.synergy12dProvisioningStatus;
        ProjectWiseprovisioningStatus = props.projectContentEngineData?.projectWiseProvisioningStatus;
        Bim360provisioningStatus = props.projectContentEngineData?.biM360ProvisioningStatus;


        if(def.isProjectWiseEnabled && ProjectWiseprovisioningStatus == 2){
            setisProjectWiseEnabled(true)
        }
        if(def.is12dEnabled && SynergyprovisioningStatus == 2){
            setis12dEnabled(true)
        }
        if(def.isBim360Enabled && Bim360provisioningStatus == 2){
            setisBIM360Enabled(true)
        }

        if(CEenabled) {
            if(props.projectContentEngineData) {
                if(SynergyprovisioningStatus == 2 || ProjectWiseprovisioningStatus == 2) {
                    isEnabled= true
                }
            }
        }
    }
    return isEnabled;
    }

    const setOneSpaceEnabled=()=>{
    var isEnabled: boolean = false;
    var OneSpaceProvisioningStatus;
    var CEenabled: boolean =false;
    if(props.defaultContentEngineSelection[0]) {
        let def = props.defaultContentEngineSelection[0];            
        CEenabled = def.isOneSpaceEnabled;
        OneSpaceProvisioningStatus = props.projectContentEngineData?.oneSpaceProvisioningStatus;
        if(CEenabled) {
            if(props.projectContentEngineData) {
                if(OneSpaceProvisioningStatus == 2) {
                    isEnabled= true
                }
            }
        }
    }
    return isEnabled;
    }

    const setDefaultExternalRolesText = (roleType: string) => {
    const returnRoles: { email: string, role: string}[] = [];

    defaultRoles.forEach((roles: any) => {

        var external = roles.ExternalRolesModels;
        if (Array.isArray(external)) { 
            external.forEach((role: any) => {
                if(role.role === roleType || role.role === 'SupplierInformalExchange' ){
                    returnRoles.push({ email: role.email , role: roleType});
                }
            });
        }
    });
    return returnRoles;

    };

    const onCreateProjectWise = (RemovedExternalRole: IExternalRoles[], AdditionalRole:IProjectWiseTriggerRoles[], RemovedRole:IProjectWiseTriggerRoles[], AdditionalExternalMember: IExternalRoles[], PWInternalViewer: any ) => {
    const clientData: IProjectWiseTriggerClientAndOrg = {
        name: pageState.projectDetails.client,
        code: pageState.projectDetails.customerId ? pageState.projectDetails.customerId : ''
    }

    const owningOrgData: IProjectWiseTriggerClientAndOrg = {
        name: pageState.projectDetails.costCenter ? pageState.projectDetails.costCenter : '',
        code: pageState.projectDetails.costCenterCode ? pageState.projectDetails.costCenterCode : ''
    }

    const postData: IProjectWiseTriggerModel = {
        projectNumber : pageState.projectDetails.projectId,
        projectName: pageState.projectDetails.projectName,
        client: clientData,
        owningOrg: owningOrgData,
        aureconRegion: pageState.projectDetails.region,
        workLocation: pageState.projectDetails.location ? pageState.projectDetails.location : '',
        createdBy : currentUserEmailAddress,
        projectManager: pageState.projectDetails.projectManagerName,
        templateName: onGetProjectWiseTemplateName(),
        isConfidential: isConfidential,
        transmittalsRequired: props.defaultContentEngineSelection[0].projectWiseSelection?.IsTransmittalsRequired ? props.defaultContentEngineSelection[0].projectWiseSelection?.IsTransmittalsRequired : false,
        roles: AdditionalRole,
        isUpdating: true,
        ExternalRoles: AdditionalExternalMember,
        isSameExternalAccess: false,
        RolesToBeRemoved: RemovedRole,
        ExternalRolesToBeRemoved: RemovedExternalRole
    }

    triggerProjectWiseCreation(instance, accounts, postData)
        .then((results:any) => {
        console.log("Projectwise roles has been updated")
        resetArrayList(PWInternalViewer);
        })
    }
    const resetArrayList=(PWInternalViewer: any)=>{
    
    setRemovedProjectManagers([]);
    setAdditionalProjectManagers([]);
    setRemovedInformationControllers([]);
    setAdditionalInformationControllers([]);
    setRemovedProjectSupportGroup([]);
    setAdditionalProjectSupportGroup([]);
    setRemovedProjectAccountants([]);
    setAdditionalProjectAccountants([]);
    setRemovedReviewers([]);
    setAdditionalReviewers([]);
    setRemovedApprovers([]);
    setAdditionalApprovers([]);
    setRemovedAuthorisers([]);
    setAdditionalAuthorisers([]);
    setRemovedVerifiers([]);
    setAdditionalVerifiers([]);
    setRemovedDigitalPracticeLeader([]);
    setAdditionalDigitalPracticeLeader([]);
    setRemovedProjectMember([]);
    setAdditionalProjectMember([]);
    setAddedPWInternalViewer([]);
    setRemovedPWInternalViewer([]);
    setRemovedExternal([]);
    setpreviousPWInternalViewer(PWInternalViewer);
    setIsMandatoryRolesModified(false);
    setIsOneSpaceExternalRolesModified(false);
    setisPWExmodified(false);
    }

    const onGetProjectWiseTemplateName = () => {
    var templateName = '';
    if(props.defaultContentEngineSelection[0].isClientStandardDefined) {
        if(props.defaultContentEngineSelection[0].informationStandard == "Other") {
        templateName = "Other";
        }
        else {
        templateName = props.defaultContentEngineSelection[0].informationStandard;
        }
    }
    else {
        templateName = 'APIMS 2021';
    }
    return templateName;
    }

    const setDefaultRoles = (roleType: string) => {
        let distinctselectedPeople: IPeoplePickerData[] = [];
        const returnRoles: IPeoplePickerData[] = [];
        defaultRoles.forEach((roles: any) => {
            roles[roleType]?.forEach((role: IProjectPerson) => {
                var defaultRole: IPeoplePickerData = {
                    userPrincipalName: role.email.toLowerCase(),
                    displayName: role.name,
                    isWorkdayData: roles.IsWorkdayData
                }
                returnRoles.push(defaultRole);
            })
        });
        distinctselectedPeople = returnRoles.filter( (ele, ind) => ind === returnRoles.findIndex( person => person.userPrincipalName === ele.userPrincipalName && person.displayName === ele.displayName))
        
        return distinctselectedPeople;
    } 

    //Check current user role
    const CheckloggedUserRole = (people: any) =>{
        let isPMIC = false;
        const emailAddress = accounts[0] && accounts[0].username;

        if(people){
            people.forEach((element:any) =>{
                if(element.userPrincipalName.toLowerCase() === emailAddress.toLowerCase()){
                    isPMIC = true;
                }
            });
        }
        
        return isPMIC
    }    
    const addRow = () => {
        setRows([...rows,
            {
                Name: '',
                Email: '',
                Role: 'Viewer',
                Organization: '',
                OrganizationCode: '',
                IsInformationController: false
            },
        ]);
    };
    const handleRowChange = (event:  any, rowIndex: number, fieldName: keyof IExternalRoles) => {
        const updatedRows = [...rows];
        if (fieldName === 'Name' || fieldName === 'Email' || fieldName === 'Role') {
          updatedRows[rowIndex][fieldName] = event.target.value as string;
        } else if (fieldName === 'IsInformationController') {
          updatedRows[rowIndex][fieldName] = event.target.checked as boolean;
        } else if (fieldName === 'Organization') {
          const selectedOption = event.target.value;
          const organization = props.organisationList.find(option => option.companyCode === selectedOption);
          
          if (organization) {
            updatedRows[rowIndex]['OrganizationCode'] = organization.companyCode;
            updatedRows[rowIndex]['Organization'] = organization.companyName;
          }
        }

        if(!updatedRows[rowIndex]['Role']){
            updatedRows[rowIndex]['Role'] ='Viewer'
        }
        
        let distinctselectedPeople = [{
                Name: '',
                Email: '',
                Role: 'Viewer',
                Organization: '',
                OrganizationCode: '',
                IsInformationController: false
            },
        ];
        distinctselectedPeople = updatedRows.filter( (ele, ind) => ind === updatedRows.findIndex( person => person.Email === ele.Email && person.Role === ele.Role && person.Organization === ele.Organization))
      
        setRows(distinctselectedPeople);
    };

    const AddNonTableRole =  (selectedPeople: IPeoplePickerData[], role: string, Organization: string,  OrganizationCode: string, onespacerole?: boolean) => {
        const newRow: IExternalRoles[] = [];
    
        selectedPeople.forEach((person: IPeoplePickerData) => {
            newRow.push({
                Name: person.displayName,
                Email: person.userPrincipalName,
                Role: role,
                Organization: Organization,
                OrganizationCode: OrganizationCode,
                IsInformationController: false,
                IsOneSpaceRole: onespacerole

            });
         });

        return newRow;
    
    }

    const AddNonTableOSExternalRole =  (ExternalEmails: any, Organization: string,  OrganizationCode: string,  onespacerole?: boolean) => {
        const newRow: IExternalRoles[] = [];
    
        ExternalEmails.forEach((externalMember: { email: string, role: string }) => {
            newRow.push({
                Name: externalMember.email,
                Email: externalMember.email,
                Role: externalMember.role,
                Organization: Organization,
                OrganizationCode: OrganizationCode,
                IsInformationController: false,
                IsOneSpaceRole: onespacerole
            });
        });


        return newRow;
    }
    

    const deleteRow = (indexToDelete: number) => {
        let removedExternal: IExternalRoles[] = [];

        removedExternal= [...removedExternalMember, ...rows.filter((_, index) => index == indexToDelete)];

        setRemovedExternal(removedExternal);
        const updatedRows = rows.filter((_, index) => index !== indexToDelete);

        let distinctselectedPeople = [{
            Name: '',
            Email: '',
            Role: 'Viewer',
            Organization: '',
            OrganizationCode: '',
            IsInformationController: false
        },
    ];
    distinctselectedPeople = updatedRows.filter( (ele, ind) => ind === updatedRows.findIndex( person => person.Email === ele.Email && person.Role === ele.Role && person.Organization === ele.Organization))
  

        setRows(distinctselectedPeople);

    };
    
    const saveRowToYourArray = (rowIndex: number) => {
        const rowToSave = rows[rowIndex];
    };

    const deleteExternalMember = (indexToDelete: number) => {
        const removedmember = externalMember.filter((_, index) => index === indexToDelete);
        let removedExternal: IExternalRoles[] = [];
        removedmember.forEach((member) =>{
            const external= {
                Name: member.email,
                Email: member.email,
                Role:  member.role,
                Organization: "",
                OrganizationCode: "",
                IsInformationController: false,
                IsOneSpaceRole: true
            }
            removedExternal.push(external);      
        });
        setRemovedOnespaceExternalMember(removedExternal);
        setIsOneSpaceExternalRolesModified(true);
        const updatedMembers = externalMember.filter((_, index) => index !== indexToDelete);
        setExternalMember(updatedMembers);

    };

    return (

        <div className={style.projectTabRoles}>
            <div className={style.lineWrapper}>
                <div className={style.leftCommandButtons}>
                    <h1>Current Project Team</h1>
                </div>
                { (CheckloggedUserRole(selectedProjectManagers) || CheckloggedUserRole(selectedInformationControllers))  && 
                    <div className={style.rightCommandButtons}>
                        <Button
                            title='View Mode'
                            type="icon-square"
                            size="medium"
                            icon="visibility"
                            onClick={onClickDisplayMode}
                            cssClass={`${editMode === false ? "buttonModeActive" : "buttonModeDeactive"}`}
                        />
                        &nbsp;
                        <Button
                            title='Edit Mode'
                            type="icon-square"
                            size="medium"
                            icon="edit"
                            onClick={onClickEditMode}
                            cssClass={`${editMode === true ? "buttonModeActive" : "buttonModeDeactive"}`}
                        />
                    </div>                             
                }
            </div>

            <p>
                <b>Mandatory Roles *</b>
            </p>

            <div className={style.lineWrapper}>
                <Tooltip text="Project Manager (PM) is responsible for the production and delivery of information in accordance with the DWP and related client requirements. The PM must also coordinate onboarding, ensuring required outcomes are clear to the entire project team.">
                    <div className={style.left}>
                        Project Management*
                    </div>
                </Tooltip>
                <div className={style.right}>
                    <div className={style.peoplePickerContainer}>
                            <PeoplePicker id='projectManager'
                                className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                                selectedPeople={selectedProjectManagers}
                                selectionChanged={onSelectionChanged_ProjectManager}
                                disabled={!editMode}
                            />
                        {/* <div className="peoplePickerDescription">Project Manager (PM) is responsible for the production and delivery of information in accordance with the DWP and related client requirements. The PM must also coordinate onboarding, ensuring required outcomes are clear to the entire project team.</div> */}
                    </div>
                    {ProjectManagererrorMessage && <p style={{ color: 'red' }}>{ProjectManagererrorMessage}</p>}
                </div>
                
            </div>

            <div className={style.lineWrapper}>
                <Tooltip text="Information Controller contributes to the DWP and, when required prepare documention to define the information exchange process. They also provide training and guidance around IM control and exchange protocols and monitor, support and control information in line with the DWP.">
                    <div className={style.left}>
                        Information Controllers*
                    </div>
                </Tooltip>
                <div className={style.right}>
                        <PeoplePicker id='projectControls'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedInformationControllers}
                            selectionChanged={onSelectionChanged_InformationControllers}
                            disabled={!editMode} 
                        />
                        {InformationControllererrorMessage && <p style={{ color: 'red' }}>{InformationControllererrorMessage}</p>}
                </div>
            </div>

            <div className={style.lineWrapper}> 
                <Tooltip text="Assigned to the Project Members group, a mandatory role from Workday.">
                    <div className={style.left}>
                        Project Support*
                    </div>
                </Tooltip>
                <div className={style.right}>
                        <PeoplePicker id='projectSupport'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedProjectSupportGroup}
                            selectionChanged={onSelectionChanged_ProjectSupportGroup}
                            disabled={!editMode} />
                    {ProjectSupportGroupErrorMessage && <p style={{ color: 'red' }}>{ProjectSupportGroupErrorMessage}</p>}
                </div>
            </div>

            <div className={style.lineWrapper}>
                <Tooltip text="Assigned to the Project Members group, a mandatory role from Workday.">
                    <div className={style.left}>
                        Project Accountant*
                    </div>
                </Tooltip>
                <div className={style.right}>
                    
                        <PeoplePicker id='projectAccountant'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedProjectAccountants}
                            selectionChanged={onSelectionChanged_ProjectAccountant}
                            disabled={!editMode} />
                   
                    {ProjectAccountantErrorMessage && <p style={{ color: 'red' }}>{ProjectAccountantErrorMessage}</p>}
                </div>
            </div>


            <p><b>Additional Roles</b></p>
            <div className={style.lineWrapper}>
                <Tooltip text="The Digital Practice Leader of your region can be added as a support user as required. This maps into the Information Controller role.">
                    <div className={style.left}>
                        Digital Practice Leader
                    </div>
                </Tooltip>
                <div className={`${style.right} ${style.peoplePickerContainer}`}>
                        <PeoplePicker id='projectDigitalPracticeLeader'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedDigitalPracticeLeader}
                            selectionChanged={onSelectionChanged_DigitalPracticeLeader}
                            disabled={!editMode} />
                    {DigitalPracticeLeaderErrorMessage && <p style={{ color: 'red' }}>{DigitalPracticeLeaderErrorMessage}</p>}
                </div>
            </div>
            <div className={style.lineWrapper}>
                <Tooltip text="The Authoriser accepts responsibility of the content and quality of the information being Authorised for Publish.">
                    <div className={style.left}>
                        Authorisers
                    </div>
                </Tooltip>
                <div className={style.right}>
                        <PeoplePicker id='projectAuthorisers'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedAuthorisers}
                            selectionChanged={onSelectionChanged_Authorisers}
                            disabled={!editMode} />
                    {AuthoriserErrorMessage && <p style={{ color: 'red' }}>{AuthoriserErrorMessage}</p>}
                </div>
            </div>
            <div className={style.lineWrapper}>
                <Tooltip text="The Approver accepts responsibility of the content and quality of the information being Approved for Share.">
                    <div className={style.left}>
                        Approvers
                    </div>
                </Tooltip>
                <div className={style.right}>
                        <PeoplePicker id='projectApprovers'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedApprovers}
                            selectionChanged={onSelectionChanged_Approvers}
                            disabled={!editMode} />
                    {ApproverErrorMessage && <p style={{ color: 'red' }}>{ApproverErrorMessage}</p>}
                </div>
            </div>
            <div className={style.lineWrapper}>
                <Tooltip text="The Verifier is responsible for independently reviewing the output to confirm information provided meets requirements. This is assigned in the One Space document workflows. 
                                Note: This is not an applicable role for ProjectWise or 12d Synergy.">
                    <div className={style.left}>
                        Verifiers
                    </div>
                </Tooltip>
                <div className={style.right}>
                        <PeoplePicker id='projectVerifiers'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedVerifiers}
                            selectionChanged={onSelectionChanged_Verifiers}
                            disabled={!editMode} />
                    {VerifierErrorMessage && <p style={{ color: 'red' }}>{VerifierErrorMessage}</p>}
                </div>
            </div>
            <div className={style.lineWrapper}>
                <Tooltip text="The Reviewer is responsible for confirming that the information provided meets the specified requirements, agreed standards, methods and procedures for the production of information. Note: This is not an applicable role for ProjectWise or 12d Synergy.">
                    <div className={style.left}>
                        Reviewers
                    </div>
                </Tooltip>
                <div className={style.right}>
                        <PeoplePicker id='projectReviewers'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedReviewers}
                            selectionChanged={onSelectionChanged_Reviewers}
                            disabled={!editMode} />
                    {ReviewerErrorMessage && <p style={{ color: 'red' }}>{ReviewerErrorMessage}</p>}
                </div>
            </div>            
            <div className={style.lineWrapper}>
                <Tooltip text="Project Members are responsible for the production of information following the methods and procedures defined in the DWP.">
                <div className={style.left}>
                    Project Member/User
                </div>
                </Tooltip>
                <div className={style.right}>
                    
                        <PeoplePicker id='projectMembers'
                            className={ editMode ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                            selectedPeople={selectedProjectMember}
                            selectionChanged={onSelectionChanged_ProjectMember}
                            disabled={!editMode} />
                    
                    {ProjectMemberErrorMessage && <p style={{ color: 'red' }}>{ProjectMemberErrorMessage}</p>}
                </div>
            </div>
            {
                (isConfidential) ? 
                    (<div className={style.lineWrapper}>
                        <Tooltip text="Gives Read-Only access to the project.">
                            <div className={style.left}>
                                Internal Viewer
                                <br/>
                                <a className={style.descriptionText}>(if project is confidential)</a>
                                <br/>
                                <a className={style.descriptionText}>read only access</a>
                            </div>
                        </Tooltip>
                        <div className={style.right}>
                                <PeoplePicker id='onespaceInternalRole'
                                    className={ editMode  ? 'editModeMgtPeoplePicker' : 'displayModeMgtPeoplePicker' }
                                    selectedPeople={selectedInternalViewer}
                                    selectionChanged={onSelectionChanged_OnespaceInternalRole}
                                    disabled={!(editMode && isConfidential)} />
                            {InternalViewerErrorMessage && <p style={{ color: 'red' }}>{InternalViewerErrorMessage}</p>}
                        </div>
                    </div>
                    ) 
                :(<div></div>)
            }
            
            
            {
                (isExternalProcessRequired) ?
                (<>
                    <div>
                        <div className={style.txtnote}>
                            <p>
                                Note: To add content engine specific roles content engine mobilisation is required. <br/>
                                Mobilising your CDE's from the content engine tab will enable this feature.
                            </p>
                        </div>
                    </div>
                    <h3><u>One Space Specific Roles</u></h3>
                
                    <div >
                        <div className={style.lineWrapper}>
                            <div className={style.container}>
                                <div className={style.addButtonContainer}>
                                    <button type="button" onClick={addExternalMember} 
                            disabled={!(editMode && isOneSpaceEnabled)} >Add External Member</button>
                                </div>
                            </div>
                        </div>
                        <table>
                            {externalMember.map((member, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className={style.lineWrapper}>
                                            <div className={style.left}>{index == 0? "External Member": ""}</div>
                                            <div className={style.right}>
                                                
                                                <div className={style.deleteButtonContainer}>

                                                <input
                                                    className={`${style.InputtextwithActionButton}`}
                                                    disabled={editMode ? false : true}
                                                    value={member.email}
                                                    onChange={(e) => handleRowChange_OnespaceExternalRole(e, index)}
                                                    onBlur={(e) => handleRowChange_OnespaceExternalRole(e, index)}
                                                />
                                                
                                                <button
                                                    type="button"
                                                    onClick={() => deleteExternalMember(index)}
                                                    className={editMode ? style.deletebutton : style.disableddeletebutton}
                                                    disabled={!editMode}>
                                                    ✖
                                                </button>
                                                </div>
                                                <div className={style.inputRoleContainer}>
                                                    <label htmlFor={`roleSelect_${index}`} className={style.label}>External Role: </label>
                                                    <select
                                                        id={`roleSelect_${index}`}
                                                        className={`${style.SelectionwithActionButton}`}
                                                        disabled={!editMode}
                                                        value={member.role} 
                                                        onChange={(e) => handleRoleChange_OnespaceExternalRole(e, index)}>
                                                        <option value="SupplierCollaborator">Supplier - Collaborator</option>
                                                        <option value="SupplierInformalExchange">Supplier - Informal Exchange</option>
                                                    </select>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>

                    </div>

                    <h3 className={style.projectwiseHeader}><u>ProjectWise and 12D Specific Roles</u></h3>
                            
                    <div className={style.lineWrapper}>
                        <div className={style.externalMembersDivText}>
                            <div className={style.externalMembersDiv}>
                                External Members and Role Assignment
                            </div>
                        </div>
                    </div>
                    <div className={style.divider}></div>
                
                <div className={style.lineWrapper}>
                    <div className={style.container}>
                        <div className={style.addButtonContainer}>
                            <button type="button" onClick={addRow} 
                            disabled={!(editMode && is12dProjectWiseEnabled && isExternalProcessRequired)}>Add External User Details</button>

                        </div>
                        {rows.length >  0?
                    (
                        <div className={style.tableContainer}>
                        
                        <table className={style.customTable}>
                            <thead className={style.customTableHeader}>
                            <tr>
                                <th>External User Details</th>
                                <th>Project Role</th>
                                <th>Information Controller</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                <td>
                                    
                                    <div className={style.lineWrapperTable}>
                                        <div className={style.left}>
                                                Name
                                        </div>
                                        <div className={style.right}>
                                            <input id="Name" 
                                                name="Name" 
                                                type="text" 
                                                placeholder="Name"  
                                                className={style.Inputtext}
                                                disabled={!(editMode && is12dProjectWiseEnabled && isExternalProcessRequired)}
                                                value={row.Name}
                                                onChange={(event) => handleRowChange(event, index, 'Name')}
                                                onBlur={() => saveRowToYourArray(index)}
                                            />
                                        </div>
                                    </div>
                                    {
                                        isSameExternalAccess ? (<></>): (
                                            <div className={style.lineWrapperTable}>
                                                <div className={style.left}>
                                                    <label htmlFor="Organization">Organization</label>
                                                </div>
                                                <div className={style.right}>
                                                <select
                                                    id={`Organization-${index}`}
                                                    name={`Organization-${index}`}
                                                    value={row.OrganizationCode}
                                                    onChange={(event) => handleRowChange(event, index, 'Organization')}
                                                    className={style.Selectiontext}
                                                    disabled={!(editMode && is12dProjectWiseEnabled && isExternalProcessRequired) }
                                                    aria-label="Select an organization" 
                                                    >
                                                    <option value="">Select an organization</option>
                                                    {OrganisationList?.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option.companyCode}>
                                                        {option.companyCode} - {option.companyName}
                                                        </option>
                                                    ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className={style.lineWrapperTable}>
                                        <div className={style.left}>
                                        Email
                                        </div>
                                        <div className={style.right}>
                                            <input id="Email" 
                                                name="Email" 
                                                type="text" 
                                                placeholder="Email" 
                                                className={style.Inputtext}
                                                disabled={!(editMode && is12dProjectWiseEnabled && isExternalProcessRequired)}
                                                value={row.Email}
                                                onChange={(event) => handleRowChange(event, index, 'Email')}
                                                onBlur={() => saveRowToYourArray(index)}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <label htmlFor="projectRole">Project Role:</label>
                                    <select id="projectRole" 
                                            name="projectRole" 
                                            className={style.customSelect} 
                                            disabled={!(editMode && is12dProjectWiseEnabled && isExternalProcessRequired)}
                                            value={row.Role || "Viewer"} 
                                            onChange={(event) => handleRowChange(event, index,'Role')}
                                            >
                                        <option value="Viewer">Viewer</option>
                                        <option value="Member">Member</option>
                                        <option value="Authorizer">Authorizer</option>
                                        <option value="Approver">Approver</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="checkbox" 
                                            placeholder="Information Controller" 
                                            className={style.customCheckbox}
                                            disabled={!(editMode && is12dProjectWiseEnabled && isExternalProcessRequired)} 
                                            checked={row.IsInformationController}
                                            onChange={(event) => handleRowChange(event, index,'IsInformationController')}
                                        />
                                </td>
                                <td>
                                    <button 
                                        type="button"
                                        onClick={() => deleteRow(index)}
                                        className={!(editMode && is12dProjectWiseEnabled) ? style.disableddeletebutton : style.deletebutton} 
                                        disabled={!(editMode && is12dProjectWiseEnabled && isExternalProcessRequired)}>
                                    ✖
                                    </button>
                                </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        </div>
                        
                    ): (<></>)}
                    </div>

                    </div>
                        </>): 
                    (<></>)

            }

            {editMode && (
                <div>
                    {isLoading ? (
                        <div className={style.modal_overlay}>
                        <div className={style.loader}></div>
                      </div>
                    ) : (
                    <div className={style.lineWrapper}>
                        <div className={style.rightButtons}>
                        <Button
                            type="secondary"
                            size="medium"
                            label='Cancel'
                            width='100px'
                            onClick={onClickCancel}
                            cssClass={"buttonModeDeactive"}
                        />
                        <Button
                            type="primary"
                            size="medium"
                            label='Save'
                            width='100px'
                            onClick={onSaveRoles}
                            cssClass={"buttonModeActive"}
                        />
                        </div>
                    </div>
                    )}
                </div>
                )}
        </div>
        );
};

export default Tab2Roles;

import './styles/App.scss';
import Login from './pages/Login';
import ProjectsContainer from './pages/ProjectsContainer';
import Spatial from './pages/Spatial';
import HelpAndSupport from './pages/HelpAndSupport';
import ProjectDetailsPage from './pages/ProjectDetailsPage';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import '@aurecon-creative-technologies/styleguide/lib/css/aurecon-design-system.min.css';


const App = () =>  {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/Login" />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Projects" element={<ProjectsContainer />} />
          <Route path="/Project/:ProjectId" element={<ProjectDetailsPage />} />
          <Route path="/Spatial" element={<Spatial />} />
          <Route path="/Help" element={<HelpAndSupport />} />
          <Route path="/Terms" element={<TermsAndConditions />} />
          <Route path="/Privacy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

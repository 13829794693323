import { OverflowMenu } from "@aurecon-creative-technologies/styleguide";
import { FC } from "react";
import { GeometryType } from "../../../../constants/ErsiMapConst";
import { IDictionary } from "../../../../models/shared/IDictonary";
import Style from "../../../../styles/components/shared/map/widgets/DrawerWidgets.module.scss";
import { cancelDrawFeature, drawFeature } from "../WebMapDrawAction";

interface IDrawerWidgetsProps {
  attributes?: IDictionary;
}
const DrawerWidgets: FC<IDrawerWidgetsProps> = (props) => {
  const { attributes } = props;
  const drawerItems = [
    {
      id: GeometryType.Point,
      label: "Draw Point",
      icon: "place",
    },
    {
      id: GeometryType.Polyline,
      label: "Draw Line",
      icon: "timeline",
    },
    {
      id: GeometryType.Polygon,
      label: "Draw Area",
      icon: "rectangle",
    },
    {
      id: GeometryType.None,
      label: "Cancel Draw",
      icon: "cancel",
    },
  ];

  const onSelectedItem = (item: string) => {
    if (item === GeometryType.None) {
      cancelDrawFeature();
      return;
    }

    drawFeature(item, attributes);
  };

  return (
    <div className={Style.mapDrawerWidgetContainer}>
      <OverflowMenu
        cssClass={Style.contextMenu}
        items={drawerItems}
        onSelectItem={onSelectedItem}
      />
    </div>
  );
};

export default DrawerWidgets;

import { ReactComponent as AureconLogo } from '../assets/aurecon_logo_white.svg';
import { ReactComponent as BuilderIcon } from '../assets/builder_logo.svg';
import { Button } from '@aurecon-creative-technologies/styleguide';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';

import style from '../styles/pages/Login.module.scss';

const Login: React.FunctionComponent = () => {
    const { instance } = useMsal();

    if(useIsAuthenticated()) window.location.href = "/Projects";
    return (
        <div className={style.builderLoginPageMainContainer}>
            <div className={style.builderLoginPageGradient}></div>
            <div className={style.builderLoginPage}>
                <div className={style.builderLoginPageHeader}>
                    <AureconLogo height={30} />
                </div>
                <div className={style.builderLoginPageMain}>
                    <h2>
                        Welcome to
                    </h2>
                    <div className={style.builderLoginPageMainLogo}>
                        <BuilderIcon height={90} width={90}/>
                        <h1>Builder</h1>
                    </div>

                    <p>
                        Builder is a Digital Workspace tool that centralises project planning and content engine mobilisation by collecting available information from Workday,
                        and capturing project planning information to guide you through setting up your project environment.
                    </p>
                </div>
                <div className={style.builderLoginPageButton}>
                    <Button type="secondary" size="medium" label="Login" onClick={() => handleLogin(instance)}/>
                </div>
                <div className={style.builderLoginPageFooter}>
                    <span className={style.footerLink}><a href="/Terms" target="_blank">Terms & Conditions</a></span>
                    <span className={style.footerSeparator}>|</span>   
                    <span className={style.footerLink}><a href="/Privacy" target="_blank">Privacy Policy</a></span>   
                    <span className={style.footerSeparator}>|</span>    
                    <span>2022 DWB version 1.0.0</span>
                </div>
            </div>
        </div>
    );
}

function handleLogin(msalInstance: IPublicClientApplication) {
    msalInstance.loginRedirect()
        .catch((e: any) => {
            msalInstance.loginPopup()
                .catch((err: any) => {
                    console.log('Custom Error Page if ever');
                })
        });
}

export default Login;

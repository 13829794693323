export const AddressType = {
  POI: "POI",
  Locality: "Locality",
};

export const GeometryType = {
  Point: "point",
  Polygon: "polygon",
  Polyline: "polyline",
  None: "none",
};

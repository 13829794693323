import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Container } from '@aurecon-creative-technologies/styleguide';

import Header from '../components/shared/Header';
import Footer from '../components/shared/Footer';
import style from '../styles/pages/ProjectDetails.module.scss';
import ProjectDetail from '../components/project/ProjectDetail';
import { useIsAuthenticated } from '@azure/msal-react';

const ProjectDetailsPage: React.FunctionComponent = () => {
    if (!useIsAuthenticated()) window.location.href = "/";

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className={style.projectsMainContainer}>
            <Header />
            <div className={style.goBack} onClick={() => navigate(-1)}>&lt;&nbsp;&nbsp;Go back</div>
            <Container fluid cssClass={style.projectDetailsPageContainer} key='projects-container-1'>
                <ProjectDetail pageState={ location.state } />
            </Container>
            <Footer />
        </div>
    )
};

export default ProjectDetailsPage;

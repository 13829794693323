import React, { useState, ReactNode } from 'react';
import '../styles/components/shared/tooltip.scss';

interface TooltipProps {
    text: string;
    children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => {
        setVisible(true);
    };

    const hideTooltip = () => {
        setVisible(false);
    };

    return (
        <div className="tooltip-container"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}>
            {children}
            {visible && <div className="tooltip-text">{text}</div>}
        </div>
    );
};

export default Tooltip;
import Card from './../shared/Card';
import { IProjectModel } from '../../models/project/IProjectModel';
import { ReactComponent as TeamsIcon } from '../../assets/teams_logo.svg';
import { ReactComponent as OneSpaceIcon } from '../../assets/one_space_logo.svg';
import { ReactComponent as ProjectSpaceIcon } from '../../assets/project_space_logo.svg';
import { ReactComponent as ProjectWiseIcon } from '../../assets/projectwise_logo.svg';
import { ReactComponent as BIM360Icon } from '../../assets/bim_360_logo.svg';
import { ReactComponent as TwelveDSynergyIcon } from '../../assets/12d_synergy_logo.svg';

import style from '../../styles/components/project/ProjectCard.module.scss';
import { generatePath, useNavigate } from 'react-router-dom';

export interface IProjectCardProps {
  projectData: IProjectModel;
  onClick?: () => void;
}

const ProjectCard: React.FunctionComponent<IProjectCardProps> = (props) => {
  const data = props.projectData;
  const navigate = useNavigate();

  const handleOnClick = (projData: any) => {
    const projectUrl = generatePath("/Project/:id", { id: projData.projectId });
    navigate(projectUrl, { state: { projectDetails: projData } });
  };

  return (
    <div className={style.projectCardContainer}>
      <Card
        header={
          <div>
            {data.masterProjectHierarchyID && <div className={style.header}>{data.masterProjectHierarchyID}</div>}
            <span className={style.header}>{data.projectId}</span>
          </div>
        }
        // header={<span className={style.header}>{data.projectId} {data.masterProjectHierarchyID ? data.masterProjectHierarchyID : ''}</span>}
        title={
            <div className={style.title} onClick={() => handleOnClick(data)}>{data.projectName}</div>
        }
      >
        <div className={style.contentWrapper}>
          <div className={style.lineWrapper}>
            <div className={style.left}>Client</div>
            <div className={style.right}>{data.client}</div>
          </div>
          <div className={style.lineWrapper}>
            <div className={style.left}>Opportunity ID</div>
            <div className={style.right}>{data.opportunityId}</div>
          </div>
          <div className={style.lineWrapper}>
            <div className={style.left}>Project Manager</div>
            <div className={style.right}>{data.projectManagerName}</div>
          </div>
          <div className={style.lineWrapper}>
            <div className={style.left}>Business Sponsor</div>
            <div className={style.right}>{data.businessSponsor}</div>
          </div>
        </div>
        <div className={style.contentEnginesWrapper}>
          <div className={(data.projectContentEngineData && data.projectContentEngineData.oneSpaceProvisioningStatus) == 2 ? 
            style.contentEngineContainer : style.contentEngineContainerDisabled}>
              <TeamsIcon width={40} height={40} />
          </div>
          <div className={(data.projectContentEngineData && data.projectContentEngineData.oneSpaceProvisioningStatus) == 2 ? 
            style.contentEngineContainer : style.contentEngineContainerDisabled}>
              <OneSpaceIcon width={28} height={40} />
          </div>
          <div className={(data.projectContentEngineData && data.projectContentEngineData.projectWiseProvisioningStatus) == 2 ? 
            style.contentEngineContainer : style.contentEngineContainerDisabled}>
              <ProjectWiseIcon width={40} height={40} />
          </div>
          <div className={(data.projectContentEngineData && data.projectContentEngineData.biM360ProvisioningStatus) == 2 ? 
            style.contentEngineContainer : style.contentEngineContainerDisabled}>
              <BIM360Icon width={40} height={40} />
          </div>
          <div className={(data.projectContentEngineData && data.projectContentEngineData.synergy12dProvisioningStatus) == 2 ? 
            style.contentEngineContainer : style.contentEngineContainerDisabled}>
              <TwelveDSynergyIcon width={40} height={40} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ProjectCard;

import style from '../../styles/pages/ProjectDetails.module.scss';
import React from 'react';
import ProjectTabs from './ProjectTabs';
import { ReactComponent as OneSpaceIcon } from '../../assets/one_space_logo.svg';
import { ReactComponent as WorkDayIcon } from '../../assets/workday_logo.svg';
import { IProjectDetails } from '../../models/project/IProjectDetails';
import { IProjectDetailProps } from './IProjectDetailProps';

const ProjectDetail: React.FunctionComponent<IProjectDetailProps> = (props) => {

  const pageState: IProjectDetails = props.pageState;

  return (
    <React.Fragment>
      {pageState && pageState.projectDetails &&
        <React.Fragment>
          <div className={style.projectDetailsPageContainerHeader}>
            <h2>{pageState.projectDetails.projectId} - {pageState.projectDetails.projectName}</h2>
            {pageState.projectDetails.workDayLink &&
              <a href={pageState.projectDetails.workDayLink} target='_blank' rel="noreferrer">
                <WorkDayIcon width={36} height={36} title="WorkDay" />
              </a>
            }
          </div>
          <div className={style.lineWrapper}>
            <div className={style.left}>Opportunity Reference</div>
            <div className={style.right}>{pageState.projectDetails.opportunityId}</div>
            <div className={style.right}>
              {pageState.projectDetails.oneSpaceLink &&
                <a href={pageState.projectDetails.oneSpaceLink} target='_blank' rel="noreferrer">
                  <OneSpaceIcon width={20} height={20} title="OneSpace" />
                </a>
              }
            </div>
          </div>

          {pageState.projectDetails.masterProjectHierarchyID && 
            <div className={style.lineWrapper}>
              <div className={style.left}>Master Project</div>
              <div className={style.right}>{pageState.projectDetails.masterProjectHierarchyID}</div>
            </div>
          }
          
          <div className={style.lineWrapper}>
            <div className={style.left}>Client</div>
            <div className={style.right}>{pageState.projectDetails.client}</div>
          </div>

         <div className={style.lineWrapper}>
            <div className={style.left}>Project Duration</div>
            <div className={style.right}>{pageState.projectDetails.projectStartDate} - {pageState.projectDetails.projectEndDate}</div>
          </div>

         <div className={style.lineWrapper}>
            <div className={style.left}>Project Manager</div>
            <div className={style.right}>{pageState.projectDetails.projectManagerName}</div>
          </div>

          <div className={style.lineWrapper}>
            <div className={style.left}>Project Classification</div>
            <div className={style.right}>{pageState.projectDetails.classification}</div>
          </div>

          <div className={style.projectTabs}>
            <ProjectTabs pageState={pageState} />
          </div>

        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ProjectDetail;
import { Extent, Point } from "@arcgis/core/geometry";
import MapView from "@arcgis/core/views/MapView";
import { GeometryType } from "../../../constants/ErsiMapConst";
import { IGeometryExtent } from "../../../models/map/IGeocodingAddressCandidateResponse";

let mapView: MapView | null;

export const cacheMapView = (view: MapView | null): void => {
  mapView = view;
};

export const getMapView = (): MapView | null => {
  if (!mapView) return null;
  return mapView;
};

export const zoomTo = (
  geometryType: string,
  extent: IGeometryExtent | null,
  location: Point | null
): void => {
  if (!mapView) return;

  if (geometryType === GeometryType.Point) {
    mapView.goTo({
      target: location,
      scale: 10000,
    });

    return;
  }

  mapView.goTo(
    new Extent({
      xmin: extent?.xmin,
      xmax: extent?.xmax,
      ymin: extent?.ymin,
      ymax: extent?.ymax,
    }).expand(1.5)
  );
};

import style from '../../../styles/components/project/ProjectTabs.module.scss';

import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { PeoplePicker } from '@microsoft/mgt-react';
import { Button, Accordion, AccordionPanel, Icon, Radio, IRadioItem, Checkbox, Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';

import { IProjectPlanningSubSectionModel } from '../../../models/project/IProjectPlanningSubSectionModel';
import { IProjectDetails } from '../../../models/project/IProjectDetails';
import { updateContentEngineSelection, updateProjectPlanningSubSection } from '../../../apis/project/Project';
import { IContentEngineSelectionModel } from '../../../models/project/IContentEngineSelectionModel';
import { IProjectWiseSelectionModel } from '../../../models/project/IProjectWiseSelectionModel';
import { BIM360Content, BIM360Service } from '../../../models/project/IBIM360SelectionModel';
import { IPeoplePickerData } from '../../../models/project/IPeoplePickerData';
import { IProjectPerson2 } from '../../../models/project/IProjectPerson';
import { IProjectMetadataModel } from '../../../models/project/IProjectMetadataModel';
import { IProjectContentEngine } from '../../../models/project/IProjectContentEngine';
// import { IProjectPlanningProps } from './IProjectPlanningProps';

interface IProjectPlanningProps {
    pageState: any;
    defaultPlanningSubSection: IProjectPlanningSubSectionModel[];
    defaultContentEngineLookup: IProjectMetadataModel[];
    defaultContentEngineSelection: IContentEngineSelectionModel[];
    projectContentEngineData?: IProjectContentEngine;
    handlePlanningSubSectionSave: () => void;
    handleLoadSavedContentEngineSelection: () => void;
}

const Tab1Planning: React.FunctionComponent<IProjectPlanningProps> = (props) => {

  const pageState: IProjectDetails = props.pageState;

  const { instance, accounts } = useMsal();

  const [activePanelId, setActivePanelId] = useState<string>();
  const YesNoOptions = [{ id: '1', label: 'Yes' }, { id: '2', label: 'No' }];
  const YesNoOptions_Disabled = [{id: '1', label: 'Yes', disabled: true}, {id: '2', label: 'No', disabled: true}];
  
  //Planning Sub Section
  const [planningPanelRadioSelection, setPlanningPanelRadioSelection] = useState<IRadioItem[]>(YesNoOptions);
  const [selectedPlanningExternalAccess, setSelectedPlanningExternalAccess] = useState<string>("");
  const [selectedPlanningProjectConfidential, setSelectedPlanningProjectConfidential] = useState<string>("");
  const [completedDigitalWorkplan, setCompletedDigitalWorkplan] = useState<boolean>();
  const [isProjectPlanningSubSectionSaved, setIsProjectPlanningSubSectionSaved] = useState<boolean>(false);
  const [isSameAccess, setisSameAccess] = useState<string>("");

  //Content Engine Selection
  // const contentEngineClientInfoMgtRadioSelection: IRadioItem[] = YesNoOptions; //need to add disable when Content Engine tab is tackled
  const [contentEngineClientInfoMgtRadioSelection, setContentEngineClientInfoMgtRadioSelection] = useState<IRadioItem[]>(YesNoOptions);
  const [selectedContentEngineClientInfoMgt, setSelectedContentEngineClientInfoMgt] = useState<string>("");
  const [selectedInformationStandard, setSelectedInformationStandard] = useState<number>(0);
  const [isContentEngineSelectionSaved, setIsContentEngineSelectionSaved] = useState<boolean>(false);
  const apims2021DropdownItems: IDropdownItemProps[] = [{id: 1, label: 'Aurecon Project Information Management Standard (APIMS 2021)'}];
  // const [showOtherInformationStandard, setShowOtherInformationStandard] = useState<boolean>(false);
  // const [selectedInformationStandardOther, setSelectedInformationStandardOther] = useState<string>("");

  //Project Environment under Content Engine Selection
  const [isOneSpaceSelected, setIsOneSpaceSelected] = useState<boolean>(true);
  const [isProjectWiseSelected, setIsProjectWiseSelected] = useState<boolean>(false);
  const [isBim360Selected, setIsBim360Selected] = useState<boolean>(false);
  const [is12dSynergySelected, setIs12dSynergySelected] = useState<boolean>(false);

  //ProjectWise 
  const [projectWiseTransmittalRadioSelection, setProjectWiseTransmittalRadioSelection] = useState<IRadioItem[]>(YesNoOptions); //need to add disable when Content Engine tab is tackled
  const [selectedProjectWiseTransmittalsRequired, setSelectedProjectWiseTransmittalsRequired] = useState<string>("");

  //BIM360
  const [selectedTimeZoneBIM360, setSelectedTimeZoneBIM360] = useState<number>(1);
  const [selectedModellingTeamBIM360, setSelectedModellingTeamBIM360] = useState<number>(0);
  const [selectedBIM360Admins, setSelectedBIM360Admins] = useState<any>([]);

  useEffect(() => {
    setProjectPlanningSubSection();
    setContentEngineSelection();
  }, [props.defaultPlanningSubSection, props.defaultContentEngineSelection]);

  const onChangePanel = (panelId: string) => {
    setActivePanelId(panelId);
  }

  const setProjectPlanningSubSection = () => {
    if(props.defaultPlanningSubSection[0]) {
      var disabledRadioSelection: IRadioItem[] = YesNoOptions_Disabled;
      setPlanningPanelRadioSelection(disabledRadioSelection);

      setSelectedPlanningExternalAccess(props.defaultPlanningSubSection[0].isExternalProcessRequired===true ? "1" : "2");
      setisSameAccess(props.defaultPlanningSubSection[0].isSameAccess===true ? "1" : (props.defaultPlanningSubSection[0].isSameAccess===false? "2": ""));
      setSelectedPlanningProjectConfidential(props.defaultPlanningSubSection[0].isProjectConfidential===true ? "1" : "2");
      setCompletedDigitalWorkplan(props.defaultPlanningSubSection[0].isDigitalWorkplanCompleted);
      setIsProjectPlanningSubSectionSaved(true);
    }
  }
  
  const setContentEngineSelection = () => {
    if(props.defaultContentEngineSelection[0]) {
      let def = props.defaultContentEngineSelection[0];

      var disabledRadioSelection: IRadioItem[] = YesNoOptions_Disabled;
      if(props.defaultContentEngineSelection[0].inheritedFromProjectId !== "") {
        if(props.defaultContentEngineSelection[0].isProjectWiseEnabled) {
          setContentEngineClientInfoMgtRadioSelection(disabledRadioSelection);
        }
      }

      setSelectedContentEngineClientInfoMgt(def.isClientStandardDefined===true ? "1" : "2");
      setSelectedInformationStandard(getIdFromDisplayText(def.informationStandard));
      setIsProjectWiseSelected(def.isProjectWiseEnabled);
      setIsOneSpaceSelected(def.isOneSpaceEnabled);
      setIsBim360Selected(def.isBim360Enabled);
      setIs12dSynergySelected(def.is12dEnabled);

      if(def.inheritedFromProjectId !== "") {
        if(def.isProjectWiseEnabled) {
          setSelectedProjectWiseTransmittalsRequired(def.projectWiseSelection?.IsTransmittalsRequired === true ? "1" : "2");
          setProjectWiseTransmittalRadioSelection(YesNoOptions_Disabled);
        }
        else {
          setProjectWiseTransmittalRadioSelection(YesNoOptions);
          setSelectedProjectWiseTransmittalsRequired("");
        }
      }
      else {
        if(def.isProjectWiseEnabled) {
          setSelectedProjectWiseTransmittalsRequired(def.projectWiseSelection?.IsTransmittalsRequired===true ? "1" : "2");
          
          if(props.projectContentEngineData && props.projectContentEngineData.projectWiseProvisioningStatus===2) {
            setProjectWiseTransmittalRadioSelection(YesNoOptions_Disabled);
          }
        }
        else {
          setProjectWiseTransmittalRadioSelection(YesNoOptions);
          setSelectedProjectWiseTransmittalsRequired("");
        }
      }

      if (def.isBim360Enabled && def.bim360Selection) {
        setSelectedTimeZoneBIM360(getIdFromDisplayText(def.bim360Selection.timeZone));
        setSelectedModellingTeamBIM360(getIdFromDisplayText(def.bim360Selection.modellingTeam));
        setSelectedBIM360Admins(setDefaultBim360Admins());
      }
      else {
        setSelectedTimeZoneBIM360(0);
        setSelectedModellingTeamBIM360(0);
        setSelectedBIM360Admins([]);
      }

      setIsContentEngineSelectionSaved(true);
    }
  } 

  const setDefaultBim360Admins = () => {
    const returnRoles: IPeoplePickerData[] = [];
    props.defaultContentEngineSelection[0].bim360Selection?.Services.forEach((service: BIM360Service) => {
        var defaultRole: IPeoplePickerData = {
            userPrincipalName: service.Administrator.Email,
            displayName: service.Administrator.Name
        }
        returnRoles.push(defaultRole);
    });
    return returnRoles;
  } 

  const resetContentEngineSelection = () => {
    props.handleLoadSavedContentEngineSelection();
  }

  const onSavePlanningSubSection = () => {
    const postData: IProjectPlanningSubSectionModel = {
      projectId: pageState.projectDetails.projectId,
      isExternalProcessRequired: selectedPlanningExternalAccess === "1" ? true : false,
      isProjectConfidential: selectedPlanningProjectConfidential === "1" ? true : false,
      isSameAccess: isSameAccess === "1" ? true : false,
      isDigitalWorkplanCompleted: completedDigitalWorkplan
    };

    updateProjectPlanningSubSection(instance, accounts, postData)
      .then((results: IProjectPlanningSubSectionModel) => {
        var disabledRadioSelection: IRadioItem[] = YesNoOptions_Disabled;
        setPlanningPanelRadioSelection(disabledRadioSelection);
        setIsProjectPlanningSubSectionSaved(true);
        setActivePanelId("");
        props.handlePlanningSubSectionSave();
      });
  }

  const onSaveContentEngineSelection = () => {
    const postData: IContentEngineSelectionModel = {
      projectId: pageState.projectDetails.projectId,
      isClientStandardDefined: selectedContentEngineClientInfoMgt === "1" ? true : false,
      informationStandard: getSelectedDropdownDisplayText(selectedInformationStandard),
      isOneSpaceEnabled: isOneSpaceSelected,
      isProjectWiseEnabled: isProjectWiseSelected,
      isBim360Enabled: isBim360Selected,
      is12dEnabled: is12dSynergySelected,
      projectWiseSelection: bindProjectWiseSelection(),
      bim360Selection: bindBim360Selection(),
    }
    
    updateContentEngineSelection(instance, accounts, postData)
      .then((results: IContentEngineSelectionModel) => {
        console.log('Content Engine Selection Saved');
        setIsContentEngineSelectionSaved(true);
        setActivePanelId("");
        props.handleLoadSavedContentEngineSelection();
      })
  }

  const bindProjectWiseSelection = () => {
    if(isProjectWiseSelected) {
      var projectWiseSelectionModel: IProjectWiseSelectionModel = {
        IsTransmittalsRequired: selectedProjectWiseTransmittalsRequired === "1" ? true : false
      }
      return projectWiseSelectionModel;
    }
    else {
      return undefined;
    }
  }

  const bindBim360Selection = () => {
    if(isBim360Selected) {
      var bim360Service: BIM360Service[] = [];
      selectedBIM360Admins.forEach((peoplePickerData: IPeoplePickerData) => {
        var administrator: IProjectPerson2 = {
          Name: peoplePickerData.displayName,
          Email: peoplePickerData.userPrincipalName
        }
        var service: BIM360Service = {
          serviceType: 'doc_manager',
          Administrator: administrator
        }
        bim360Service.push(service);
      });

      var bim360Content: BIM360Content = {
        Services: bim360Service,
        projectLanguage: "English",
        timeZone: getSelectedDropdownDisplayText(selectedTimeZoneBIM360),
        modellingTeam: getSelectedDropdownDisplayText(selectedModellingTeamBIM360)
      }

      return bim360Content;
    }
    else {
      return undefined;
    }
  }

  const loadContentEngine_InformationStandard = () => {
    return loadMetadataForLookup('InformationStandard');
  };

  const loadContentEngine_Timezone = () => {
    return loadMetadataTimeZone('Timezone');
  };

  const loadContentEngine_ModellingTeam = () => {
    return loadMetadataForLookup('ModellingTeam');
  };

  const loadMetadataForLookup = (metadataType: string) => {
    const returnMetadata:any[] = [];
    props.defaultContentEngineLookup.forEach((m: IProjectMetadataModel) => {
        if (m.type === metadataType) {
            var metadata = {id: m.id, label: m.value, value: m.value }
            returnMetadata.push(metadata);
        }
    });
    return returnMetadata;
  }

  const loadMetadataTimeZone = (metadataType: string) => {
    const returnMetadata:any[] = [];
    props.defaultContentEngineLookup.forEach((m: IProjectMetadataModel) => {
        if (m.type === metadataType) {
            var metadata;
            switch (m.value) {
              case 'Asia/Bangkok':
                metadata = {id: m.id, label: '(GMT+07:00) Bangkok/Hanoi', value: m.value }
                break;
              case 'Asia/Jakarta':
                metadata = {id: m.id, label: '(GMT+07:00) Jakarta', value: m.value }
                break;
              case 'Asia/Novosibirsk':
                metadata = {id: m.id, label: '(GMT+07:00) Novosibirsk', value: m.value }
                break;
              case 'Asia/Shanghai':
                metadata = {id: m.id, label: '(GMT+08:00) Beijing', value: m.value }
                break;
              case 'Asia/Chongqing':
                metadata = {id: m.id, label: '(GMT+08:00) Chongqing', value: m.value }
                break;
              case 'Asia/Hong_Kong':
                metadata = {id: m.id, label: '(GMT+08:00) Hong Kong', value: m.value }
                break;
              case 'Asia/Krasnoyarsk':
                metadata = {id: m.id, label: '(GMT+08:00) Krasnoyarsk', value: m.value }
                break;
              case 'Asia/Kuala_Lumpur':
                metadata = {id: m.id, label: '(GMT+08:00) Kuala Lumpur', value: m.value }
                break;
              case 'Australia/Perth':
                metadata = {id: m.id, label: '(GMT+08:00) Perth', value: m.value }
                break;
              case 'Asia/Singapore':
                metadata = {id: m.id, label: '(GMT+08:00) Singapore', value: m.value }
                break;
              case 'Asia/Taipei':
                metadata = {id: m.id, label: '(GMT+08:00) Taipei', value: m.value }
                break;
              case 'Asia/Ulaanbaatar':
                metadata = {id: m.id, label: '(GMT+08:00) Ulaan Bataar', value: m.value }
                break;
              case 'Asia/Urumqi':
                metadata = {id: m.id, label: '(GMT+08:00) Urumqi', value: m.value }
                break;
              case 'Asia/Irkutsk':
                metadata = {id: m.id, label: '(GMT+09:00) Irkutsk', value: m.value }
                break;
              case 'Asia/Tokyo':
                metadata = {id: m.id, label: '(GMT+09:00) Osaka/Sapporo/Tokyo', value: m.value }
                break;
              case 'Asia/Seoul':
                metadata = {id: m.id, label: '(GMT+09:00) Seoul', value: m.value }
                break;
              case 'Australia/Adelaide':
                metadata = {id: m.id, label: '(GMT+09:30) Adelaide', value: m.value }
                break;
              case 'Australia/Darwin':
                metadata = {id: m.id, label: '(GMT+09:30) Darwin', value: m.value }
                break;
              case 'Australia/Brisbane':
                metadata = {id: m.id, label: '(GMT+10:00) Brisbane', value: m.value }
                break;
              case 'Australia/Melbourne':
                metadata = {id: m.id, label: '(GMT+10:00) Canberra/Melbourne', value: m.value }
                break;
              case 'Pacific/Guam':
                metadata = {id: m.id, label: '(GMT+10:00) Guam', value: m.value }
                break;
              case 'Australia/Hobart':
                metadata = {id: m.id, label: '(GMT+10:00) Hobart', value: m.value }
                break;
              case 'Pacific/Port_Moresby':
                metadata = {id: m.id, label: '(GMT+10:00) Port Moresby', value: m.value }
                break;
              case 'Australia/Sydney':
                metadata = {id: m.id, label: '(GMT+10:00) Sydney', value: m.value }
                break;
              case 'Asia/Yakutsk':
                metadata = {id: m.id, label: '(GMT+10:00) Yakutsk', value: m.value }
                break;
              case 'Pacific/Noumea':
                metadata = {id: m.id, label: '(GMT+11:00) New Caledonia', value: m.value }
                break;
              case 'Asia/Vladivostok':
                metadata = {id: m.id, label: '(GMT+11:00) Vladivostok', value: m.value }
                break;
              case 'Pacific/Auckland':
                metadata = {id: m.id, label: '(GMT+12:00) Auckland/Wellington', value: m.value }
                break;
              default:
                metadata = {id: m.id, label: m.value, value: m.value }
                break;
            }
            returnMetadata.push(metadata);
        }
    });
    return returnMetadata;
  }

  const handleInformationStandardDropdownChange = (infoStandard: string | number) => {
    setSelectedInformationStandard(Number(infoStandard));
  }

  const handleModellingTeamDropdownChange = (modellingTeam: string | number) => {
    setSelectedModellingTeamBIM360(Number(modellingTeam));
  }

  const onChangeBIM360Admins = (e: any) => {
    setSelectedBIM360Admins(e.target.selectedPeople);
  };

  const getSelectedDropdownDisplayText = (optionId: number) => {
    let returnText = '';

    props.defaultContentEngineLookup.forEach((m: IProjectMetadataModel) => {
      if (m.id===optionId) {
        returnText = m.value ? m.value : '';
      }
    });

    return returnText;
  }

  const getIdFromDisplayText = (displayText: string) => {
    let returnId = 0;

    props.defaultContentEngineLookup.forEach((m: IProjectMetadataModel) => {
      if (m.value===displayText) {
          returnId = m.id ? m.id : 0;
      }
    });

    return returnId;
  }

  const checkIfProjectPlanningSubSectionSaveIsDisabled = () => {
    if(selectedPlanningExternalAccess==="" || selectedPlanningProjectConfidential==="" || completedDigitalWorkplan !== true) {
      return true;
    }
    else {
      return false;
    }
  }

  const checkIfContentEngineSelectionSaveIsDisabled = () => {
    if(checkClientInfoMgtSelection() && checkProjectWiseSelection() && checkBIM360Selection()) {
      return false;
    }
    else {
      return true;
    }
  }

  const checkClientInfoMgtSelection = () => {
    if(selectedContentEngineClientInfoMgt==="") {
      return false;
    }
    else {
      if(selectedContentEngineClientInfoMgt==="1" && selectedInformationStandard===0) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  const checkProjectWiseSelection = () => {
    if(isProjectWiseSelected) {
      if(selectedProjectWiseTransmittalsRequired !== "") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  const checkBIM360Selection = () => {
    if(isBim360Selected) {
      if(selectedTimeZoneBIM360 !== 0 && selectedModellingTeamBIM360 !== 0 && selectedBIM360Admins.length >= 2) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  const checkIfInformationStandardIsDisabled = () => {
    if(props.defaultContentEngineSelection[0] && props.defaultContentEngineSelection[0].inheritedFromProjectId !== "") {
      if(props.defaultContentEngineSelection[0].isProjectWiseEnabled) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if(props.projectContentEngineData && props.projectContentEngineData.projectWiseProvisioningStatus===2) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  const checkIfProjectWiseSelectionIsDisabled = () => {
    if(props.defaultContentEngineSelection[0] && props.defaultContentEngineSelection[0].inheritedFromProjectId !== "") {
      if(props.defaultContentEngineSelection[0].isProjectWiseEnabled) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if(props.projectContentEngineData && props.projectContentEngineData.projectWiseProvisioningStatus===2) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  const checkIfBIM360ProvisioningCompleted = () => {
    if(props.projectContentEngineData && props.projectContentEngineData.biM360ProvisioningStatus===2) {
      return true;
    }
    else {
      return false;
    }
  }

  const checkIf12dSynergyProvisioningCompleted = () => {
    if(props.projectContentEngineData && props.projectContentEngineData.synergy12dProvisioningStatus===2) {
      return true;
    }
    else {
      return false;
    }
  }
  
  const renderPlanningPanelHeader = () => {
    return (
      <div className={style.accordionHeaders}>
        <Icon type={isProjectPlanningSubSectionSaved ? "check_circle" : "error"} colour={isProjectPlanningSubSectionSaved ? '#00BD39' : '#E0452A'} />
        <span>Planning</span>
      </div>
    );
  }

  const renderPlanningPanelContent = () => {
    return (
      <div className={style.accordionContent}>
        <p><b>Description</b></p>
        <p>
          Plan the project to maximise delivery success, commensurate with client and Aurecon expectations, 
          contractual obligations, scope, product type, size, complexity, and risk profile.
        </p>
        {props.defaultPlanningSubSection[0] && 
          props.defaultPlanningSubSection[0].inheritedFromProjectId &&
            <p><b>These values are inherited from this Project Code - {props.defaultPlanningSubSection[0].inheritedFromProjectId.split("-")[0]} *</b></p>
        }
        <div className={style.flex}>
          <p className={style.left}>Is external access required?*</p>
          <div className={style.right}>
            <Radio
              items={planningPanelRadioSelection}
              selectedItem={selectedPlanningExternalAccess}
              onItemSelect={(id) => setSelectedPlanningExternalAccess(id)}
              horizontal
            />
          </div>
        </div>
        {selectedPlanningExternalAccess === '1'? 
        (
          <div className={style.flex}>
          <p className={style.left}>Will all external organisations on the project have the same level of access?*</p>
          <div className={style.right}>
            <Radio
              items={planningPanelRadioSelection}
              selectedItem={isSameAccess}
              onItemSelect={(id) => setisSameAccess(id) }
              horizontal
            />
          </div>
        </div>

        ):
        (<div></div>)

        }
        <div className={style.flex}>
          <p className={style.left}>
            Is this project confidential?*
            <div><sub>Note: Projects are accessible to all staff by default.</sub></div>
          </p>
          <div className={style.right}>
            <Radio
              items={planningPanelRadioSelection}
              selectedItem={selectedPlanningProjectConfidential}
              onItemSelect={(id) => setSelectedPlanningProjectConfidential(id)}
              horizontal
            />
          </div>
        </div>
        <div className={style.digitalWorkplan}>
          <p><b>Digital Workplan*</b></p>
          <div>
            <div>
              <Checkbox
                label="I have completed my digital workplan."
                checked={completedDigitalWorkplan}
                disabled={isProjectPlanningSubSectionSaved}
                onChange={(value) => {
                  setCompletedDigitalWorkplan(value)
                }}
              />
            </div>
          </div>
        </div>
        
        <div className={style.digitalPlanning}>
          <div className={style.digitalPlanningLink}>
            <a href="https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Platforms/APIMS/APIMS-Lifecycle-Planning.aspx" target="_blank" rel="noreferrer">
              <p><b><Icon type="error" /> Learn more about Digital Planning</b></p>
            </a>
          </div>
          {!isProjectPlanningSubSectionSaved && 
            <div className={style.rightButtons}>
              <Button
                  type="primary"
                  size="medium"
                  label='Save'
                  width='100px'
                  disabled={checkIfProjectPlanningSubSectionSaveIsDisabled()}
                  onClick={onSavePlanningSubSection}
                  cssClass={"buttonModeActive"}
              />
            </div>
          }
        </div>
      </div>
    )
  }

  const renderRiskHealthSafetyEnvironmentPanelHeader = () => {
    return (
      <div className={style.accordionHeaders}>
        <Icon type="check_circle" colour='#00BD39' />
        <span>Risk & Health, Safety and Environment (Coming Soon)</span>
      </div>
    );
  }

  const renderHardwareAndSoftwarePanelHeader = () => {
    return (
      <div className={style.accordionHeaders}>
        <Icon type="check_circle" colour='#00BD39' />
        <span>Hardware & Software (Coming Soon)</span>
      </div>
    );
  }

  const renderContentEnginePanelHeader = () => {
    return (
      <div className={style.accordionHeaders}>
        <Icon type={isContentEngineSelectionSaved ? "check_circle" : "error"} colour={isContentEngineSelectionSaved ? '#00BD39' : '#E0452A'} />
        <span>Content Engine Selection</span>
      </div>
    );
  }

  const renderContentEnginePanelContent = () => {
    return (
      <div className={style.accordionContent}>
        <p><b>Description</b></p>
        <p>
          Knowing where to store your information while you are working is important to ensure you can easily access and find this information when you need it.
        </p>
        {props.defaultPlanningSubSection[0] && 
          props.defaultPlanningSubSection[0].inheritedFromProjectId &&
            <p><b>These values are inherited from this Project Code - {props.defaultPlanningSubSection[0].inheritedFromProjectId.split("-")[0]} *</b></p>
        }
        <div className={style.contentEngineSelectionFlex}>
          <p className={style.left}>Are the client information management standards defined for this project?*</p>
          <div className={style.right}>
            <Radio
              items={contentEngineClientInfoMgtRadioSelection}
              selectedItem={selectedContentEngineClientInfoMgt}
              onItemSelect={(id) => setSelectedContentEngineClientInfoMgt(id)}
              horizontal
            />
          </div>
        </div>
        <div className={style.contentEngineSelectionFlex}>
          <p className={style.left}><b>Information standard*</b></p>
          <div className={style.right}>
            {selectedContentEngineClientInfoMgt==="1" &&
              <Dropdown
                cssClass={style.bim360Dropdown}
                items={loadContentEngine_InformationStandard()}
                selectedItem={selectedInformationStandard}
                onSelectItem={(selected) => { handleInformationStandardDropdownChange(selected) }}
                disabled={checkIfInformationStandardIsDisabled()}
              />
            }
            {selectedContentEngineClientInfoMgt==="2" &&
              <Dropdown
                cssClass={style.bim360Dropdown}
                items={apims2021DropdownItems}
                selectedItem={1}
                disabled
              />
            }
          </div>
        </div>
        <div className={style.contentEngineSelectionFlex}>
          <p className={style.left}><b>Project environment</b></p>
          <div className={style.projectEnvironmentCheckboxes}>
            <div className={style.projectEnvironmentCheckbox}>
              <Checkbox
                label="One Space"
                checked={isOneSpaceSelected}
                disabled={true}
              />
            </div>
            <div className={style.projectEnvironmentCheckbox}>
              <Checkbox
                label="ProjectWise"
                checked={isProjectWiseSelected}
                onChange={(value) => {
                  setIsProjectWiseSelected(value)
                }}
                disabled={checkIfProjectWiseSelectionIsDisabled()}
              />
            </div>
            <div className={style.projectEnvironmentCheckbox}>
              <Checkbox
                label="BIM 360"
                checked={isBim360Selected}
                onChange={(value) => {
                  setIsBim360Selected(value)
                }}
                disabled={checkIfBIM360ProvisioningCompleted()}
              />
            </div>
            <div className={style.projectEnvironmentCheckbox}>
              <Checkbox
                label="12d Synergy"
                checked={is12dSynergySelected}
                onChange={(value) => {
                  setIs12dSynergySelected(value)
                }}
                disabled={checkIf12dSynergyProvisioningCompleted()}
              />
            </div>
          </div>
        </div>
        <div className={style.contentEngineSelectionContainer}>
          <div className={style.contentEngineSelection}>
            <div className={style.projectEnvironmentTitle}>One Space</div>
            {/* Uncomment whenever we have implemented tfNSW */}
            {/* {selectedContentEngineClientInfoMgt==="1" &&
              getSelectedDropdownDisplayText(selectedInformationStandard)==="Transport for NSW (TfNSW)" &&
              <p>
                One Space will be provisioned with a <b>{getSelectedDropdownDisplayText(selectedInformationStandard)}</b> template. Client document code and revision requirements can be met.
              </p>
            }
            {selectedContentEngineClientInfoMgt==="1" && 
              getSelectedDropdownDisplayText(selectedInformationStandard) !== "Transport for NSW (TfNSW)" &&
              <p>
                One Space does not currently this support client standard. APIMS 2021 will be applied. Client document code and revision requirements can be met. <a href="https://aurecongroup.sharepoint.com/sites/Hive-Digital/Shared%20Documents/Platforms/DWS/OneSpace/OneSpace-Apply-Basic-Client-Metadata.pdf" target='_blank'>
                Learn more
                </a>
              </p>
            } */}
            {selectedContentEngineClientInfoMgt==="1" &&
              <p>
                One Space does not currently this support client standard. APIMS 2021 will be applied. Client document code and revision requirements can be met. <a href="https://aurecongroup.sharepoint.com/sites/Hive-Digital/Shared%20Documents/Platforms/DWS/OneSpace/OneSpace-Apply-Basic-Client-Metadata.pdf" target='_blank' rel="noreferrer">
                Learn more
                </a>
              </p>
            }
            {selectedContentEngineClientInfoMgt==="2" &&
              <p className={style.notSelected}>No additional information required.</p>
            }
            {props.defaultContentEngineSelection[0] && 
              props.defaultContentEngineSelection[0].inheritedFromProjectId !== "" &&
                <div>
                  <p>This project will be setup under the project hierarchy within One Space.</p>
                  <p>NOTE: MOBILISATION VIA THE CONTENT ENGINES TAB IS STILL REQUIRED FOR THIS PROJECT.</p>
                </div>
            }
          </div>
          <div className={style.contentEngineSelection}>
            <div className={style.projectEnvironmentTitle}>ProjectWise</div>
            {isProjectWiseSelected ? 
              renderContentEngineProjectWiseContent()
              :
              <p className={style.notSelected}>Not selected.</p>
            }
          </div>
          <div className={style.contentEngineSelection}>
            <div className={style.projectEnvironmentTitle}>BIM 360</div>
            {isBim360Selected ?
              renderBIM360Content() 
              :
              <p className={style.notSelected}>Not selected.</p>
            }
          </div>
          <div className={style.contentEngineSelection}>
            <div className={style.projectEnvironmentTitle}>12d Synergy</div>
            {is12dSynergySelected ? 
              render12DSynergyContent()
              :
              <p className={style.notSelected}>Not selected.</p>
            }
          </div>
        </div>
        <div className={style.unsureWhatContentEngine}>
          <div className={style.unsureWhatContentEngineLink}>
            <a href="https://aurecongroup.sharepoint.com/sites/HIVE-Knowledge/Shared%20Documents/Guide%20for%20Project%20Information%20at%20Aurecon.pdf" target="_blank" rel="noreferrer">
              <p><b><Icon type="error" /> Unsure what content engine to use?</b></p>
            </a>
          </div>
          <div className={style.rightButtons}>
            <Button
              type="primary"
              size="medium"
              label='Cancel'
              width='100px'
              // disabled={checkIfProjectPlanningSubSectionSaveIsDisabled()}
              onClick={resetContentEngineSelection}
              cssClass={"buttonModeDeactive"}
            />
            <Button
              type="primary"
              size="medium"
              label='Save'
              width='100px'
              disabled={checkIfContentEngineSelectionSaveIsDisabled()}
              onClick={onSaveContentEngineSelection}
              cssClass={"buttonModeActive"}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderContentEngineProjectWiseContent = () => {
    return (
      <div>
        {props.defaultContentEngineSelection[0] && 
          props.defaultContentEngineSelection[0].inheritedFromProjectId !== "" &&
            <div>
              <p>This project will be setup under the project hierarchy within ProjectWise.</p>
              <p>NOTE: MOBILISATION VIA THE CONTENT ENGINES TAB IS STILL REQUIRED FOR THIS PROJECT.</p>
            </div>
        }
        {selectedContentEngineClientInfoMgt==="1" && 
          selectedInformationStandard !== 0 &&
            getSelectedDropdownDisplayText(selectedInformationStandard)==="Other" &&
              <div>
                {/* <p>Template: <b>{getSelectedDropdownDisplayText(selectedInformationStandard)}</b>.</p> */}
                <p>The ProjectWise team will be in contact to discuss your specific client requirements.</p>
              </div>
        }
        <div className={style.contentEngineSelectionFlex}>
          <p className={style.leftWithWidth}>Are transmittals required?*</p>
          <div className={style.right}>
            <Radio
              items={projectWiseTransmittalRadioSelection}
              selectedItem={selectedProjectWiseTransmittalsRequired}
              onItemSelect={(id) => setSelectedProjectWiseTransmittalsRequired(id)}
              horizontal
            />
          </div>
        </div>
      </div>
    )
  }

  const renderBIM360Content = () => {
    return (
      <div>
        {props.defaultContentEngineSelection[0] && 
          props.defaultContentEngineSelection[0].inheritedFromProjectId !== "" &&
            props.projectContentEngineData?.biM360ProvisioningStatus===2 &&
              <div>
                <p>BIM 360 Mobilisation has been completed for this hierarchy project configuration as per {props.defaultContentEngineSelection[0].inheritedFromProjectId?.split("-")[0]}</p>
              </div>
        }
        {selectedContentEngineClientInfoMgt==="1" &&
            <div>
              <p>
                BIM 360 does not currently support client standards. APIMS 2021 will be applied.
              </p>
            </div>
          }
        <div className={style.contentEngineSelectionFlex}>
          <p className={style.leftWithWidth}>Time zone*</p>
          <div className={style.rightWithWidth}>
            <Dropdown
              cssClass="bim360Dropdown"
              items={loadContentEngine_Timezone()}
              selectedItem={selectedTimeZoneBIM360}
              disabled={checkIfBIM360ProvisioningCompleted()}
              onSelectItem={(selected) => { setSelectedTimeZoneBIM360(Number(selected)) }}
            />
          </div>
        </div>
        <div className={style.contentEngineSelectionFlex}>
          <p className={style.leftWithWidth}>Modelling Team*</p>
          <div className={style.rightWithWidth}>
            <Dropdown
              cssClass="bim360Dropdown"
              items={loadContentEngine_ModellingTeam()}
              selectedItem={selectedModellingTeamBIM360}
              disabled={checkIfBIM360ProvisioningCompleted()}
              onSelectItem={(selected) => { handleModellingTeamDropdownChange(selected) }}
            />
          </div>
        </div>
        <div className={style.contentEngineSelectionFlex}>
          <p className={style.leftWithWidth}>Administrators*</p>
          <div className={style.rightWithWidth}>
            <PeoplePicker id="bim360Admins"
              className={ checkIfBIM360ProvisioningCompleted() ? 'displayModeMgtPeoplePicker' : 'editModeMgtPeoplePicker' }
              selectedPeople={selectedBIM360Admins}
              selectionChanged={onChangeBIM360Admins}
              disabled={checkIfBIM360ProvisioningCompleted()}
            />
          </div>
        </div>
        <div className={style.contentEngineWarning}>Please select a minimum of two administrators.</div>
      </div>
    )
  }

  const render12DSynergyContent = () => {
    return (
      <div>
        {props.defaultContentEngineSelection[0] && 
          props.defaultContentEngineSelection[0].inheritedFromProjectId !== "" &&
            props.projectContentEngineData?.biM360ProvisioningStatus===2 &&
              <div>
                <p>BIM 360 Mobilisation has been completed for this hierarchy project configuration as per {props.defaultContentEngineSelection[0].inheritedFromProjectId?.split("-")[0]}</p>
              </div>
        }
        <p className={style.notSelected}>12d Synergy does not currently support client standards. APIMS 2021 will be applied.</p>
      </div>
    )
  }

  return (
    <div className={style.projectTabPlanning}>
        <Accordion activePanelId={activePanelId} onPanelToggle={(panelId: string) => onChangePanel(panelId)}>
          <AccordionPanel panelId='p1' label={renderPlanningPanelHeader()}>
            {renderPlanningPanelContent()}
          </AccordionPanel>
          <AccordionPanel panelId='p2' label={renderRiskHealthSafetyEnvironmentPanelHeader()} disabled />
          <AccordionPanel panelId='p3' label={renderHardwareAndSoftwarePanelHeader()} disabled />          
          <AccordionPanel panelId='p4' label={renderContentEnginePanelHeader()}>
            {renderContentEnginePanelContent()}
          </AccordionPanel>
        </Accordion>

    </div>);
};

export default Tab1Planning;

import style from '../../../styles/components/project/ProjectTabs.module.scss';

import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Button, Grid, Modal } from '@aurecon-creative-technologies/styleguide';
import { IProjectDetails } from '../../../models/project/IProjectDetails';
import { ReactComponent as OneSpaceIcon } from '../../../assets/one_space_logo.svg';
import { ReactComponent as ProjectWiseIcon } from '../../../assets/projectwise_logo.svg';
import { ReactComponent as BIM360Icon } from '../../../assets/bim_360_logo.svg';
import { ReactComponent as TwelveDSynergyIcon } from '../../../assets/12d_synergy_logo.svg';
import { trigger12dSynergyCreation, triggerBIM360Creation, triggerOneSpaceCreation, triggerProjectWiseCreation } from '../../../apis/project/Project';
import { I12dSynergyTriggerModel } from '../../../models/project/I12dSynergyTriggerModel';
import { IProjectWiseTriggerClientAndOrg, IProjectWiseTriggerModel, IProjectWiseTriggerRoles } from '../../../models/project/IProjectWiseTriggerModel';
import { IContentEngineSelectionModel } from '../../../models/project/IContentEngineSelectionModel';
import { IProjectRolesModel } from '../../../models/project/IProjectRolesModel';
import { IProjectPerson } from '../../../models/project/IProjectPerson';
import { IProjectContentEngine } from '../../../models/project/IProjectContentEngine';
import { ProvisioningStatus } from '../../../enums/ProvisioningStatus';
import { IOneSpaceTriggerModel } from '../../../models/project/IOneSpaceTriggerModel';
import { IProjectPlanningSubSectionModel } from '../../../models/project/IProjectPlanningSubSectionModel';
import { IBIM360TriggerModel } from '../../../models/project/IBIM360TriggerModel';
import { IExternalRoles } from '../../../models/project/IExternalRoles';

interface IProjectContentEnginesProps {
  pageState: any;
  projectContentEngineData?: IProjectContentEngine;
  defaultPlanningSubSection: IProjectPlanningSubSectionModel[];
  defaultContentEngineSelection: IContentEngineSelectionModel[];
  defaultRoles: IProjectRolesModel[];
  isProjectPlanningSubSectionSaved: boolean;
  isContentEngineSelectionSaved: boolean;
  isMandatoryRolesComplete: boolean;
  handleContentEngineCreation: () => void;
}

const Tab5ContentEngines: React.FunctionComponent<IProjectContentEnginesProps> = (props) => {

  const pageState: IProjectDetails = props.pageState;

  const { instance, accounts } = useMsal();
  const currentUserEmailAddress = accounts[0] && accounts[0].username;
  const currentUserDisplayName = accounts[0] && accounts[0].name;

  const [isBIM360ModalShowing, setIsBIM360ModalShowing] = useState<boolean>(false);
  const [isOneSpaceModalShowing, setIsOneSpaceModalShowing] = useState<boolean>(false);
  const [isProjectWiseModalShowing, setIsProjectWiseModalShowing] = useState<boolean>(false);
  const [is12dSynergyModalShowing, setIs12dSynergyModalShowing] = useState<boolean>(false);

  const onShowBIM360Modal = () => {
    setIsBIM360ModalShowing(true);
  }
  
  const onCloseBIM360Modal = () => {
    setIsBIM360ModalShowing(false);
  }

  const onShowOneSpaceModal = () => {
    setIsOneSpaceModalShowing(true);
  }
  
  const onCloseOneSpaceModal = () => {
    setIsOneSpaceModalShowing(false);
  }

  const onShowProjectWiseModal = () => {
    setIsProjectWiseModalShowing(true);
  }
  
  const onCloseProjectWiseModal = () => {
    setIsProjectWiseModalShowing(false);
  }

  const onShow12dSynergyModal = () => {
    setIs12dSynergyModalShowing(true);
  }

  const onClose12dSynergyModal = () => {
    setIs12dSynergyModalShowing(false);
  }

  const onGetProjectWiseTemplateName = () => {
    var templateName = '';
    if(props.defaultContentEngineSelection[0].isClientStandardDefined) {
      if(props.defaultContentEngineSelection[0].informationStandard === "Other") {
        // templateName = props.defaultContentEngineSelection[0].informationStandardOther ? props.defaultContentEngineSelection[0].informationStandardOther : '';
        templateName = "Other";
      }
      else {
        templateName = props.defaultContentEngineSelection[0].informationStandard;
      }
    }
    else {
      templateName = 'APIMS 2021';
    }
    return templateName;
  }

  const onCreateOneSpace = () => {
    const owners: string[] = [];
    if((props.defaultRoles[0].ProjectManagers && props.defaultRoles[0].ProjectManagers?.length > 0) || (props.defaultRoles[1].ProjectManagers && props.defaultRoles[1].ProjectManagers?.length > 0)) {
      for (var i in props.defaultRoles) {
        props.defaultRoles[i].ProjectManagers?.forEach((defRole: IProjectPerson) => {
          owners.push(defRole.email);
        })
      }
    }

    const postData: IOneSpaceTriggerModel = {
      projectId : pageState.projectDetails.projectId,
      projectName: pageState.projectDetails.projectName,
      owners: owners,
      requesterEmail: currentUserEmailAddress,
      masterProjectHierarchyID: pageState.projectDetails.masterProjectHierarchyID
    }

    triggerOneSpaceCreation(instance, accounts, postData)
      .then((results) => {
        setIsOneSpaceModalShowing(false);
        props.handleContentEngineCreation();
      })
  }
  
  const onCreateProjectWise = () => {
    const clientData: IProjectWiseTriggerClientAndOrg = {
      name: pageState.projectDetails.client,
      code: pageState.projectDetails.customerId ? pageState.projectDetails.customerId : ''
    }

    const owningOrgData: IProjectWiseTriggerClientAndOrg = {
      name: pageState.projectDetails.costCenter ? pageState.projectDetails.costCenter : '',
      code: pageState.projectDetails.costCenterCode ? pageState.projectDetails.costCenterCode : ''
    }

    const roles: IProjectWiseTriggerRoles[] = [];
    if((props.defaultRoles[0].ProjectManagers && props.defaultRoles[0].ProjectManagers?.length > 0) || (props.defaultRoles[1].ProjectManagers && props.defaultRoles[1].ProjectManagers?.length > 0)) {
      for (var x in props.defaultRoles) {
        props.defaultRoles[x].ProjectManagers?.forEach((defRole: IProjectPerson) => {
          var projectManagers: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'ProjectManager',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(projectManagers);
        })
      }
    }
    //add role InformationControllers, Digital Practice Leader Project Accountant, Reviewers, Approvers, Authorisers, Verifiers
    if((props.defaultRoles[0].InformationControllers && props.defaultRoles[0].InformationControllers?.length > 0) || (props.defaultRoles[1].InformationControllers && props.defaultRoles[1].InformationControllers?.length > 0)) {
      for (var x in props.defaultRoles) {
        props.defaultRoles[x].InformationControllers?.forEach((defRole: IProjectPerson) => {
          var InformationControllers: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'InformationController',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(InformationControllers);
        })
      }
    }

    if((props.defaultRoles[0].ProjectSupportGroup && props.defaultRoles[0].ProjectSupportGroup?.length > 0) || (props.defaultRoles[1].ProjectSupportGroup && props.defaultRoles[1].ProjectSupportGroup?.length > 0)) {
      for (var i in props.defaultRoles) {
        props.defaultRoles[i].ProjectSupportGroup?.forEach((defRole: IProjectPerson) => {
          var projectSupports: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'User',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(projectSupports);
        })
      }
    }
    if((props.defaultRoles[0].ProjectAccountants && props.defaultRoles[0].ProjectAccountants?.length > 0) || (props.defaultRoles[1].ProjectAccountants && props.defaultRoles[1].ProjectAccountants?.length > 0)) {
      for (var i in props.defaultRoles) {
        props.defaultRoles[i].ProjectAccountants?.forEach((defRole: IProjectPerson) => {
          var ProjectAccountant: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'User',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(ProjectAccountant);
        })
      }
    }
    if((props.defaultRoles[0].Reviewers && props.defaultRoles[0].Reviewers?.length > 0) || (props.defaultRoles[1].Reviewers && props.defaultRoles[1].Reviewers?.length > 0)) {
      for (var i in props.defaultRoles) {
        props.defaultRoles[i].Reviewers?.forEach((defRole: IProjectPerson) => {
          var Reviewer: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'Verifier',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(Reviewer);
        })
      }
    }
    if((props.defaultRoles[0].Approvers && props.defaultRoles[0].Approvers?.length > 0) || (props.defaultRoles[1].Approvers && props.defaultRoles[1].Approvers?.length > 0)) {
      for (var i in props.defaultRoles) {
        props.defaultRoles[i].Approvers?.forEach((defRole: IProjectPerson) => {
          var Approver: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'Approver',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(Approver);
        })
      }
    }
    if((props.defaultRoles[0].Authorisers && props.defaultRoles[0].Authorisers?.length > 0) || (props.defaultRoles[1].Authorisers && props.defaultRoles[1].Authorisers?.length > 0)) {
      for (var i in props.defaultRoles) {
        props.defaultRoles[i].Authorisers?.forEach((defRole: IProjectPerson) => {
          var Authoriser: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'Authoriser',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(Authoriser);
        })
      }
    }
    if((props.defaultRoles[0].Verifiers && props.defaultRoles[0].Verifiers?.length > 0) || (props.defaultRoles[1].Verifiers && props.defaultRoles[1].Verifiers?.length > 0)) {
      for (var i in props.defaultRoles) {
        props.defaultRoles[i].Verifiers?.forEach((defRole: IProjectPerson) => {
          var Verifier: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'Verifier',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(Verifier);
        })
      }
    }

    if((props.defaultRoles[0].DigitalPracticeLeader && props.defaultRoles[0].DigitalPracticeLeader?.length > 0) || (props.defaultRoles[1].DigitalPracticeLeader && props.defaultRoles[1].DigitalPracticeLeader?.length > 0)) {
      for (var x in props.defaultRoles) {
        props.defaultRoles[x].DigitalPracticeLeader?.forEach((defRole: IProjectPerson) => {
          var DigitalPracticeLeader: IProjectWiseTriggerRoles = {
            userName: defRole.name,
            roleName: 'InformationController',
            resourceValue: pageState.projectDetails.projectId,
            assignedTime: new Date().toDateString(),
          }
          roles.push(DigitalPracticeLeader);
        })
      }
    }

    let ExternalRoles: IExternalRoles[] | undefined;

    const postData: IProjectWiseTriggerModel = {
      projectNumber : pageState.projectDetails.projectId,
      projectName: pageState.projectDetails.projectName,
      client: clientData,
      owningOrg: owningOrgData,
      aureconRegion: pageState.projectDetails.region,
      workLocation: pageState.projectDetails.location ? pageState.projectDetails.location : '',
      createdBy : currentUserEmailAddress,
      projectManager: pageState.projectDetails.projectManagerName,
      templateName: onGetProjectWiseTemplateName(),
      isConfidential: props.defaultPlanningSubSection[0].isExternalProcessRequired ? props.defaultPlanningSubSection[0].isExternalProcessRequired : false,
      transmittalsRequired: props.defaultContentEngineSelection[0].projectWiseSelection?.IsTransmittalsRequired ? props.defaultContentEngineSelection[0].projectWiseSelection?.IsTransmittalsRequired : false,
      roles: roles,
      isUpdating: false,
      ExternalRoles: ExternalRoles,
      isSameExternalAccess: false
    }

    triggerProjectWiseCreation(instance, accounts, postData)
      .then((results) => {
        setIsProjectWiseModalShowing(false);
        props.handleContentEngineCreation();
      })
  }

  const onCreateBIM360 = () => {
    const postData: IBIM360TriggerModel = {
      projectNumber: pageState.projectDetails.projectId,
      createdBy: currentUserEmailAddress,
      createdByName: currentUserDisplayName
    }
    
    triggerBIM360Creation(instance, accounts, postData)
      .then((results) => {
        setIsBIM360ModalShowing(false);
        props.handleContentEngineCreation();
      })
  }

  const onCreate12dSynergy = () => {
    const postData: I12dSynergyTriggerModel = {
      projectNumber : pageState.projectDetails.projectId,
      name: pageState.projectDetails.projectName,
      template :"APIMS job",
      createdBy : currentUserEmailAddress,
      projectManager: pageState.projectDetails.projectManagerName,
      client : pageState.projectDetails.client,
      costCenter: pageState.projectDetails.costCenter ? pageState.projectDetails.costCenter : '',
      location: pageState.projectDetails.location ? pageState.projectDetails.location : '',
      isConfidential: props.defaultPlanningSubSection[0].isExternalProcessRequired ? props.defaultPlanningSubSection[0].isExternalProcessRequired : false,
    }

    trigger12dSynergyCreation(instance, accounts, postData)
      .then((results) => {
        setIs12dSynergyModalShowing(false);
        props.handleContentEngineCreation();
      })
  }

  const openOneSpaceLink = () => {
    window.open('https://aurecongroup.sharepoint.com/sites/' + pageState.projectDetails.projectId.replace('P',''), "_blank");
  }

  const openProjectWiseLink = () => {
    window.open(props.projectContentEngineData?.projectWiseLink, "_blank");
  }

  const open12dSynergyLink = () => {
    window.open(props.projectContentEngineData?.synergy12dLink, "_blank");
  }

  const renderProvisioningStatus = (contentEngine: string) => {
    var isEnabled = false;
    var provisioningStatus;
    if(props.isProjectPlanningSubSectionSaved && props.isContentEngineSelectionSaved && props.isMandatoryRolesComplete) {
      if(props.defaultContentEngineSelection || props.projectContentEngineData) {
        switch (contentEngine) {
          case "BIM360":
            isEnabled = props.defaultContentEngineSelection[0].isBim360Enabled;
            provisioningStatus = props.projectContentEngineData?.biM360ProvisioningStatus;
            break;
          case "OneSpace":
            isEnabled = props.defaultContentEngineSelection[0].isOneSpaceEnabled;
            provisioningStatus = props.projectContentEngineData?.oneSpaceProvisioningStatus;
            break;
          case "ProjectWise":
            isEnabled = props.defaultContentEngineSelection[0].isProjectWiseEnabled;
            provisioningStatus = props.projectContentEngineData?.projectWiseProvisioningStatus;
            break;
          case "12dSynergy":
            isEnabled = props.defaultContentEngineSelection[0].is12dEnabled;
            provisioningStatus = props.projectContentEngineData?.synergy12dProvisioningStatus;
            break;
        }
        
        if(isEnabled) {
          if(props.projectContentEngineData) {
            if(provisioningStatus === 1) {
              return (
                <div className={style.mobilisation} style={{ background: '#FFEFB6' }}>{ProvisioningStatus.InProgress}</div>
              )
            }
            else if(provisioningStatus === 2) {
              return (
                <div className={style.mobilisation} style={{background:'#C4F6EA'}}>{ProvisioningStatus.Complete}</div>
              )
            }
            else {
              return (
                <div className={style.mobilisation} style={{ background: '#A9C7F4' }}>{ProvisioningStatus.Ready}</div>
              )
            }
          }
          else {
            return (
              <div className={style.mobilisation} style={{ background: '#A9C7F4' }}>{ProvisioningStatus.Ready}</div>
            )
          }
        }
        else {
          return (
            <div className={style.mobilisation} style={{ background: '#D4D4D4' }}>{ProvisioningStatus.NotSelected}</div>
          );
        }
      }
      else {
        return (
          <div className={style.mobilisation} style={{ background: '#D4D4D4' }}>{ProvisioningStatus.NotSelected}</div>
        )
      }
    }
    else {
      return (
        <div className={style.mobilisation}></div>
      )
    }
  }

  const renderBIM360Button = () => {
    if(props.isProjectPlanningSubSectionSaved && props.isContentEngineSelectionSaved && props.isMandatoryRolesComplete) {
      if(props.projectContentEngineData) {
        if(props.projectContentEngineData.biM360ProvisioningStatus === 0 && props.defaultContentEngineSelection[0].isBim360Enabled) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='Setup'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={onShowBIM360Modal}
              />
            </div>
          )
        }
      }
      else {
        if(props.defaultContentEngineSelection[0].isBim360Enabled) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='Setup'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={onShowBIM360Modal}
              />
            </div>
          )
        }
      }
    }
  }

  const renderOneSpaceButton = () => {
    if(props.isProjectPlanningSubSectionSaved && props.isContentEngineSelectionSaved && props.isMandatoryRolesComplete) {
      if(props.projectContentEngineData) {
        if(props.projectContentEngineData.oneSpaceProvisioningStatus === 0 && props.defaultContentEngineSelection[0].isOneSpaceEnabled) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='Setup'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={onShowOneSpaceModal}
              />
            </div>
          )
        }
        else if(props.projectContentEngineData.oneSpaceProvisioningStatus === 2) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='View'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={openOneSpaceLink}
              />
            </div>
          )
        }
      }
      else {
        return (
          <div className={style.bottomButtonsContainer}>
            <Button
              type="primary"
              size="medium"
              label='Setup'
              width='100px'
              icon='launch'
              cssClass={"buttonModeActive"}
              onClick={onShowOneSpaceModal}
            />
          </div>
        )
      }
    }
  }

  const renderProjectWiseButton = () => {
    if(props.isProjectPlanningSubSectionSaved && props.isContentEngineSelectionSaved && props.isMandatoryRolesComplete) {
      if(props.projectContentEngineData) {
        if(props.projectContentEngineData.projectWiseProvisioningStatus === 0 && props.defaultContentEngineSelection[0].isProjectWiseEnabled) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='Setup'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={onShowProjectWiseModal}
              />
            </div>
          )
        }
        else if(props.projectContentEngineData.projectWiseProvisioningStatus === 2) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='View'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={openProjectWiseLink}
              />
            </div>
          )
        }
      }
      else {
        if(props.defaultContentEngineSelection[0].isProjectWiseEnabled) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='Setup'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={onShowProjectWiseModal}
              />
            </div>
          )
        }
      }
    }
  }

  const render12dSynergyButton = () => {
    if(props.isProjectPlanningSubSectionSaved && props.isContentEngineSelectionSaved && props.isMandatoryRolesComplete) {
      if(props.projectContentEngineData) {
        if(props.projectContentEngineData.synergy12dProvisioningStatus === 0 && props.defaultContentEngineSelection[0].is12dEnabled) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='Setup'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={onShow12dSynergyModal}
              />
            </div>
          )
        }
        else if(props.projectContentEngineData.synergy12dProvisioningStatus === 2) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='View'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={open12dSynergyLink}
              />
            </div>
          )
        }
      }
      else {
        if(props.defaultContentEngineSelection[0].is12dEnabled) {
          return (
            <div className={style.bottomButtonsContainer}>
              <Button
                type="primary"
                size="medium"
                label='Setup'
                width='100px'
                icon='launch'
                cssClass={"buttonModeActive"}
                onClick={onShow12dSynergyModal}
              />
            </div>
          )
        }
      }
    }
  }

  const renderBIM360Card = () => {
    return (
      <div className={style.contentEngineCardContainer}>
        <div className={style.headerContainer}>
          <div className={style.header}>BIM 360</div>
          <div className={style.icon}><BIM360Icon width={40} height={40} /></div>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.lineWrapper}>
            <div className={style.description}>
              BIM360 is a design collaboration content engine. It has been configured to support APIMS 2021, a basic template is made available to projects.
            </div>
          </div>
          <div className={style.lineWrapper}>
            {renderProvisioningStatus("BIM360")}
          </div>
        </div>
        {renderBIM360Button()}
        <Modal
          isShowing={isBIM360ModalShowing}
          onClose={onCloseBIM360Modal}
          shouldOverlayClose={false}
          isCloseButton={true}
        >
          <div className={style.modalContainer}>
            <div>
              <p><b>Ready to setup?</b></p>
            </div>
            <div>
              <p>Are you sure you are ready to setup this Content Engine? You cannot undo this action.</p>
            </div>
            <div className={style.rightButtons}>
              <Button
                type="secondary"
                size="medium"
                label='Cancel'
                width='100px'
                onClick={onCloseBIM360Modal}
                cssClass={"buttonModeActive"}
              />
              <Button
                type="secondary"
                size="medium"
                label='Setup'
                width='100px'
                onClick={onCreateBIM360}
                cssClass={"buttonModeActive"}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  const renderOneSpaceCard = () => {
    return (
      <div className={style.contentEngineCardContainer}>
        <div className={style.headerContainer}>
          <div className={style.header}>One Space</div>
          <div className={style.icon}><OneSpaceIcon width={25} height={40} /></div>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.lineWrapper}>
            <div className={style.description}>
              One Space is Aurecon’s configured environment of Microsoft SharePoint to support Opportunities during the Engage and Win phase, and projects during Deliver phase. 
              One Space adopts APIMS 2021 and includes Microsoft Teams and a project mailbox as part of the content engine.
            </div>
          </div>
          <div className={style.lineWrapper}>
            {renderProvisioningStatus("OneSpace")}
          </div>
        </div>
        {renderOneSpaceButton()}
        <Modal
          isShowing={isOneSpaceModalShowing}
          onClose={onCloseOneSpaceModal}
          shouldOverlayClose={false}
          isCloseButton={true}
        >
          <div className={style.modalContainer}>
            <div>
              <p><b>Ready to setup?</b></p>
            </div>
            <div>
              <p>Are you sure you are ready to setup this Content Engine? You cannot undo this action.</p>
            </div>
            <div className={style.rightButtons}>
              <Button
                type="secondary"
                size="medium"
                label='Cancel'
                width='100px'
                onClick={onCloseOneSpaceModal}
                cssClass={"buttonModeActive"}
              />
              <Button
                type="secondary"
                size="medium"
                label='Setup'
                width='100px'
                onClick={onCreateOneSpace}
                cssClass={"buttonModeActive"}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  const renderProjectWiseCard = () => {
    return (
      <div className={style.contentEngineCardContainer}>
        <div className={style.headerContainer}>
          <div className={style.header}>ProjectWise</div>
          <div className={style.icon}><ProjectWiseIcon width={40} height={40} /></div>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.lineWrapper}>
            <div className={style.description}>
              ProjectWise is currently Aurecon’s preferred Content Engine for design content (for supported applications).
              <br/>
              ProjectWise can be used for the management of the content lifecycle of all engineering design content.
            </div>
          </div>
          <div className={style.lineWrapper}>
            {renderProvisioningStatus("ProjectWise")}
          </div>
        </div>
        {renderProjectWiseButton()}
        <Modal
          isShowing={isProjectWiseModalShowing}
          onClose={onCloseProjectWiseModal}
          shouldOverlayClose={false}
          isCloseButton={true}
        >
          <div className={style.modalContainer}>
            <div>
              <p><b>Ready to setup?</b></p>
            </div>
            <div>
              <p>Are you sure you are ready to setup this Content Engine? You cannot undo this action.</p>
            </div>
            <div className={style.rightButtons}>
              <Button
                type="secondary"
                size="medium"
                label='Cancel'
                width='100px'
                onClick={onCloseProjectWiseModal}
                cssClass={"buttonModeActive"}
              />
              <Button
                type="secondary"
                size="medium"
                label='Setup'
                width='100px'
                onClick={onCreateProjectWise}
                cssClass={"buttonModeActive"}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  const render12dSynergyCard = () => {
    return (
      <div className={style.contentEngineCardContainer}>
        <div className={style.headerContainer}>
          <div className={style.header}>12d Synergy</div>
          <div className={style.icon}><TwelveDSynergyIcon width={40} height={40} /></div>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.lineWrapper}>
            <div className={style.description}>
              12D Synergy is a design collaboration content engine. It has been configured to support APIMS 2021, a basic template is made available to projects.
            </div>
          </div>
          <div className={style.lineWrapper}>
            {renderProvisioningStatus("12dSynergy")}
          </div>
        </div>
        {render12dSynergyButton()}
        <Modal
          isShowing={is12dSynergyModalShowing}
          onClose={onClose12dSynergyModal}
          shouldOverlayClose={false}
          isCloseButton={true}
        >
          <div className={style.modalContainer}>
            <div>
              <p><b>Ready to setup?</b></p>
            </div>
            <div>
              <p>Are you sure you are ready to setup this Content Engine? You cannot undo this action.</p>
            </div>
            <div className={style.rightButtons}>
              <Button
                type="secondary"
                size="medium"
                label='Cancel'
                width='100px'
                onClick={onClose12dSynergyModal}
                cssClass={"buttonModeActive"}
              />
              <Button
                type="secondary"
                size="medium"
                label='Setup'
                width='100px'
                onClick={onCreate12dSynergy}
                cssClass={"buttonModeActive"}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className={style.contentEngineTabContainer}>
      <div className={style.contentEngineTabDescription}>
        <p>
          Content engine are the primary element to any Common Data Environment.
          Aurecon has a predefined a number of Content Engines based on information types and applications.
        </p>
        <p>The current approved Content Engines within Aurecon are:</p>
      </div>
      <Grid row md={12} gap={12} cssClass={style.contentEnginesCards}>
        <Grid item md={3} key={"content-engine-grid" + 1}>
          {renderBIM360Card()}
        </Grid>
        <Grid item md={3} key={"content-engine-grid" + 2}>
          {renderOneSpaceCard()}
        </Grid>
        <Grid item md={3} key={"content-engine-grid" + 3}>
          {renderProjectWiseCard()}
        </Grid>
        <Grid item md={3} key={"content-engine-grid" + 4}>
          {render12dSynergyCard()}
        </Grid>
      </Grid>
    </div>
  );
};

export default Tab5ContentEngines;

import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { RequestAccessToken } from "./accessTokenHandler";

const headersWithToken = (token: string | undefined): HeadersInit => {
  return {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Methods": "GET, POST",
    //'Access-Control-Allow-Origin': '*',
    accept: "application/json",
    "Content-Type": "application/json",
  };
};

const getAsync = async <TResponse>(
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  apiUrl: string,
  tokenRequired = true
): Promise<TResponse> => {
  let requestInfo: RequestInit = {
    method: "GET",
  };

  if (tokenRequired) {
    const token = await RequestAccessToken(instance, accounts);
    requestInfo.headers = headersWithToken(token);
  }

  return new Promise((resolve, reject) => {
    fetch(apiUrl, requestInfo)
      .then((response) => response.text())
      .then((responseText) => {
        if (responseText) return JSON.parse(responseText);
        else return null;
      })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

const postAsync = async <TResponse>(
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  apiUrl: string,
  postData: any
): Promise<TResponse> => {
  const token = await RequestAccessToken(instance, accounts);
  const jsonBody = JSON.stringify(postData);

  return new Promise((resolve, reject) => {
    fetch(apiUrl, {
      method: "POST",
      headers: headersWithToken(token),
      body: jsonBody,
    })
      .then((response) => response.text())
      .then((responseText) => {
        if (responseText) return JSON.parse(responseText);
        else return null;
      })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export { getAsync, postAsync };

import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { IGeocodingAddressCandidateResponse } from "../../models/map/IGeocodingAddressCandidateResponse";
import { IGeocodingResponse } from "../../models/map/IGeocodingSuggestResponse";
import { getAsync } from "../../utils/transportRequest";

const baseUrl =
  "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer";

export const geoCodingSuggest = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  text: string,
  sourceContry?: string
): Promise<IGeocodingResponse> => {
  const suggestUrl = `${baseUrl}/suggest?text=${text}&sourceCountry=${sourceContry}&f=json`;
  return getAsync<IGeocodingResponse>(instance, accounts, suggestUrl, false);
};

export const findGeoCodingAddressCandidates = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  magicKey: string
): Promise<IGeocodingAddressCandidateResponse> => {
  const token = await acquireGeoCodingAcessToken(instance, accounts);
  const url = `${baseUrl}/findAddressCandidates?magicKey=${magicKey}&forStorage=true&outFields=*&token=${token}&f=json`;
  return getAsync<IGeocodingAddressCandidateResponse>(
    instance,
    accounts,
    url,
    false
  );
};

export const acquireGeoCodingAcessToken = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
): Promise<IGeocodingResponse> => {
  const suggestUrl = `/api/ErsiMap/Token`;
  return getAsync<IGeocodingResponse>(instance, accounts, suggestUrl);
};

import { Button, Grid } from '@aurecon-creative-technologies/styleguide';
import Footer from '../components/shared/Footer';
import Header from '../components/shared/Header';

import style from '../styles/pages/HelpAndSupport.module.scss';

const HelpAndSupport: React.FunctionComponent = () => {
    const onOpenLink = (link: string) => {
        window.open(link, '_blank');
    }

    return (
        <div>
            <Header />
            <div className={style.helpAndSupportPageContainer}>
                <div className={style.helpAndSupportHeader}>
                    <h2>Help and Support</h2>
                </div>
                <div className={style.helpAndSupportLinksContainer}>
                    <div className={style.helpAndSupportLinksColumn}>
                        <div className={style.helpAndSupportLinkSubHeader}>Help</div>
                        <div className={style.helpAndSupportLinkContainer}>
                            <div>For Help with Builder please reference the Hive page here: </div>
                            <div>
                                <Button
                                    type="primary"
                                    size="medium"
                                    label='Help with Builder'
                                    icon='launch'
                                    cssClass={"buttonModeActive"}
                                    onClick={() => onOpenLink("https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Platforms/digitalworkspace/DWS-Builder.aspx")}
                                />
                            </div>
                        </div>
                        <div className={style.helpAndSupportLinkContainer}>
                            <div>With Quick Reference Guides located here: </div>
                            <div>
                                <Button
                                    type="primary"
                                    size="medium"
                                    label='Quick reference guides'
                                    icon='launch'
                                    cssClass={"buttonModeActive"}
                                    onClick={() => onOpenLink("https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Platforms/digitalworkspace/DWS-Builder-QRGs.aspx")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.helpAndSupportLinksColumn}>
                        <div className={style.helpAndSupportLinkSubHeader}>Support</div>
                        <div className={style.helpAndSupportLinkContainer}>
                            <div>
                                Please reach out to the Digital Practice Leader, 
                                Local PSH team or Information Management Champions within your region for assistance using Builder.  
                            </div>
                            <div>For Technical Issues please submit a Service Desk ticket: </div>
                            <div>
                                <Button
                                    type="primary"
                                    size="medium"
                                    label='Technical Issues'
                                    icon='launch'
                                    cssClass={"buttonModeActive"}
                                    onClick={() => onOpenLink("https://aureconprod.service-now.com/bss/ask_for_help.do")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.helpAndSupportLinksColumn}>
                        <div className={style.helpAndSupportLinkSubHeader}>Feedback</div>
                        <div className={style.helpAndSupportLinkContainer}>
                            <div>Please find the feedback form for any feedback and enhancement requests for Builder located here:  </div>
                            <div>
                                <Button
                                    type="primary"
                                    size="medium"
                                    label='Feedback & Enhancement Requests'
                                    icon='launch'
                                    cssClass={"buttonModeActive"}
                                    onClick={() => onOpenLink("https://forms.office.com/Pages/ResponsePage.aspx?id=sQe9wba3ykee8M9mc12q5j9QTuu6J51HhEXVpIdcYUhUMDVQVzVPTExVUkM1QU1HTlRXUFJNWUQ3WC4u")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HelpAndSupport;
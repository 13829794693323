import { FC, ReactNode } from "react";
import {
  Grid,
  IButtonProps,
  Modal,
} from "@aurecon-creative-technologies/styleguide";

import Style from "../../styles/components/shared/ConfirmModal.module.scss";

interface ConfirmModalProps {
  title: string;
  message: string | ReactNode;
  open: boolean;
  loading?: boolean;
  onYes?: () => void;
  onNo?: () => void;
  onClose: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const actions: IButtonProps[] = [
    {
      type: "secondary",
      label: "Yes",
      cssClass: Style.actionButton,
      loading: props.loading,
      disabled: props.loading,
      onClick: () => props.onYes && props.onYes(),
    },
    {
      type: "primary",
      label: "No",
      disabled: props.loading,
      cssClass: Style.actionButton,
      onClick: () => (props.onNo ? props.onNo() : props.onClose()),
    },
  ];

  return (
    <Modal
      isShowing={props.open}
      shouldOverlayClose={false}
      isOverlay
      isCloseButton
      size="medium"
      modalPadding={{ top: "0", right: "24px", left: "24px", bottom: "24px" }}
      onClose={props.onClose}
      actions={actions}
    >
      <Grid row gap={10} cssClass={Style.confirmModalContainer}>
        <Grid item xs={12}>
          <h2>{props.title}</h2>
          {props.message}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmModal;

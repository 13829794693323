import {
  Extent,
  Geometry,
  Point,
  Polygon,
  Polyline,
} from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import { AddressType } from "../../../constants/ErsiMapConst";
import {
  IGeocodingAddressCandidate,
  IGeometryLocation,
} from "../../../models/map/IGeocodingAddressCandidateResponse";
import { geometrySymbols } from "./WebMapGeometrySymbols";
import { getMapView } from "./WebMapViewInstance";

export const createPolylinGeometry = (
  mapView: MapView,
  paths: number[][][]
): Geometry => {
  const geometry = new Polyline({
    paths,
    spatialReference: mapView.spatialReference,
  });
  return geometry;
};

export const createPolygonGeometry = (
  mapView: MapView,
  extentOrRings: Extent | number[][][],
  zoomTo = true
): Geometry => {
  const extent =
    extentOrRings instanceof Extent ? (extentOrRings as Extent) : null;
  const geometry = new Polygon({
    hasZ: false,
    hasM: false,
    rings: extent
      ? [
          [
            [extent.xmin, extent.ymin],
            [extent.xmin, extent.ymax],
            [extent.xmax, extent.ymax],
            [extent.xmax, extent.ymin],
            [extent.xmin, extent.ymin],
          ],
        ]
      : (extentOrRings as number[][][]),
    spatialReference: { wkid: 4326 },
  });

  zoomTo && mapView.goTo(geometry.extent.expand(1.5));
  return geometry;
};

export const createPointGeometry = (
  mapView: MapView,
  location: IGeometryLocation,
  zoomTo = true
): Geometry => {
  const geometry = new Point({
    x: location.x,
    y: location.y,
  });
  zoomTo && mapView.goTo({ target: geometry, scale: 10000 });
  return geometry;
};

export const getGraphicByMagicKey = (magicKey: string): Graphic | null => {
  const mapView = getMapView();

  if (!mapView) return null;
  return mapView.graphics.find((g) => g.attributes.id === magicKey);
};

export const createGraphic = (geometry: Geometry, attributes: any): Graphic => {
  const graphic = new Graphic({
    geometry,
    symbol: geometrySymbols[geometry.type],
    attributes,
  });
  return graphic;
};

export const addGraphic = (
  mapView: MapView,
  candidate: IGeocodingAddressCandidate
): void => {
  mapView.graphics.removeAll();

  const geometry =
    candidate.attributes.Addr_type === AddressType.Locality
      ? createPolygonGeometry(
          mapView,
          new Extent({
            xmin: candidate.extent.xmin,
            xmax: candidate.extent.xmax,
            ymin: candidate.extent.ymin,
            ymax: candidate.extent.ymax,
          })
        )
      : createPointGeometry(mapView, candidate.location);

  mapView.graphics.add(
    createGraphic(geometry, {
      id: candidate.magicKey,
      origin: "Geocode",
      name: candidate.address.substring(0, 50),
    })
  );
};

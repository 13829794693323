import { useEffect, useState } from 'react';

import { Container } from '@aurecon-creative-technologies/styleguide';

import { IProjectModel } from '../models/project/IProjectModel';
import Header from '../components/shared/Header';
import Footer from '../components/shared/Footer';

import style from '../styles/pages/ProjectsContainer.module.scss';
import { getProjects } from '../apis/project/Project';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IProjectsResponseModel } from '../models/project/IProjectsResponseModel';
import { bindProjectData } from '../utils/bindProjectData';
import ProjectGrid from '../components/projects/ProjectGrid';
import { IProjectFilterModel } from '../models/project/IProjectFilterModel';

const ProjectsContainer: React.FunctionComponent = () => {
    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated) window.location.href = "/";

    const { instance, accounts } = useMsal();

    const pageSize = 8;
    const [projects, setProjects] = useState<IProjectModel[]>([]);
    const [totalProjectsCount, setTotalProjectsCount] = useState<number>(0);
    const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(true);

    const [selectedProjectFilter, setSelectedProjectFilter] = useState<IProjectFilterModel>({
        pageSize: pageSize,
        pageNumber: 1,
        sortBy: 'ProjectId',
        sortOrder: 'desc',
        searchKeyword: '',
    });

    useEffect(() => {
        getProjects(instance, accounts, `pageSize=${pageSize}&pageNumber=1&sortBy=ProjectId&sortOrder=desc`)
            .then((results: IProjectsResponseModel) => {
                if(results.totalCount) {
                    setTotalProjectsCount(results.totalCount);
                    setProjects(bindProjectData(results));
                    setIsProjectsLoading(false);
                }
                else {
                    
                    setIsProjectsLoading(false);
                }
            });
    }, [instance, accounts]);

    const onSearch = (searchValue: string) => {
        setIsProjectsLoading(true);
        setSelectedProjectFilter({...selectedProjectFilter, searchKeyword: searchValue});
        // reset Projects to 0?
        setProjects([]);
        var tmpCurrentApiQuery;
        if(searchValue === "") {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=1&${selectedProjectFilter.sortBy}&sortOrder=${selectedProjectFilter.sortOrder}`;
        }
        else {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=1&${selectedProjectFilter.sortBy}&sortOrder=${selectedProjectFilter.sortOrder}&searchValue=${encodeURIComponent(searchValue)}`;
        }
        getProjects(instance, accounts, tmpCurrentApiQuery)
        .then((results: IProjectsResponseModel) => {
            setTotalProjectsCount(results.totalCount);
            setProjects(bindProjectData(results));
            setIsProjectsLoading(false);
        });
    }

    const onPageChange = (pageNumber: number) => {
        setIsProjectsLoading(true);
        // reset Projects to 0?
        setProjects([]);
        setSelectedProjectFilter({...selectedProjectFilter, pageNumber: pageNumber});
        var tmpCurrentApiQuery;
        if(selectedProjectFilter.searchKeyword === "") {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${selectedProjectFilter.sortBy}&sortOrder=${selectedProjectFilter.sortOrder}`;
        }
        else {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${selectedProjectFilter.sortBy}&sortOrder=${selectedProjectFilter.sortOrder}&searchValue=${encodeURIComponent(selectedProjectFilter.searchKeyword)}`;
        }
        
        getProjects(instance, accounts, tmpCurrentApiQuery)
            .then((results: IProjectsResponseModel) => {
                setTotalProjectsCount(results.totalCount);
                setProjects(bindProjectData(results));
                setIsProjectsLoading(false);
            });
    }

    const onSortByChange = (sortBy: string) => {
        setIsProjectsLoading(true);
        // reset Projects to 0?
        setProjects([]);
        setSelectedProjectFilter({...selectedProjectFilter, sortBy: sortBy});
        var tmpCurrentApiQuery;
        if(selectedProjectFilter.searchKeyword === "") {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=1&sortBy=${sortBy}&sortOrder=${selectedProjectFilter.sortOrder}`;
        }
        else {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=1&sortBy=${sortBy}&sortOrder=${selectedProjectFilter.sortOrder}&searchValue=${encodeURIComponent(selectedProjectFilter.searchKeyword)}`;
        }

        getProjects(instance, accounts, tmpCurrentApiQuery)
            .then((results: IProjectsResponseModel) => {
                setTotalProjectsCount(results.totalCount);
                setProjects(bindProjectData(results));
                setIsProjectsLoading(false);
            });
    }

    const onSortOrderChange = (sortOrder: string) => {
        setIsProjectsLoading(true);
        // reset Projects to 0?
        setProjects([]);
        setSelectedProjectFilter({...selectedProjectFilter, sortOrder: sortOrder});
        var tmpCurrentApiQuery;
        if(selectedProjectFilter.searchKeyword === "") {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=1&sortBy=${selectedProjectFilter.sortBy}&sortOrder=${sortOrder}`;
        }
        else {
            tmpCurrentApiQuery = `pageSize=${pageSize}&pageNumber=1&sortBy=${selectedProjectFilter.sortBy}&sortOrder=${sortOrder}&searchValue=${encodeURIComponent(selectedProjectFilter.searchKeyword)}`;
        }

        getProjects(instance, accounts, tmpCurrentApiQuery)
            .then((results: IProjectsResponseModel) => {
                setTotalProjectsCount(results.totalCount);
                setProjects(bindProjectData(results));
                setIsProjectsLoading(false);
            });
    }

    return (
        <div className={style.projectsContainer}>
            <Header />
            <Container fluid cssClass={style.projectsPageContainer} key='projects-container-1'>
                <ProjectGrid 
                    projectData={projects}
                    totalProjectsCount={totalProjectsCount}
                    isLoading={isProjectsLoading}
                    onSearch={onSearch}
                    onPageChange={onPageChange}
                    onSortByChange={onSortByChange}
                    onSortOrderChange={onSortOrderChange}
                />
            </Container>
            <Footer />
        </div>
    )
};

export default ProjectsContainer;
import style from '../../styles/components/project/NoProjects.module.scss';

const NoProjects: React.FunctionComponent = () => {
  return (
    <div className={style.noProjectsContainer}>
      <div className={style.noProjectsHeader}>There are no Projects assigned to you.</div>      
      <div className={style.noProjectsSubHeader}>
          <div>
                This means you are not a nominated Project Manager, 
                Project Support or Document Controller on a project.
            </div>
          <div>Click <a href='https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/Platforms/digitalworkspace/DWS-Builder.aspx' target='_blank' rel="noreferrer">here</a> to view the quick reference guide on being added to projects.</div>
      </div>
    </div>
  );
};

export default NoProjects;

import { FC, useEffect, useRef, useState } from "react";
import esriConfig from "@arcgis/core/config";
import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";

import Style from "../../../styles/components/shared/map/Map.module.scss";
import { findGeoCodingAddressCandidates } from "../../../apis/map/map";
import { useMsal } from "@azure/msal-react";
import { Loader } from "@aurecon-creative-technologies/styleguide";
import { addGraphic } from "./WebMapGraphic";
import { cacheLayerViews } from "./WebMapLayer";
import { cacheMapView } from "./WebMapViewInstance";
import LayerView from "@arcgis/core/views/layers/LayerView";
import DrawerWidgets from "./widgets/DrawerWidgets";
import { initDraw } from "./WebMapDrawAction";
import { IDictionary } from "../../../models/shared/IDictonary";

export interface IWebMapViewerProps {
  apiKey: string;
  webMapId: string;
  attributes?: IDictionary;
  magicKey?: string;
  onLayerViewUpdating?: (layerView: LayerView) => void;
}

const WebMapViewer: FC<IWebMapViewerProps> = (props) => {
  const { apiKey, webMapId, attributes, magicKey, onLayerViewUpdating } = props;
  const mapViewerRef = useRef<HTMLDivElement>(null);
  const [mapView, setMapView] = useState<MapView>();
  const [loading, setLoading] = useState(false);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (!mapViewerRef.current || mapView) return;

    esriConfig.apiKey = apiKey;
    const webMap = new WebMap({
      portalItem: {
        id: webMapId,
      },
    });

    const view = new MapView({
      container: mapViewerRef.current,
      map: webMap,
    });

    view.on("layerview-create", (evt) => {
      cacheLayerViews(evt.layerView, onLayerViewUpdating);
    });

    initDraw(view);
    setMapView(view);
    cacheMapView(view);
  }, [webMapId, apiKey, mapView, onLayerViewUpdating]);

  useEffect(() => {
    if (!magicKey || !mapView) return;

    const asyncEffect = async () => {
      setLoading(true);
      const addressCandidate = await findGeoCodingAddressCandidates(
        instance,
        accounts,
        magicKey
      );

      setLoading(false);
      const candidate = addressCandidate.candidates[0];
      addGraphic(mapView, { ...candidate, magicKey });
    };

    asyncEffect();
  }, [mapView, magicKey, accounts, instance]);

  return (
    <>
      {loading && (
        <div className={Style.mapViewerContainer}>
          <Loader />
        </div>
      )}

      <div
        style={{ display: loading ? "none" : "inherit" }}
        className={Style.mapViewerContainer}
        ref={mapViewerRef}
      >
        <DrawerWidgets attributes={attributes} />
      </div>
    </>
  );
};

export default WebMapViewer;

import Footer from '../components/shared/Footer';
import Header from '../components/shared/Header';

const Spatial: React.FunctionComponent = () => {
    return (
        <div>
            <Header />
            <div>SPATIAL</div>
            <Footer />
        </div>
    )
}

export default Spatial;
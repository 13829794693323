import { ISettingsModel } from '../models/shared/ISettingsModel';

export const getSettings = async (): Promise<ISettingsModel> => {

  if (process.env.REACT_APP_LOCAL_DEVELOPMENT) {
    let settings = {
        version: process.env.REACT_APP_VERSION ?? "",
        appClientId: process.env.REACT_APP_CLIENT_ID ?? "", 
        appApiScope: process.env.REACT_APP_API_SCOPE ?? "",
        appAuthority: process.env.REACT_APP_API_AUTHORITY ?? "",
        appRedirectUri: process.env.REACT_APP_REDIRECT_URI ?? "",
        mapConfigApiKey: process.env.REACT_APP_MAPCONFIG_APIKEY ?? "",
        mapConfigWebMapId: process.env.REACT_APP_MAPCONFIG_WEBMAPID ?? ""
    };
    return settings;
  }

  const apiUrl = `/api/Settings`;
  const response = await fetch(apiUrl);
  if (!response.ok) throw response;

  return response.json();
};

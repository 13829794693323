import { ReactComponent as AureconLogo } from '../../assets/aurecon_logo.svg';

import style from '../../styles/components/shared/Footer.module.scss';

const Footer: React.FunctionComponent = () => {
  return (
    <div className={style.builderFooterContainer}>
        <div className={style.builderFooter}>
            <span>
                <AureconLogo height={20} />
            </span>
            <span className={style.footerLink}>
                <a href="/Terms" target="_blank">Terms & Conditions</a>
            </span>
            <span className={style.footerSeparator}>|</span>   
            <span className={style.footerLink}>
                <a href="/Privacy" target="_blank">Privacy Policy</a>
            </span>   
            <span className={style.footerSeparator}>|</span>    
            <span>2022 DWB version 1.0.0</span>
        </div>
    </div>
  );
};

export default Footer;

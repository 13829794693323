import MapView from "@arcgis/core/views/MapView";
import { GeometryType } from "../../../constants/ErsiMapConst";
import Draw from "@arcgis/core/views/draw/Draw";
import {
  createGraphic,
  createPointGeometry,
  createPolygonGeometry,
  createPolylinGeometry,
} from "./WebMapGraphic";
import { Geometry } from "@arcgis/core/geometry";
import { IDictionary } from "../../../models/shared/IDictonary";
import Graphic from "@arcgis/core/Graphic";
import { addNewFeature } from "./WebMapLayer";

let draw: Draw;

const drawActionFactory = {
  [GeometryType.Point]: () => draw.create("point", { mode: "click" }),
  [GeometryType.Polyline]: () => draw.create("polyline", { mode: "hybrid" }),
  [GeometryType.Polygon]: () => draw.create("polygon", { mode: "hybrid" }),
};

const drawCompleteHandlers = {
  [GeometryType.Point]: (evt: any): Geometry => {
    const geometry = createPointGeometry(
      draw.view,
      {
        x: evt.coordinates[0],
        y: evt.coordinates[1],
      },
      false
    );

    return geometry;
  },
  [GeometryType.Polyline]: (evt: any): Geometry =>
    createPolylinGeometry(draw.view, evt.vertices),
  [GeometryType.Polygon]: (evt: any) =>
    createPolygonGeometry(draw.view, evt.vertices, false),
};

const drawActionHandler = (type: string, evt: any): Graphic => {
  draw.view.graphics.removeAll();
  const geometry = drawCompleteHandlers[type](evt);
  geometry.spatialReference = draw.view.spatialReference;

  const now = new Date();
  const graphic = createGraphic(geometry, {
    origin: "Drawn",
    name: `The drawn location - ${now.getDate()}/${now.getMonth()}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}:${now.getMilliseconds()}`,
  });

  draw.view.graphics.add(graphic);
  return graphic;
};

export const initDraw = (mapView: MapView): void => {
  draw = new Draw({
    view: mapView,
  });
};

export const drawFeature = (type: string, attributes?: IDictionary): void => {
  if (!type || !drawActionFactory[type]) return;

  draw.view.container.style.cursor = "crosshair";
  const action = drawActionFactory[type]();

  action.on("cursor-update", (evt) => drawActionHandler(type, evt));
  action.on("draw-complete", async (evt) => {
    const graphic = drawActionHandler(type, evt);
    draw.view.container.style.cursor = "default";
    await addNewFeature(
      attributes!["projectId"],
      attributes!["userName"],
      graphic
    );

    draw.view.graphics.removeAll();
  });
};

export const cancelDrawFeature = (): void => {
  draw.view.container.style.cursor = "default";
  draw.view.graphics.removeAll();
  draw.reset();
};

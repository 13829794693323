import style from "../../../styles/components/project/ProjectTabs.module.scss";

import { IProjectDetails } from "../../../models/project/IProjectDetails";
import WebMapViewer from "../../shared/map/WebMapViewer";
import {
  Button,
  FormInput,
  Loader,
} from "@aurecon-creative-technologies/styleguide";
import { useEffect, useMemo, useState } from "react";
import { geoCodingSuggest } from "../../../apis/map/map";
import { useMsal } from "@azure/msal-react";
import { IGeocodingSuggestionResponse } from "../../../models/map/IGeocodingSuggestResponse";
import { getGraphicByMagicKey } from "../../shared/map/WebMapGraphic";
import {
  addNewFeature,
  clearLayerViewsCache,
  deleteFeature,
  queryFeatureLayerView,
} from "../../shared/map/WebMapLayer";
import { ILocationItem } from "../../../models/map/ILocationItem";
import ConfirmModal from "../../shared/ConfirmModal";
import { zoomTo } from "../../shared/map/WebMapViewInstance";
import { builderSettingConst } from '../../../constants/builderSettingConst';
import { ISettingsModel } from '../../../models/shared/ISettingsModel';

interface IProjectLocationProps {
  pageState: IProjectDetails;
  onLocationsAdded: (added: boolean) => void;
}

const Tab3Location: React.FunctionComponent<IProjectLocationProps> = (
  props
) => {
  const settingsJsonString = localStorage.getItem(builderSettingConst);
  const settings = settingsJsonString ? (JSON.parse(settingsJsonString) as ISettingsModel) : null;

  const { pageState, onLocationsAdded } = props;
  const { instance, accounts } = useMsal();
  const [searchText, setSearchText] = useState("");
  const [magicKey, setMagicKey] = useState("");
  const [searching, setSearching] = useState(false);
  const [openSearchPanel, setOpenSearchPanel] = useState(false);
  const [searchResults, setSearchResults] = useState<
    IGeocodingSuggestionResponse[]
  >([]);
  const [locationItems, setLocationItems] = useState<ILocationItem[]>([]);
  const [selectedLocationItem, setSelectedLocationItem] =
    useState<ILocationItem>();
  const [aggregatedlocationItems, setAggregatedLocationItems] = useState<
    ILocationItem[]
  >([]);

  const [openModal, setOpenModal] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    return () => {
      clearLayerViewsCache();
    };
  }, []);

  useEffect(() => {
    if (!aggregatedlocationItems || !aggregatedlocationItems?.length) return;

    onLocationsAdded(!!aggregatedlocationItems.length);
  }, [aggregatedlocationItems, onLocationsAdded]);

  useEffect(() => {
    if (!locationItems.length) return;

    const newLocations = locationItems.filter(
      (it) => !aggregatedlocationItems.some((lc) => lc.id === it.id)
    );
    setAggregatedLocationItems([...aggregatedlocationItems, ...newLocations]);
    setLocationItems([]);
  }, [aggregatedlocationItems, locationItems]);

  const onSearching = async (value: string) => {
    if (!value) {
      setSearching(false);
      setOpenSearchPanel(false);
      setSearchText("");
      setMagicKey("");
      return;
    }

    setSearching(true);
    setOpenSearchPanel(true);
    setSearchText(value);
    const searchResponse = await geoCodingSuggest(instance, accounts, value);
    setSearchResults(searchResponse?.suggestions ?? []);
    setSearching(false);
  };

  const onResultSelected = (result: IGeocodingSuggestionResponse) => {
    setOpenSearchPanel(false);
    setSearchText(result.text);
    setMagicKey(result.magicKey);
  };

  const onSaveProjectLocation = async () => {
    setSaving(true);

    const graphic = getGraphicByMagicKey(magicKey);
    await addNewFeature(
      pageState.projectDetails.projectId,
      accounts[0].username,
      graphic
    );
    setSaving(false);
    setSearchText("");
    setMagicKey("");
  };

  const onDeletingLocation = async () => {
    if (!selectedLocationItem) return;

    const deletedItemIdx = aggregatedlocationItems.findIndex(
      (it) => it.id === selectedLocationItem.id
    );
    if (deletedItemIdx < 0) return;

    setSaving(true);

    await deleteFeature(selectedLocationItem);

    const newItems = [...aggregatedlocationItems];
    newItems.splice(deletedItemIdx, 1);
    setAggregatedLocationItems(newItems);

    setOpenModal(false);
    setSaving(false);
  };

  const searchResultsPanel = useMemo(() => {
    if (!openSearchPanel) return null;

    if (searching)
      return (
        <div className={style.list}>
          <div className={style.searchLoader}>
            <Loader size="extra small" />
          </div>
        </div>
      );

    return (
      <div className={style.list}>
        {searchResults.length === 0 ? (
          <div className={style.notFound}>No results found.</div>
        ) : (
          searchResults.map((res) => (
            <div
              key={res.magicKey}
              className={style.item}
              onClick={() => onResultSelected(res)}
            >
              {res.text}
            </div>
          ))
        )}
      </div>
    );
  }, [searching, openSearchPanel, searchResults]);

  return (
    <div className={style.tabLocation}>
      <div className={style.searchBox}>
        <div className={style.searchBoxTitle}>Add Location</div>
        <FormInput
          type="search"
          disabled={saving}
          value={searchText}
          onChange={onSearching}
        />
        {searchResultsPanel}
        {magicKey && (
          <Button
            label="Save"
            icon="place"
            disabled={saving}
            loading={saving}
            cssClass={style.saveLocationButton}
            onClick={onSaveProjectLocation}
          />
        )}
        {aggregatedlocationItems && !!aggregatedlocationItems.length && (
          <>
            <p>
              <b>Locations</b>
            </p>
            {aggregatedlocationItems.map((lc) => (
              <div
                className={style.locationItem}
                key={lc.id}
                onClick={() =>
                  zoomTo(
                    lc.geometryType,
                    lc.geometryExtent,
                    lc.geometryLocation
                  )
                }
              >
                <div>{lc.name}</div>
                <Button
                  type="custom"
                  icon="delete"
                  cssClass={style.deleteLocationButton}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setOpenModal(true);
                    setSelectedLocationItem(lc);
                  }}
                />
              </div>
            ))}
          </>
        )}
      </div>
      <WebMapViewer
        apiKey={settings ? settings.mapConfigApiKey : ""}
        webMapId={settings ? settings.mapConfigWebMapId : ""}
        magicKey={magicKey}
        attributes={{
          projectId: pageState.projectDetails.projectId,
          userName: accounts[0].username,
        }}
        onLayerViewUpdating={async (layerView) => {
          const locations = await queryFeatureLayerView(
            pageState.projectDetails.projectId,
            layerView
          );

          if (!locations.length) return;
          setLocationItems(locations);
        }}
      />
      <ConfirmModal
        open={openModal}
        loading={saving}
        onYes={onDeletingLocation}
        onClose={() => setOpenModal(false)}
        title="Delete Location?"
        message="Are you sure that you are ready to delete this location?"
      />
    </div>
  );
};

export default Tab3Location;

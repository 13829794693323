import style from '../../styles/components/shared/Card.module.scss';

export interface ICardProps {
  header: JSX.Element;
  title: JSX.Element;
  onClick?: () => void;
}

const Card: React.FunctionComponent<ICardProps> = (props) => {
  const { header, title, onClick } = props;
  const handleOnClick = () => {
    if (onClick) onClick();
  };
  return (
    <div className={style.cardContainer} onClick={handleOnClick}>
      <div className={style.header}>{header}</div>
      {title}
      {props.children}
    </div>
  );
};

export default Card;

import { Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { ReactComponent as BuilderIcon } from '../../assets/builder_logo.svg';

import style from '../../styles/components/shared/Header.module.scss';
import { Button, Modal, useModal } from '@aurecon-creative-technologies/styleguide';

const Header: React.FunctionComponent = () => {
    const { instance, accounts } = useMsal();
    const { isShowing, toggleModal } = useModal()
    const location = useLocation();

    const displayName = accounts[0] && accounts[0].name;
    const emailAddress = accounts[0] && accounts[0].username;

    const getDisplayNameLetters = (displayName: any) => {
        if (displayName !== "")
        {
            const dnWords = displayName.split(' ');
            return dnWords[0].charAt(0) + dnWords[1].charAt(0);
        }
    };

    const logout = () => {
        instance.logoutRedirect();
    };

    const isCurrentLink = (url: string) => {
        if (location.pathname.indexOf(url) >= 0)
            return true;
        else
            return false;
    }

    const goToHomePage = () => {
        window.location.href = '/Projects'
    }

    return (
        <div className={style.builderTopHeaderContainer}>
            <div className={style.builderTopHeaderIconContainer} onClick={goToHomePage}>
                <BuilderIcon height={35} width={35}/>
                <div>Builder</div>
            </div>
            <div className={style.builderTopHeaderLinksContainer}>
                <div className={style.builderTopHeaderLink} style={isCurrentLink('/Project') ? { borderBottom: '6px solid #169AA6' } : { }}>
                    <Link to="/Projects" >
                        Projects
                    </Link>
                </div>
                {/* Remove Spatial Tab for Release 1
                <div className={style.builderTopHeaderLink} style={isCurrentLink('/Spatial') ? { borderBottom: '6px solid #169AA6' } : { }}>
                    <Link to="/Spatial" >
                        Spatial
                    </Link>
                </div> */}
                <div className={style.builderTopHeaderLink} style={isCurrentLink('/Help') ? { borderBottom: '6px solid #169AA6' } : { }}>
                    <Link to="/Help" >
                        Help & Support
                    </Link>
                </div>
            </div>
            <div className={style.builderTopHeaderProfileContainer}>
                <Button
                    label={getDisplayNameLetters(accounts[0] && accounts[0].name ? accounts[0].name : "")}
                    onClick={toggleModal}></Button>

                <Modal
                    isShowing={isShowing}
                    onClose={toggleModal}
                    actions={[]}
                    cssClass={ 'userDetailsHeader' }
                    shouldOverlayClose={true}
                    isCloseButton={true}
                    size={'medium'}
                >
                    <div className={style.builderTopHeaderProfileModal}>
                        <div className={style.builderTopHeaderProfileModalLetters}>
                            {getDisplayNameLetters(accounts[0] && accounts[0].name ? accounts[0].name : "")}
                        </div>
                        <div className={style.builderTopHeaderProfileModalNames}>
                            <div><b>{displayName}</b></div>
                            <div>{emailAddress}</div>
                        </div>
                        <div className={style.builderTopHeaderProfileModalLine} />
                        <div className={style.builderTopHeaderProfileModalLogout} onClick={logout}>Logout</div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default Header;
import { IProjectModel } from "../models/project/IProjectModel";
import { IProjectsResponseModel } from "../models/project/IProjectsResponseModel";
import { IProjectRolesModel } from "../models/project/IProjectRolesModel";
import { IExternalRoles } from "../models/project/IExternalRoles";
import { IProjectMetadataModel } from "../models/project/IProjectMetadataModel";
import { IContentEngineSelectionModel } from "../models/project/IContentEngineSelectionModel";
import { IProjectWiseSelectionModel } from "../models/project/IProjectWiseSelectionModel";
import { BIM360Content } from "../models/project/IBIM360SelectionModel";

export const bindProjectData = (projectData: IProjectsResponseModel) => {
    const projects: IProjectModel[] = [];

    projectData.items.map((item: any) => {
        var project: IProjectModel = {
            projectId: item.projectId,
            projectName: item.projectName,
            client: item.client,
            customerId: item.customerId,
            costCenter: item.costCenter,
            costCenterCode: item.costCenterCode,
            opportunityId: item.opportunityId,
            workdayId: item.workdayId,
            projectStartDate: item.projectStartDate,
            projectEndDate: item.projectEndDate,
            classification: item.classification,
            currency: item.currency,
            region: item.region,
            location: item.location,
            country: item.country,            
            customerMarket: item.customerMarket,
            projectManagerName: item.projectManagerName,
            projectManagerEmail: item.projectManagerEmail,
            projectAccountantName: item.projectAccountantName,
            projectAccountantEmail: item.projectAccountantEmail,
            businessSponsor: item.businessSponsor,
            informationControllers: item.informationControllers,
            projectSupportGroup: item.projectSupportGroup,
            projectContentEngineData: item.projectContentEngineData,
            masterProjectHierarchyID: item.masterProjectHierarchyID
        };

        if(item.opportunityId) {
            project.oneSpaceLink = 'https://aurecongroup.sharepoint.com/sites/' + item.opportunityId
        }

        if(item.workdayId) {
            project.workDayLink = 'https://wd3.myworkday.com/aurecongroup/d/inst/1$17/' + item.workdayId + '.htmld'
        }

        projects.push(project);

        return project;
    })

    return projects;
}

export const bindContentEngineSelectionData = (contentEngineSelectionData: any) => {
    const defaultContentEngineSelections: IContentEngineSelectionModel[] = [];
    if(contentEngineSelectionData) {
        var tmpProjectWiseSelection = contentEngineSelectionData.projectWiseSelectionJson ? JSON.parse(contentEngineSelectionData.projectWiseSelectionJson) : {};
        var tmpBim360Selection = contentEngineSelectionData.bim360SelectionJson ? JSON.parse(contentEngineSelectionData.bim360SelectionJson) : {}
    
        var defaultContentEngineSelection: IContentEngineSelectionModel = {
            projectId: contentEngineSelectionData.projectId,
            isClientStandardDefined: contentEngineSelectionData.isClientStandardDefined,
            informationStandard: contentEngineSelectionData.informationStandard,
            informationStandardOther: contentEngineSelectionData.informationStandardOther,
            isOneSpaceEnabled: contentEngineSelectionData.isOneSpaceEnabled,
            isProjectWiseEnabled: contentEngineSelectionData.isProjectWiseEnabled,
            isBim360Enabled: contentEngineSelectionData.isBim360Enabled,
            is12dEnabled: contentEngineSelectionData.is12dEnabled,
            projectWiseSelection: tmpProjectWiseSelection as IProjectWiseSelectionModel,
            bim360Selection: tmpBim360Selection as BIM360Content,
            inheritedFromProjectId: contentEngineSelectionData.inheritedFromProjectId
        }
        defaultContentEngineSelections.push(defaultContentEngineSelection);
    }
    
    return defaultContentEngineSelections;
}

export const bindRolesData = (rolesData: any) => {
    const defaultRoles: IProjectRolesModel[] = [];
    let sameAccess: boolean = false;

    rolesData.map((role: any) => {
        const ExternalRoles: IExternalRoles[] =[];

        if(role.externalRolesModels){

            var Externals = role.externalRolesModels;
            Externals.map((externalRole: any)=>{
                var exrole: IExternalRoles={
                    Name: externalRole.Name,
                    Email: externalRole.Email,
                    Role: externalRole.Role,
                    Organization: externalRole.Organization,
                    OrganizationCode: externalRole.OrganizationCode,
                    IsInformationController: externalRole.IsInformationController
                }
                ExternalRoles.push(externalRole);

                return exrole;
            })

        }

        var defaultRole: IProjectRolesModel = {
            ProjectId: role.projectId,
            ProjectManagers: role.projectManagers ? role.projectManagers : [],
            ProjectAccountants: role.projectAccountants ? role.projectAccountants : [],
            InformationControllers: role.informationControllers ? role.informationControllers : [],
            ProjectSupportGroup: role.projectSupportGroup ? role.projectSupportGroup : [],
            Reviewers: role.reviewers ? role.reviewers : [],
            Approvers: role.approvers ? role.approvers : [],
            Authorisers: role.authorisers ? role.authorisers : [],
            Verifiers: role.verifiers ? role.verifiers : [],
            DigitalPracticeLeader: role.digitalPracticeLeader ? role.digitalPracticeLeader: [],
            ProjectMembers: role.projectMembers? role.projectMembers:[],
            ExternalRolesModels: ExternalRoles? ExternalRoles:[],
            IsWorkdayData: role.isWorkdayData,
            isSameExternalAccess:sameAccess
        }

        defaultRoles.push(defaultRole);

        return defaultRole;
    })

    return defaultRoles;
}


export const bindMetadata = (data: any) => {
    const ret: IProjectMetadataModel[] = [];

    if (data) {
        data.map((m: any) => {
            var defaultMetadata: IProjectMetadataModel = {
                id: m.id,
                metadataId: m.metadataId,
                type: m.type,
                value: m.value,
                isParent: m.isParent
            }

            ret.push(defaultMetadata);

            return defaultMetadata
        })
    }
    return ret;
}

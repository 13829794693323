import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider as MsalReactProvider } from "@azure/msal-react";

import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider as MGT_Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { getSettings } from './apis/settings';
import { ISettingsModel } from './models/shared/ISettingsModel';
import { builderSettingConst } from './constants/builderSettingConst';

getSettings().then((settings: ISettingsModel) => {

  localStorage.setItem(builderSettingConst, JSON.stringify(settings));

  const msalConfig = {
    auth: {
      clientId: settings.appClientId,
      authority: settings.appAuthority,
      redirectUri: settings.appRedirectUri
    },
    cache: {
      cacheLocation: "sessionStorage",  // This configures where your cache will be stored
      storeAuthStateInCookie: false,    // Set this to "true" if you are having issues on IE11 or Edge
    }
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  Providers.globalProvider = new MGT_Msal2Provider({ publicClientApplication: msalInstance });

  ReactDOM.render(
    <React.StrictMode>
      <MsalReactProvider instance={msalInstance}>
        <App />
      </MsalReactProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}).catch(err => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
});
